import socketIoClient from 'socket.io-client'
import sailsIo from 'sails.io.js'

export default {
  socketInit (context, opts) {
    return new Promise((resolve, reject) => {
      if (!context.state.io) {
        const isProductionEnvironment = (process.env.NODE_ENV === 'production')
        const io = sailsIo(socketIoClient)
        let disconnectTimer = null
        let flashSent = false
        io.sails.reconnection = false
        if (isProductionEnvironment || location.protocol === 'https:') {
          io.sails.url = `${location.protocol}//${location.hostname}${location.port ? ':' + location.port : ''}`
        } else {
          io.sails.url = `${location.protocol}//${location.hostname}:1337`
        }
        io.sails.environment = process.env.NODE_ENV || 'development'
        io.sails.useCORSRouteToGetCookie = false
        context.commit('SOCKET_IO', io)

        const reconnect = () => {
          context.commit('SOCKET_ENROLLED', false)
          setTimeout(() => {
            io.socket.reconnect()
          }, 5000)
        }
        const sendFlash = () => {
          disconnectTimer = null
          flashSent = true
          context.dispatch('flash', { kind: 'negative', messages: 'Connection to server interrupted', timeout: 2000 })
          context.commit('SOCKET_OFFLINE', true)
        }
        io.socket.on('disconnect', () => {
          console.log('socket disconnect')
          context.commit('SOCKET_INITIAL_CONNECTION', false)
          if (!disconnectTimer) {
            disconnectTimer = setTimeout(sendFlash, 10000)
          }
          reconnect()
        })
        io.socket.on('connect_error', (e) => {
          console.log(e)
          context.commit('SOCKET_INITIAL_CONNECTION', false)
          if (!disconnectTimer) {
            disconnectTimer = setTimeout(sendFlash, 10000)
          }
          reconnect()
        })
        io.socket.on('reconnect_error', (e) => {
          console.log(e)
          context.commit('SOCKET_INITIAL_CONNECTION', false)
          if (!disconnectTimer) {
            disconnectTimer = setTimeout(sendFlash, 10000)
          }
          reconnect()
        })
        io.socket.on('connect', () => {
          if (disconnectTimer) {
            clearTimeout(disconnectTimer)
          }
          if (flashSent) {
            flashSent = false
            context.dispatch('flash', { kind: 'positive', messages: 'Connection to server restored', timeout: 2000 })
          }
          if (context.rootState.User.authenticated) {
            context.dispatch('socketEnroll')
          }
        })
        const reactiveModels = [
          'area',
          'adhoc',
          'boschpanel',
          'branch',
          'budget',
          'building',
          'camera',
          'clientlogdownload',
          'clientlogfilenames',
          'dashboard',
          'door',
          'encoder',
          'group',
          'healthcheck',
          'healthcheckdef',
          'hvaccontroller',
          'informacastdialcast',
          'informacastm2m',
          'informacastlog',
          'informacastmessage',
          'informacastoutboundemail',
          'informacastrecipientgroup',
          'oncallschedule',
          'organization',
          'organizationlog',
          'panel',
          'point',
          'room',
          'schedule',
          'server',
          'serverlogdownload',
          'serverlogfilenames',
          'servicedivision',
          'system',
          'tag',
          'user',
          'userdashboard',
          'usergroup',
          'userservicedivision',
          'userorg'
        ]
        for (const model of reactiveModels) {
          io.socket.on(model, (message) => {
            const opts = Object.assign({}, message)
            opts.populate = opts.populate ? !Array.isArray(opts.populate) ? [opts.populate] : opts.populate : [] // this should be an array but easy to mess up
            opts.background = message.background ? message.background : false
            switch (message.verb) {
              case 'updated':
                context.dispatch(`${model}Updated`, opts)
                break
              case 'created':
                context.dispatch(`${model}Created`, opts)
                break
              case 'destroyed':
                context.dispatch(`${model}Destroyed`, opts)
                break
              case 'value':
                context.dispatch(`${model}Value`, opts)
                break
            }
          })
        }
        // TODO: reenabled if live updates are requested on homepage
        // GOTO: check2.js around line 1848 to reenable this
        // io.socket.on('health', (message) => {
        //   switch (message.verb) {
        //     case 'updated':
        //       if (context.rootState.Organization.list.find(o => o.id === message.id)) {
        //         if (!context.state.inhibitUpdate || context.rootState.Organization.current.id === message.id) {
        //           context.commit('SOCKET_INHIBIT_UPDATE', true)
        //           context.dispatch('organizationHealthUpdate')
        //           setTimeout(function () {
        //             context.commit('SOCKET_INHIBIT_UPDATE', false)
        //           }, 60000)
        //         }
        //       }
        //       break
        //   }
        // })
        io.socket.on('diagnostic', (message) => {
          switch (message.verb) {
            case 'getTimeline': {
              context.commit('DIAGNOSTIC_CAMERA_TIMELINE', message.data)
              break
            }
            default: {
              context.commit('DIAGNOSTIC_CLIENT_DATA', message.data)
              break
            }
          }
        })
      }
    })
  },
  socketEnroll (context, opts) {
    if (context.state.io && !context.state.enrolled && !context.state.enrolling && context.rootState.User.authenticated) {
      context.commit('SOCKET_ENROLLING', true)
      try {
        context.state.io.socket.get('/api/app/subscribe', (body, response) => {
          context.commit('SOCKET_ENROLLING', false)
          if (response.statusCode === 200) {
            context.commit('SOCKET_OFFLINE', false)
            context.commit('SOCKET_ENROLLED', true)
          } else if (response.statusCode === 401) {
            window.location.reload()
          } else {
            setTimeout(() => {
              context.dispatch('socketEnroll')
            }, 5000)
          }
        })
      } catch (e) {
        context.commit('SOCKET_ENROLLING', false)
        setTimeout(() => {
          context.dispatch('socketEnroll')
        }, 5000)
      }
    }
  },
  socketUnenroll (context, opts) {
    if (context.state.io && context.state.enrolled && !context.rootState.User.authenticated) {
      try {
        context.state.io.socket.get('/api/app/unsubscribe', (body, response) => {
          console.log('unenrolled3')
          context.commit('SOCKET_ENROLLED', false)
        })
      } catch (e) {
        console.log('unenrolled4')
        context.commit('SOCKET_ENROLLED', false)
      }
    }
  },
  socketPointSubscribe (context, opts) {
    return new Promise((resolve, reject) => {
      if (context.state.io) {
        try {
          context.state.io.socket.post('/api/point/subscribe', { ids: opts }, (body, response) => {
            if (response.statusCode === 200) {
              resolve(body)
            } else {
              reject(body)
            }
          })
        } catch (e) {
          reject(e)
        }
      } else {
        reject('No socket')
      }
    })
  },
  socketPointUnsubscribe (context, opts) {
    return new Promise((resolve, reject) => {
      if (context.state.io) {
        try {
          context.state.io.socket.post('/api/point/unsubscribe', { ids: opts }, (body, response) => {
            if (response.statusCode === 200) {
              resolve(body)
            } else {
              reject(body)
            }
          })
        } catch (e) {
          reject(e)
        }
      } else {
        reject('No socket')
      }
    })
  }
}
