export default function () {
  return {
    current: {
      id: '',
      active: false,
      building: '',
      applicationVersion: '',
      application: '',
      description: '',
      expiration: '',
      fromAddress: '',
      fromName: '',
      ipAddressRestriction: '',
      ip: '',
      issuer: '',
      licensee: '',
      name: '',
      replyToAddress: '',
      smtpPort: 0,
      smtpServer: '',
      smtpUser: '',
      reenableTime: null,
      system: '',
      vendorId: ''
    },
    update: {
      active: false,
      building: '',
      reenableTime: null
    },
    fetchingList: false,
    list: []
  }
}
