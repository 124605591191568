export default function () {
  return {
    current: {
      id: '',
      active: false,
      building: '',
      message: '',
      name: '',
      vendorId: ''
    },
    update: {
      active: false,
      building: '',
      reenableTime: null
    },
    fetchingList: false,
    list: []
  }
}
