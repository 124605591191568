import axios from 'axios'
export default {
  // ===========================List Edits=====================
  usersystemkindList (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/usersystemkind', { params: opts })
        .then(({ data }) => {
          context.commit('USERSYSTEMKIND_LIST', data)
          resolve(data)
        }).catch((error) => {
          context.commit('USERSYSTEMKIND_LIST', null)
          reject(error)
        })
    })
  },
  usersystemkindUpdate (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .patch('/api/usersystemkind/00000000-0000-0000-0000-000000000000', { userSystemKinds: context.state.update.list })
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  }
}
