import axios from 'axios'

export default {
  // ======================================================================
  // ==========================List Management=============================
  // ======================================================================
  tagList (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/tag?organization=${opts}`)
        .then(({ data }) => {
          context.commit('TAG_LIST', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  // ==========================Socket Updates==============================
  tagCreated (context, opts) {
    // if we're an admin, go fetch the new data
    if (context.rootState.User.permissions.tag && context.rootState.User.permissions.tag.read) {
      const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
      const inputs = { }
      if (opts && Array.isArray(opts.populate) && opts.populate.length > 0) {
        inputs.params = { }
        inputs.params.populate = opts.populate
      }
      axios
        .get(`/api/tag/${id}`, inputs)
        .then(({ data }) => {
          if (data.id) {
            context.commit('TAG_LIST_ADD', data)
          }
        }).catch(() => {
        })
    }
  },
  tagUpdated (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    const inputs = { }
    if (opts && Array.isArray(opts.populate) && opts.populate.length > 0) {
      inputs.params = { }
      inputs.params.populate = opts.populate
    }
    const inList = context.state.list.find((i) => i.id === id)
    if (context.state.current.id === id || inList) {
      axios
        .get(`/api/tag/${id}`, inputs)
        .then(({ data }) => {
          if (context.state.current.id === data.id) {
            data.socketUpdate = true
            context.commit('TAG_CURRENT', data)
          }
          if (inList) {
            context.commit('TAG_LIST_UPDATE', data)
          }
        }).catch(() => {
        })
    }
  },
  tagDestroyed (context, opts) {
    // always try to remove from list
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    if (id === context.state.current.id) {
      context.commit('TAG_CURRENT', null)
    }
    context.commit('TAG_LIST_ITEM_REMOVE', opts)
  },
  // ===========================List Edits===============================
  tagAdd (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/tag', opts)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  tagDelete (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/api/tag/${opts}`)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  // ======================================================================
  // ==========================Item Management=============================
  // ======================================================================
  tagDetail (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/tag/${opts}`)
        .then(({ data }) => {
          context.commit('TAG_LIST_UPDATE', data)
          context.commit('TAG_CURRENT', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  tagUpdate (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/api/tag/${context.state.current.id}`, context.state.update)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  }
}
