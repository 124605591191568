import axios from 'axios'

export default {
  healthCheckEventLog (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/healthcheckeventlog', { params: opts }) // {organization: <id>, system: <kind> }
        .then(({ data }) => {
          context.commit('HEALTHCHECKEVENTLOG_LIST', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  }
}
