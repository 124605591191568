import { date } from 'quasar'

export default {
  SYSTEM_RESOURCE_LOGS (state, value) {
    if (value) {
      for (const prop of Object.keys(state.default)) {
        if (value[prop]) {
          state[prop] = value[prop]
        }
      }
    } else {
      for (const prop of Object.keys(state.default)) {
        state[prop] = state.default[prop]
      }
    }
  },
  SYSTEM_RESOURCE_LOGS_UPDATE (state, value) {
    if (value) {
      // state.systems.loaded = true
      for (const prop of Object.keys(state)) {
        if (value[prop]) {
          state[prop].healthCheckAlarms = value[prop].healthCheckAlarms
          state[prop].healthCheckTotal = value[prop].healthCheckTotal
          state[prop].history.push({
            healthCheckAlarms: value[prop].healthCheckAlarms,
            healthCheckTotal: value[prop].healthCheckTotal,
            systemKind: prop,
            time: value[prop].time
          })
          const now = new Date()
          const currentHour = date.adjustDate(now, { minutes: 0, seconds: 0, milliseconds: 0 })
          const previousHour = date.subtractFromDate(currentHour, { hours: 1 })
          while (date.getDateDiff(state[prop].history[0], now, 'hours') > 168) {
            state[prop].history.shift()
          }
          let startIndex = 0
          let endIndex = 0
          for (let index = 0; index < state[prop].history.length; ++index) {
            const timestamp = new Date(state[prop].history[index].time)
            if (timestamp < previousHour) {
              startIndex = index + 1
            }
            if (timestamp < currentHour) {
              endIndex = index
            }
          }
          if (endIndex > startIndex) {
            const newRecord = {
              time: previousHour.toISOString(),
              systemKind: prop,
              healthCheckAlarms: 0,
              healthCheckTotal: 0
            }
            for (let index = startIndex; index <= endIndex; ++index) {
              newRecord.healthCheckAlarms += state[prop].history[index].healthCheckAlarms
              newRecord.healthCheckTotal += state[prop].history[index].healthCheckTotal
            }
            newRecord.healthCheckAlarms /= (endIndex - startIndex + 1)
            newRecord.healthCheckTotal /= (endIndex - startIndex + 1)
            state[prop].history.splice(startIndex, endIndex - startIndex + 1, newRecord)
          }
        }
      }
    }
  },
  // CHILD_RESOURCE_LOGS (state, value) {
  //   state.children = state.default.children
  //   if (value) {
  //     state.children = value
  //     state.children.loaded = true
  //   }
  // },
  RESOURCELOG_LOADED (state, value) {
    state.loaded = value
  },
  RESOURCELOG_LIST (state, value) {
    if (value) {
      const result = value.result
      for (const kind of value.kinds) {
        for (const key in result[kind]) {
          if (key === 'resources') {
            for (const resourceId in result[kind][key]) {
              if (state[kind].current.id === resourceId) {
                Object.assign(state[kind].current, result[kind][key][resourceId])
              }
              const obj = state[kind][key][resourceId] ? state[kind][key][resourceId] : {}
              state[kind][key][resourceId] = Object.assign(obj, result[kind][key][resourceId])
            }
          } else {
            state[kind][key] = result[kind][key]
          }
        }
      }
    }
  },
  RESOURCELOG_HISTORY_LIST (state, value) {
    if (value && Object.keys(value).length > 0) {
      for (const [kind, values] of Object.entries(value)) {
        const newResources = Object.assign({}, state[kind].resources)
        state[kind].history = values.history
        for (const [resourceId, resourceValues] of Object.entries(values.resources)) {
          if (!newResources[resourceId]) {
            newResources[resourceId] = { healthCheckAlarms: 0, healthCheckTotal: 0 }
          }
          newResources[resourceId].history = resourceValues.history
          if (state[kind].current?.id && state[kind].current.id === resourceId) {
            state[kind].current.history = resourceValues.history
          }
        }
        state[kind].resources = newResources // force watcher to notice the change
      }
    }
  },
  RESOURCELOG_RESET_HISTORY_LIST (state, value) {

  },
  RESOURCELOG_KIND_CURRENT (state, value) {
    state[value.kind].current.id = value.id
    if (value.data) {
      state[value.kind].current = Object.assign(state[value.kind].current, value.data)
    } else {
      state[value.kind].current.healthCheckAlarms = 0
      state[value.kind].current.healthCheckTotal = 0
      state[value.kind].current.healthChecks = {}
      state[value.kind].current.history = []
    }
  }
}
