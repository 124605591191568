export default {
  BUDGET_CURRENT (state, value) {
    for (const prop of ['id', 'kind', 'rate', 'resourceType', 'units']) {
      const valid = (value && value[prop])
      const different = (valid && value[prop] !== state.current[prop])
      state.current[prop] = valid ? different ? value[prop] : state.current[prop] : ''
      if (prop !== 'resourceType') {
        state.update[prop] = valid ? different ? value[prop] : state.current[prop] : ''
      }
    }
    for (const prop of ['janAmount', 'febAmount', 'marAmount', 'aprAmount', 'mayAmount', 'junAmount', 'julAmount', 'augAmount', 'sepAmount', 'octAmount', 'novAmount', 'decAmount', 'rate']) {
      const valid = (value && value[prop])
      const different = (valid && value[prop] !== state.current[prop])
      state.current[prop] = valid ? different ? value[prop] : state.current[prop] : 0
      state.update[prop] = valid ? different ? value[prop] : state.current[prop] : 0
    }
    state.current.resource = value?.resource ?? null
    state.current.year = value?.year ?? 2000
    state.update.year = value?.year ?? 2000
  },
  BUDGET_LIST (state, value) {
    state.list = (value && Array.isArray(value)) ? [...value] : []
  },
  BUDGET_LIST_UPDATE (state, value) {
    const index = state.list.findIndex((i) => i.id === value.id)
    if (index !== -1) {
      state.list.splice(index, 1, Object.assign(state.list[index], value))
    } else {
      state.list.push(value) // see fetchModel.js
    }
  },
  BUDGET_UPDATE_KIND (state, value) {
    state.update.kind = value
  },
  BUDGET_UPDATE_YEAR (state, value) {
    state.update.year = value
  },
  BUDGET_UPDATE_JAN_AMOUNT (state, value) {
    state.update.janAmount = value
  },
  BUDGET_UPDATE_FEB_AMOUNT (state, value) {
    state.update.febAmount = value
  },
  BUDGET_UPDATE_MAR_AMOUNT (state, value) {
    state.update.marAmount = value
  },
  BUDGET_UPDATE_APR_AMOUNT (state, value) {
    state.update.aprAmount = value
  },
  BUDGET_UPDATE_MAY_AMOUNT (state, value) {
    state.update.mayAmount = value
  },
  BUDGET_UPDATE_JUN_AMOUNT (state, value) {
    state.update.junAmount = value
  },
  BUDGET_UPDATE_JUL_AMOUNT (state, value) {
    state.update.julAmount = value
  },
  BUDGET_UPDATE_AUG_AMOUNT (state, value) {
    state.update.augAmount = value
  },
  BUDGET_UPDATE_SEP_AMOUNT (state, value) {
    state.update.sepAmount = value
  },
  BUDGET_UPDATE_OCT_AMOUNT (state, value) {
    state.update.octAmount = value
  },
  BUDGET_UPDATE_NOV_AMOUNT (state, value) {
    state.update.novAmount = value
  },
  BUDGET_UPDATE_DEC_AMOUNT (state, value) {
    state.update.decAmount = value
  },
  BUDGET_UPDATE_RATE (state, value) {
    state.update.rate = value
  },
  BUDGET_UPDATE_UNITS (state, value) {
    state.update.units = value
  }
}
