import axios from 'axios'

export default {
  comfortList (context, opts) {
    const inputs = { building: null, organization: null }
    Object.assign(inputs, opts)
    return new Promise((resolve, reject) => {
      axios
        .get('/api/comfort', { params: inputs })
        .then(({ data }) => {
          context.commit('COMFORT_LIST', data)
          resolve(data)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  comfortDetail (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/comfort/${opts.id}/${opts.model}`)
        .then(({ data }) => {
          context.commit('COMFORT_CURRENT', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  comfortHistory (context, opts) {
    const inputs = { building: null, organization: null }
    Object.assign(inputs, opts)
    return new Promise((resolve, reject) => {
      axios
        .get('/api/comfort/history', { params: inputs })
        .then(({ data }) => {
          context.commit('COMFORT_HISTORY', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  }
}
