export default function () {
  return {
    current: {
      id: '',
      active: false,
      customizable: false,
      building: '',
      description: '',
      messageType: '',
      ringtone: false,
      reenableTime: null,
      shortText: '',
      system: '',
      syncId: '',
      vendorId: ''
    },
    update: {
      active: false,
      building: '',
      reenableTime: null
    },
    fetchingList: false,
    list: []
  }
}
