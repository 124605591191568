export default function () {
  return {
    current: {
      id: '',
      weekNumber: '',
      startDate: '',
      endDate: '',
      year: '',
      user: {
        id: '',
        firstName: '',
        lastName: '',
        email: '',
        mobilePhone: ''
      },
      serviceDivisionUsers: [],
      allPossibleUsers: [],
      serviceDivision: { name: '' }
    },
    update: {
      user: {}
    },
    list: [],
    nextYearList: [],
    years: [],
    selectedYear: null
  }
}
