export default {
  USERSYSTEMKIND_LIST (state, value) {
    if (value && Array.isArray(value)) {
      state.current.list = value.map(a => Object.assign({}, a))
      state.update.list = value.map(a => Object.assign({}, a))
    } else {
      state.current.list = []
      state.update.list = []
    }
  },
  USERSYSTEMKIND_LIST_UPDATE (state, value) {
    const index = state.list.findIndex((i) => i.id === value.id)
    if (index !== -1) {
      state.current.list.splice(index, 1, value)
    } else {
      state.current.list.push(value) // see fetchModel.js
    }
  },
  UPDATE_USK_ENABLED (state, value) {
    if (value.index !== null && value.value !== null && value.index <= state.update.list.length && value.index >= 0) {
      state.update.list[value.index].enabled = value.value
    }
  },
  UPDATE_USK_RETURN_TO_NORMAL (state, value) {
    if (value.index !== null && value.value !== null && value.index <= state.update.list.length && value.index >= 0) {
      state.update.list[value.index].returnToNormal = value.value
    }
  },
  UPDATE_USK_STATUS (state, value) {
    if (value.index !== null && value.value !== null && value.index <= state.update.list.length && value.index >= 0) {
      state.update.list[value.index].status = value.value
    }
  },
  UPDATE_USK_EMAIL_LEVEL (state, value) {
    if (value.index !== null && value.value !== null && value.index <= state.update.list.length && value.index >= 0) {
      state.update.list[value.index].emailLevel = value.value
    }
  },
  UPDATE_USK_SMS_LEVEL (state, value) {
    if (value.index !== null && value.value !== null && value.index <= state.update.list.length && value.index >= 0) {
      state.update.list[value.index].smsLevel = value.value
    }
  }
}
