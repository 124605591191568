export default function () {
  return {
    current: {
      active: false,
      building: null,
      databaseName: '',
      hasManagementCard: false,
      healthChecks: [],
      history: {},
      icToken: '',
      icTokenExpiresOn: null,
      id: '',
      info: {},
      instanceName: '',
      ip: '',
      kind: '',
      managementCardIp: '',
      managementCardKind: '',
      managementCardPassword: '',
      managementCardPort: 0,
      managementCardUsername: '',
      name: '',
      notes: false,
      osCheck: false,
      password: '',
      points: [],
      port: 0,
      reenableTime: null,
      serverLogDirectory: '',
      serverLogPassword: '',
      serverLogUsername: '',
      siteServerId: '',
      system: null,
      status: '',
      url: null,
      username: ''
    },
    update: {
      active: false,
      building: null,
      databaseName: '',
      hasManagementCard: false,
      icToken: '',
      id: '',
      instanceName: '',
      ip: '',
      kind: '',
      managementCardIp: '',
      managementCardKind: '',
      managementCardPassword: '',
      managementCardPort: 0,
      managementCardUsername: '',
      name: '',
      osCheck: false,
      password: '',
      port: 0,
      reenableTime: null,
      siteServerId: '',
      serverLogDirectory: '',
      serverLogPassword: '',
      serverLogUsername: '',
      url: null,
      username: ''
    },
    create: {
      building: null,
      databaseName: null,
      instanceName: null,
      ip: null,
      kind: null,
      managementCardIp: null,
      managementCardKind: null,
      managementCardPassword: null,
      managementCardPort: null,
      managementCardUsername: null,
      name: null,
      osCheck: false,
      password: null,
      port: null,
      serverLogDirectory: '',
      serverLogPassword: '',
      serverLogUsername: '',
      system: null,
      url: null,
      username: null
    },
    serverLogNames: [],
    downloadableServerLogUrl: null,
    fetchingServerLogNames: true,
    descriptions: {
      ACC: [
        'Will check if ACC Service is available by logging in to the server with the IP, Port, Username, and Password.',
        'Will Ping the Server\'s IP.',
        'Optional OS Checks: CPU Utilization, Memory Free, Bandwidth, OS Disk Space, C: Drive Space Available, C: Drive Size, Uptime, Ping, Memory Size',
        'Note on OS Checks: Additional software is needed for OS Checks. Go to File Downloads and install DayHM_OS.msi on the server.'
      ],
      Access: [
        'Will check if the Access Service is available by making an api call with username and password to verify the service can login.',
        'Will Ping the Server\'s IP.'
      ],
      Alta: [
        'Will check if Alta Service is available by logging in to the server with the IP, Port, Username, and Password.',
        'Will get Camera information',
        'Will Ping the Server\'s IP.'
      ],
      'InformaCast Fusion': [
        'Will check if InformaCast Fusion Service is available',
        'Will Ping the IP Address provided.'
      ],
      'IRP Barix': [
        '** 400+ models Only **. User Ping Only for older 50 models.',
        'Will verify config files are present.',
        'Will Ping the Barix IP.'
      ],
      'IRP Bosch': [
        '** Passcode required **. Use Ping Only if you don\'t have the passcode',
        '** NOTE **: This greatly increases the scan time. Select ping only if that is all you need.',
        'Verify I/O is not in a troubled state',
        'Will Ping the Bosch Panel IP.'
      ],
      EWS: [
        'Currently, Ping Only with the option for OS Checks.',
        'Optional OS Checks: CPU Utilization, Memory Free, Bandwidth, OS Disk Space, C: Drive Space Available, C: Drive Size, Uptime, Ping, Memory Size.',
        'Note on OS Checks: Additional software is needed for OS Checks. Go to File Downloads and install DayHM_OS.msi on the server.'
      ],
      Netcontroller: [
        'NOTE: Continuum Only. Use kind \'Ping Only\' unless the controller has an InfinityFunction in the Netcontroller called rualive.htm with one line of code: print "OK"',
        'If it has rualive.htm , it will check if the Memory is ok and has the software downloaded into it on each scan.',
        'Will Ping the Server\'s IP.'
      ],
      'Ping Only': [
        'Will Ping the Server\'s IP.'
      ],
      'Windows OS': [
        'Optional OS Checks: CPU Utilization, Memory Free, Bandwidth, OS Disk Space, C: Drive Space Available, C: Drive Size, Uptime, Ping, Memory Size.',
        'Note on OS Checks: Additional software is needed for OS Checks. Go to File Downloads and install DayHM_OS.msi on the server.'
      ]
    },
    kinds: {
      ACC: ['Surveillance'],
      Access: ['Access'],
      Alta: ['Surveillance'],
      InformaCast: ['MassNotification'],
      'InformaCast Fusion': ['MassNotification'],
      'IRP Barix': ['Access', 'MassNotification', 'Surveillance'],
      'IRP Bosch': ['Access'],
      EWS: ['HVAC'],
      Netcontroller: ['HVAC'],
      'Ping Only': ['Access', 'MassNotification', 'HVAC', 'Energy', 'Lighting', 'Surveillance'],
      'Windows OS': ['Access', 'MassNotification', 'HVAC', 'Energy', 'Lighting', 'Surveillance']
    },
    managementCardKinds: {
      None: [],
      CIMC: [
        'Will check if CIMC Service is available.',
        'Will get and record the number of good Disks.',
        'Will get and record the number of good Raid Controllers.',
        'The following info will be recorded if it is available: Manufacturer, Memory, CPU, Model, and Firmware.'
      ],
      iDRAC: [
        'Will check if iDRAC Service is available.',
        'Will get and record the number of good Disks.',
        'Will get and record the number of good Raid Controllers.',
        'The following info will be recorded if it is available: Manufacturer, Memory, CPU, Model, and Firmware.'
      ],
      iLO: [
        'Will check if iLO Service is available.',
        'Will get and record the number of good Disks.',
        'Will get and record the number of good RAID Batteries.',
        'Will get and record RAID Cache Memory Size.',
        'Will get and record the number of good Raid Controllers.',
        'The following info will be recorded if it is available: Manufacturer, Memory, CPU, Model, and Firmware.'
      ]
    },
    list: [],
    siteServerIds: [],
    canInactivateForever: false
  }
}
