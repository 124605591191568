import axios from 'axios'

export default {
  // ==========================Socket Updates==============================
  serverCreated (context, opts) {
    const currentSystem = context.rootState.System.current.id
    const permission = context.rootState.User.permissions.server?.read
    if (permission && opts?.system && opts.system === currentSystem) {
      const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
      axios
        .get(`/api/server/${id}`)
        .then(({ data }) => {
          if (data.id) {
            context.commit('SERVER_LIST_UPDATE', data)
          }
        }).catch(() => {
          console.log('failed to get server')
        })
    }
  },
  serverUpdated (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    const listItem = context.state.list.find((i) => i.id === id)
    const inputs = { }
    if (opts && Array.isArray(opts.populate) && opts.populate.length > 0) {
      inputs.params = { }
      inputs.params.populate = opts.populate
    }
    if (context.state.current.id === id || listItem) {
      axios
        .get(`/api/server/${id}`, inputs)
        .then(({ data }) => {
          if (data.id && context.state.current.id === id) {
            data.socketUpdate = true
            context.commit('SERVER_CURRENT', data)
          }
          if (listItem) {
            context.commit('SERVER_LIST_UPDATE', data)
          }
        }).catch(() => {
        })
    }
  },
  serverDestroyed (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    if (id === context.state.current.id) {
      context.commit('SERVER_CURRENT', null)
    }
    context.commit('SERVER_LIST_ITEM_REMOVE', opts)
  },
  serverlogfilenamesValue (context, opts) {
    if (opts.user === context.rootState.User.loggedIn.id) {
      context.commit('SERVER_LOG_NAMES', opts.list)
      context.commit('SERVER_FETCHING_LOG_NAMES', false)
    } else {
      context.commit('SERVER_LOG_NAMES', null)
      context.commit('SERVER_FETCHING_LOG_NAMES', true)
    }
  },
  serverlogdownloadValue (context, opts) {
    if (opts.user === context.rootState.User.loggedIn.id) {
      context.commit('SERVER_UPDATE_DOWNLOADABLE_SERVER_LOG_URL', opts.logUrl)
    } else {
      context.commit('SERVER_UPDATE_DOWNLOADABLE_SERVER_LOG_URL', null)
    }
  },
  // ===========================List Edits===============================
  serverAdd (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/server', opts)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  serverDelete (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/api/server/${opts}`)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  serverUpdate (context, opts) {
    const updates = Object.assign({}, context.state.update)
    for (const key of Object.keys(updates)) {
      if ((updates[key] === null || updates[key] === context.state.current[key]) && key !== 'id') {
        delete updates[key]
      }
    }
    return new Promise((resolve, reject) => {
      axios
        .patch(`/api/server/${context.state.current.id}`, updates)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => {
          if (error.response && error.response.data && error.response.data.message) {
            reject(error.response.data)
          } else {
            reject(error)
          }
        })
    })
  },
  serverBuildingUpdate (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/api/server/${opts.id}`, opts)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  // ======================================================================
  // ==========================Item Management=============================
  // ======================================================================
  serverLogNames (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/server/get-server-log-names', opts)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  serverDownloadSingleLog (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/server/download-single-server-log', opts)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  serverList (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/server', { params: opts })
        .then(({ data }) => {
          context.commit('SERVER_LIST', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  serverSiteServerIds (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/server/site-acc-servers', { params: opts })
        .then(({ data }) => {
          context.commit('SERVER_SITE_SERVER_IDS', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  serverDetail (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    const inputs = { }
    if (opts && Array.isArray(opts.populate) && opts.populate.length > 0) {
      inputs.params = { }
      inputs.params.populate = opts.populate
    }
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/server/${id}`, inputs)
        .then(({ data }) => {
          context.commit('SERVER_CURRENT', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  serverHistory (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/server/history', { params: { id: opts } })
        .then(({ data }) => {
          context.commit('SERVER_CURRENT_HISTORY', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  serverChildUpdate (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/api/${opts.kind}/${opts.id}`, opts.data)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  bulkUpdateServers (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .patch('/api/server/bulkupdate', opts)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  serverCreate (context, opts) {
    return new Promise((resolve, reject) => {
      const create = Object.assign({}, context.state.create)
      for (const key of Object.keys(create)) {
        if (create[key] == null) {
          delete create[key]
        }
      }
      axios
        .post('/api/server', create)
        .then(({ data }) => {
          context.commit('SERVER_LIST_UPDATE', data)
          resolve(data)
        }).catch((error) => {
          if (error.response && error.response.data && error.response.data.message) {
            reject(error.response.data)
          } else {
            reject(error)
          }
        })
    })
  },
  serverCreateReset (context, opts) {
    context.commit('SERVER_CREATE_RESET', null)
  },
  serverClone (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/server/create-clone', opts)
        .then(({ data }) => {
          context.commit('SERVER_LIST_UPDATE', data)
          resolve(data)
        }).catch((error) => {
          if (error.response && error.response.data && error.response.data.message) {
            reject(error.response.data)
          } else {
            reject(error)
          }
        })
    })
  }
}
