export default function () {
  return {
    loaded: false,
    current: {
      building: {},
      commFailures: {},
      organization: {},
      room: {},
      system: {},
      resource: {},
      systemKind: ''
    },
    systems: {
      HVAC: {},
      Surveillance: {},
      Access: {},
      Lighting: {},
      Energy: {}
    },
    counts: { all: { alarms: 0, warnings: 0, commFailures: {}, total: 0 } },
    default: {
      building: {},
      commFailures: {},
      organization: {},
      room: {},
      system: {},
      resource: {},
      systemKind: ''
    }
  }
}
