export default {
  BOSCHPANELPOINT_LIST (state, value) {
    state.list = (value && Array.isArray(value)) ? [...(value.sort((a, b) => a.name.localeCompare(b.name)))] : []
  },
  BOSCHPANELPOINT_LIST_ADD (state, value) {
    if (state.list.findIndex((i) => i.id === value.id) === -1) {
      state.list.push(Object.assign({}, value))
    }
  },
  BOSCHPANELPOINT_LIST_ITEM_REMOVE (state, value) {
    const boschPanelId = typeof value === 'string' ? value : typeof value === 'object' && value.id ? value.id : ''
    const index = state.list.findIndex((i) => i.boschPanel === boschPanelId)
    if (index !== -1) {
      state.list.splice(index, 1)
    }
  },
  BOSCHPANELPOINT_LIST_UPDATE (state, value) {
    const index = state.list.findIndex((i) => i.id === value.id)
    if (index !== -1) {
      state.list.splice(index, 1, Object.assign(state.list[index], value))
    } else {
      state.list.push(value)
    }
  },
  BOSCHPANELPOINT_CURRENT (state, value) {
    // need to make a more graceful update to minimize screen disruptions
    const props = [
      'id',
      'boschPanel',
      'active',
      'configured',
      'position',
      'history',
      'name',
      'reenableTime',
      'status',
      'statusNumber',
      'notes'
    ]
    if (value) {
      for (const prop of props) {
        if (value[prop] !== state.current[prop]) {
          state.current[prop] = value[prop]
        }
      }
    } else {
      state.current = {
        id: '',
        boschPanel: '',
        active: false,
        configured: false,
        position: null,
        history: {},
        name: '',
        reenableTime: '',
        status: '',
        statusNumber: null,
        notes: false
      }
      state.update = {
        id: '',
        active: false,
        reenableTime: ''
      }
    }
  },
  BOSCHPANELPOINT_CURRENT_HISTORY (state, value) {
    if (value && Object.keys(value).length > 0) {
      state.current.history = value
    } else {
      state.current.history = {}
    }
  },
  BOSCHPANELPOINT_CURRENT_NOTES (state, value) {
    state.current.notes = value
  },
  BOSCHPANELPOINT_UPDATE_ACTIVE (state, value) {
    state.update.active = value
  },
  BOSCHPANELPOINT_UPDATE_HEALTH_CHECK_HISTORY (state, value) {
    if (state.current?.history && state.current.history[value.name]) {
      state.current.history[value.name] = value.history
    }
  },
  BOSCHPANELPOINT_UPDATE_REENABLE_TIME (state, value) {
    state.update.reenableTime = value
  }
}
