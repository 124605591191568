import pluralize from 'pluralize'
export default {
  canCreate: (state, getters) => (model) => {
    return getters.hasPermission(model, 'create')
  },
  canEdit: (state, getters) => (model) => {
    return getters.hasPermission(model, 'update')
  },
  canDelete: (state, getters) => (model) => {
    return getters.hasPermission(model, 'destroy')
  },
  canView: (state, getters) => (model) => {
    return getters.hasPermission(model, 'read')
  },
  canViewAll: (state, getters) => (model) => {
    return getters.hasPermission(model, 'readAll')
  },
  hasPermission: (state) => (model, action) => {
    const formatted = (pluralize.singular(model)).toLowerCase()
    return (state.permissions[formatted] && state.permissions[formatted][action])
  },
  isDay: (state) => {
    return state.loggedIn.isDay
  },
  canEditAllOrganizations: (state) => {
    return state.loggedIn.allOrganizations
  },
  getHomepage: (state) => {
    return state.loggedIn.dashboardHomepage ? 'Dashboard' : 'Overview'
  },
  getHomepageUri: (state) => {
    return state.loggedIn.dashboardHomepage ? '/dashboard' : '/'
  },
  videoLinks: (state) => {
    return {
      editaccount: 'https://dayautomation.sharepoint.com/sites/DashboardDev/_layouts/15/embed.aspx?UniqueId=ac1ee330-3e8a-4ef9-b2fe-7a9bbb09c8a0&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=StreamWebApp&referrerScenario=EmbedDialog.Create',
      createuser: 'https://dayautomation.sharepoint.com/sites/DashboardDev/_layouts/15/embed.aspx?UniqueId=226e8502-f6e5-49f4-8314-4fbfc2e72428&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=StreamWebApp&referrerScenario=EmbedDialog.Create',
      createuseraiversion: 'https://dayautomation.sharepoint.com/sites/DashboardDev/_layouts/15/embed.aspx?UniqueId=f00aadc8-fbfe-4f39-a62a-7a79da3f7f67&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=OneUpFileViewer&referrerScenario=EmbedDialog.Create',
      daycreateuser: 'https://dayautomation.sharepoint.com/sites/DashboardHelp/_layouts/15/embed.aspx?UniqueId=69281162-9ad3-4e4d-998e-a9fe54d535a5&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=StreamWebApp&referrerScenario=EmbedDialog.Create',
      createorganization: 'https://dayautomation.sharepoint.com/sites/DashboardDev/_layouts/15/embed.aspx?UniqueId=c2d3adaa-b234-406b-ab69-a99ab7249a77&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=StreamWebApp&referrerScenario=EmbedDialog.Create',
      dayeditaccount: 'https://dayautomation.sharepoint.com/sites/DashboardDev/_layouts/15/embed.aspx?UniqueId=3039eaa7-05ba-4bd5-9729-a961aa6bc17b&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=StreamWebApp&referrerScenario=EmbedDialog.Create',
      edituser: 'https://dayautomation.sharepoint.com/sites/DashboardDev/_layouts/15/embed.aspx?UniqueId=f1981fb2-cbd5-4c47-b472-ed26510c3492&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=StreamWebApp&referrerScenario=EmbedDialog.Create'
    }
  }
}
