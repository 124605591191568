export default {
  BRANCH_LIST (state, value) {
    state.list = (value && Array.isArray(value)) ? [...(value.sort((a, b) => (a.name > b.name) ? 1 : -1))] : []
  },
  BRANCH_ORGANIZATION_LIST (state, value) {
    state.current.organizations = [...(value.sort((a, b) => (a.name > b.name) ? 1 : -1))]
  },
  BRANCH_USER_LIST (state, value) {
    state.current.users = [...(value.sort((a, b) => (a.email > b.email) ? 1 : -1))]
  },
  BRANCH_USER_LIST_CLEAR (state, value) {
    state.current.users = []
  },
  BRANCH_LIST_ADD (state, value) {
    if (state.list.findIndex((i) => i.id === value.id) === -1) {
      state.list.push({
        id: value.id,
        name: value.name,
        organizations: value.organizations.length,
        users: value.users.length
      })
    }
  },
  BRANCH_LIST_REMOVE (state, value) {
    const id = typeof value === 'string' ? value : typeof value === 'object' && value.id ? value.id : ''
    const index = state.list.findIndex((i) => i.id === id)
    if (index !== -1) {
      state.list.splice(index, 1)
    }
  },
  BRANCH_LIST_UPDATE (state, value) {
    const index = state.list.findIndex((i) => i.id === value.id)
    if (index !== -1) {
      state.list.splice(index, 1, Object.assign(state.list[index], value))
    } else {
      state.list.push(value)
    }
  },
  BRANCH_CURRENT (state, value) {
    // need to make a more graceful update to minimize screen disruptions
    if (value) {
      for (const prop of ['id', 'name', 'userCount', 'organizationCount']) {
        if (value[prop] !== state.current[prop]) {
          state.current[prop] = value[prop]
          state.update[prop] = value[prop]
        }
      }
    } else {
      state.current = {
        id: '',
        name: '',
        userCount: 0,
        organizationCount: 0
      }
      state.update = {
        id: '',
        name: ''
      }
    }
  },
  BRANCH_UPDATE_NAME (state, value) {
    state.update.name = value
  }
}
