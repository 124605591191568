export default {
  USERGROUP_LIST (state, value) {
    state.list = (value && Array.isArray(value)) ? [...value] : []
  },
  USERGROUP_LIST_REMOVE (state, value) {
    const index = state.list.findIndex((i) => i.id === value)
    if (index !== -1) {
      state.list.splice(index, 1)
    }
  }
}
