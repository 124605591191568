export default {
  SCHEDULE_LIST (state, value) {
    state.list = value || []
  },
  SCHEDULE_LIST_ADD (state, value) {
    if (state.list.findIndex((i) => i.id === value.id) === -1) {
      state.list.push(value)
    }
  },
  SCHEDULE_LIST_ITEM_REMOVE (state, value) {
    const id = typeof value === 'string' ? value : typeof value === 'object' && value.id ? value.id : ''
    const index = state.list.findIndex((i) => i.id === id)
    if (index !== -1) {
      state.list.splice(index, 1)
    }
  },
  SCHEDULE_LIST_UPDATE (state, value) {
    const index = state.list.findIndex((i) => i.id === value.id)
    if (index !== -1) {
      state.list.splice(index, 1, Object.assign(state.list[index], value))
    } else {
      state.list.push(value)
    }
  },
  SCHEDULE_CURRENT (state, value) {
    // need to make a more graceful update to minimize screen disruptions
    if (value) {
      for (const prop of ['id', 'allDay', 'kind', 'endTime', 'startTime', 'title']) {
        if (value[prop] !== state.current[prop]) {
          state.current[prop] = value[prop]
          state.update[prop] = value[prop]
        }
      }
      for (const prop of ['area', 'weekly']) {
        if (value[prop] !== state.current[prop]) {
          state.current[prop] = value[prop]
        }
      }
    } else {
      state.current = {
        id: '',
        allDay: false,
        area: null,
        kind: '',
        endTime: null,
        startTime: null,
        title: '',
        weekly: false
      }
      state.update = {
        id: '',
        allDay: false,
        kind: '',
        endTime: null,
        startTime: null,
        title: ''
      }
    }
  },
  SCHEDULE_UPDATE_ALL_DAY (state, value) {
    state.update.allDay = value
  },
  SCHEDULE_UPDATE_KIND (state, value) {
    state.update.kind = value
  },
  SCHEDULE_UPDATE_END_TIME (state, value) {
    state.update.endTime = value
  },
  SCHEDULE_UPDATE_START_TIME (state, value) {
    state.update.startTime = value
  },
  SCHEDULE_UPDATE_TITLE (state, value) {
    state.update.title = value
  }
}
