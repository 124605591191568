import axios from 'axios'

export default {
  // ======================================================================
  // ==========================List Management=============================
  // ======================================================================
  globalactionList (context, opts) {
    if (!context.state.fetchingList) {
      context.commit('GLOBAL_ACTION_FETCHING_LIST', true)
      return new Promise((resolve, reject) => {
        axios
          .get('/api/globalaction', { params: opts })
          .then(({ data }) => {
            context.commit('GLOBAL_ACTION_LIST', data)
            resolve(data)
          })
          .catch((error) => reject(error))
          .finally(() => context.commit('GLOBAL_ACTION_FETCHING_LIST', false))
      })
    }
  },
  // ==========================Socket Updates==============================
  globalactionCreated (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    const inputs = { }
    if (opts && Array.isArray(opts.populate) && opts.populate.length > 0) {
      inputs.params = { }
      inputs.params.populate = opts.populate
    }
    axios
      .get(`/api/globalaction/${id}`, inputs)
      .then(({ data }) => {
        data.socketUpdate = true
        context.commit('GLOBAL_ACTION_LIST_ADD', data)
      }).catch(() => {
      })
  },
  globalactionUpdated (context, opts) {
    const current = (context.rootState.Organization.current.id === opts.organization)
    if (opts.socket && current) {
      context.commit('GLOBAL_ACTION_SOCKET_UPDATE', new Date())
    } else {
      const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
      const inputs = { }
      if (opts && Array.isArray(opts.populate) && opts.populate.length > 0) {
        inputs.params = { }
        inputs.params.populate = opts.populate
      }
      const inList = context.state.list.find((i) => i.id === id)
      if (context.state.current.id === id || inList) {
        axios
          .get(`/api/globalaction/${id}`, inputs)
          .then(({ data }) => {
            if (data.id === context.state.current.id) {
              context.commit('GLOBAL_ACTION_CURRENT', data)
            }
            if (inList) {
              context.commit('GLOBAL_ACTION_LIST_UPDATE', data)
            }
          }).catch(() => {
          })
      }
    }
  },
  globalactionDestroyed (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    if (id === context.state.current.id) {
      context.commit('GLOBAL_ACTION_CURRENT', null)
    }
    context.commit('GLOBAL_ACTION_LIST_ITEM_REMOVE', opts)
  },
  // ======================================================================
  // ==========================Item Management=============================
  // ======================================================================
  globalactionDetail (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    const inputs = { }
    if (opts && Array.isArray(opts.populate) && opts.populate.length > 0) {
      inputs.params = { }
      inputs.params.populate = opts.populate
    }
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/globalaction/${id}`, inputs)
        .then(({ data }) => {
          context.commit('GLOBAL_ACTION_CURRENT', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  globalactionUpdate (context, opts) {
    const updates = Object.assign({}, context.state.update)
    for (const key of Object.keys(updates)) {
      if ((updates[key] === null || updates[key] === context.state.current[key]) && key !== 'id') {
        delete updates[key]
      }
    }
    return new Promise((resolve, reject) => {
      axios
        .patch(`/api/globalaction/${context.state.current.id}`, updates)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  }
}
