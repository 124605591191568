import axios from 'axios'
export default {
  // ======================================================================
  // ==========================List Management=============================
  // ======================================================================

  systemList (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/system', { params: opts })
        .then(({ data }) => {
          context.commit('SYSTEM_LIST', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  // ==========================Socket Updates==============================
  systemCreated (context, opts) {
    const permissions = context.rootState.User.permissions
    const currentOrgId = context.rootState.Organization.current.id
    const createdOrgId = context.rootState.Organization.create.id
    if ((currentOrgId === opts.organization || createdOrgId === opts.organization) && permissions.system && permissions.system.read) {
      const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
      const inputs = { }
      if (opts && Array.isArray(opts.populate) && opts.populate.length > 0) {
        inputs.params = { }
        inputs.params.populate = opts.populate
      }
      axios
        .get(`/api/system/${id}`, inputs)
        .then(({ data }) => {
          if (data.id) {
            context.commit('SYSTEM_LIST_UPDATE', data)
          }
        }).catch(() => {
        })
    }
  },
  systemUpdated (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    const inputs = { }
    if (opts && Array.isArray(opts.populate) && opts.populate.length > 0) {
      inputs.params = { }
      inputs.params.populate = opts.populate
    }
    const inList = context.state.list.find((i) => i.id === id)
    if (context.state.current.id === id || inList) {
      axios
        .get(`/api/system/${id}`, inputs)
        .then(({ data }) => {
          if (context.state.current.id === data.id) {
            data.socketUpdate = true
            context.commit('SYSTEM_CURRENT', data)
            // if (!opts.background) { // check2.js calls socket updates without a populate so disregard
            //   setChildLists(context, data, false)
            // }
          }
          if (inList) {
            context.commit('SYSTEM_LIST_UPDATE', data)
          }
        }).catch(() => {
        })
    }
  },
  systemDestroyed (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    if (id === context.state.current.id) {
      context.commit('SYSTEM_CURRENT', null)
    }
    context.commit('SYSTEM_LIST_ITEM_REMOVE', opts)
  },
  // ===========================List Edits===============================
  systemAdd (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/system', opts)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => {
          if (error.response && error.response.data && error.response.data.message) {
            reject(error.response.data)
          } else {
            reject(error)
          }
        })
    })
  },
  systemDelete (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/api/system/${opts}`)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  // ======================================================================
  // ==========================Item Management=============================
  // ======================================================================
  systemDetail (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    const inputs = { }
    if (opts && Array.isArray(opts.populate) && opts.populate.length > 0) {
      inputs.params = { }
      inputs.params.populate = opts.populate
    }
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/system/${id}`, inputs)
        .then(({ data }) => {
          context.commit('SYSTEM_CURRENT', data)
          context.commit('SYSTEM_LIST_UPDATE', data)
          if (data.informacastLog) {
            context.commit('INFORMACASTLOG_CURRENT', data.informacastLog)
          }
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  systemUpdate (context, opts) {
    const updates = Object.assign({}, context.state.update)
    for (const key of Object.keys(updates)) {
      if ((updates[key] === null || updates[key] === context.state.current[key]) && key !== 'id' && key !== 'serviceDivision') {
        delete updates[key]
      }
    }
    return new Promise((resolve, reject) => {
      axios
        .patch(`/api/system/${context.state.current.id}`, updates)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  systemChildUpdate (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/api/${opts.kind}/${opts.id}`, opts.data)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  systemClientKey (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/system/${opts.id}/get-client-key`)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  systemClientExe (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/client/make-exe?id=${opts.id}&key=${opts.key}&model=system`)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  systemClientExeUrl (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/client/get-exe?id=${opts.id}&model=system`)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  systemClientResetConnected (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/api/system/${context.state.current.id}`, { keyConnected: false })
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  systemUploadKeys (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/system/${opts.id}/get-upload-keys?name=${opts.file.name}&date=${opts.file.lastModifiedDate.toISOString().substring(0, 10)}`)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  systemScanBackup (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/system/${opts}/scan-backup?async=true`) // TODO change to async=true
        .then(({ data }) => { resolve(data) })
        .catch((error) => reject(error))
    })
  },
  inactiveList (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/system/inactive', { params: opts })
        .then(({ data }) => {
          context.commit('SYSTEM_INACTIVE', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  inactiveByKind (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/system/inactive-counts', { params: opts })
        .then(({ data }) => {
          context.commit('SYSTEM_INACTIVE', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  systemCreate (context, opts) {
    return new Promise((resolve, reject) => {
      if (opts.organization) {
        const create = Object.assign({ organization: opts.organization }, context.state.create)
        for (const key of Object.keys(create)) {
          if (create[key] == null) {
            delete create[key]
          }
        }
        axios
          .post('/api/system', create)
          .then(({ data }) => {
            resolve(data)
          }).catch((error) => reject(error))
      } else {
        reject(new Error('No Organization'))
      }
    })
  },
  systemCreateReset (context, opts) {
    context.commit('SYSTEM_CREATE_RESET', null)
  },
  systemHealthchecks (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/system/healthchecks?id=${context.state.current.id}`)
        .then(({ data }) => {
          context.commit('SYSTEM_CURRENT_HEALTH_CHECKS', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  }
}
