export default function () {
  return {
    childHealthCheckList: [],
    current: {
      active: null,
      aggregation: '',
      aggregationLowerIsWorse: false,
      aggregationThreshold: null,
      alarm: false,
      conditions: '',
      defaultDelay: 0,
      defaultThreshold: 0,
      delay: null,
      description: '',
      history: {},
      id: '',
      kind: '',
      logs: [],
      lowerIsWorse: false,
      maxValue: 0,
      name: '',
      notes: false,
      points: [],
      reenableTime: null,
      resource: null,
      resourceType: '',
      status: '',
      systemKind: '',
      threshold: null,
      triggered: false,
      unitOfMeasure: '',
      value: 0
    },
    kinds: [],
    list: [],
    logs: [],
    update: {
      active: null,
      aggregationThreshold: null,
      delay: null,
      reenableTime: null,
      threshold: null,
      unitOfMeasure: ''
    },
    staticKeys: [
      'aggregation',
      'aggregationLowerIsWorse',
      'alarm',
      'conditions',
      'defaultDelay',
      'defaultThreshold',
      'description',
      'kind',
      'lowerIsWorse',
      'maxValue',
      'name',
      'resource',
      'resourceType',
      'status',
      'systemKind',
      'triggered',
      'unitOfMeasure',
      'value'
    ],
    displayKeys: {
      aggregation: 'Aggregation',
      aggregationLowerIsWorse: 'Default Lower Is Worse',
      alarm: 'Alarm',
      conditions: 'Conditions',
      defaultDelay: 'Default First Alarm Delay',
      defaultThreshold: 'Default Threshold',
      description: 'Description',
      kind: 'Kind',
      lowerIsWorse: 'Lower Is Worse',
      maxValue: 'Max Value',
      name: 'Name',
      resource: 'Parent Resource',
      resourceType: 'Parent Type',
      status: 'Status',
      systemKind: 'System Kind',
      triggered: 'Triggered',
      unitOfMeasure: 'Unit of Measure',
      value: 'Value'
    },
    canInactivateForever: true
  }
}
