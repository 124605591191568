export default {
  FILE_LIST (state, value) {
    if (value && Array.isArray(value)) {
      state.list = value
    }
  },
  FILE_LIST_REMOVE (state, value) {
    const listIndex = state.list.findIndex(f => f.id === value)
    if (listIndex > -1) {
      state.list.splice(listIndex, 1)
    }
  },
  FILE_UPDATE_ATTRIBUTE (state, value) {
    const index = state.list.findIndex((i) => i.id === value.id)
    if (index !== -1) {
      state.list.splice(index, 1, Object.assign(state.list[index], value))
    }
  }
}
