export default {
  BOOKMARK_LIST_UPDATE (state, value) {
    const index = state.list.findIndex((i) => i.id === value.id)
    if (index !== -1) {
      state.list.splice(index, 1, Object.assign(state.list[index], value)) // keep old values such as status
    } else {
      state.list.push(value) // see fetchModel.js
    }
  }
}
