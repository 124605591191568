export default function () {
  return {
    current: {
      list: []
    },
    update: {
      list: []
    }
  }
}
