import axios from 'axios'

export default {
  // ======================================================================
  // ==========================List Management=============================
  // ======================================================================
  servicedivisionList (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/servicedivision')
        .then(({ data }) => {
          context.commit('SERVICEDIVISION_LIST', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  // ==========================Socket Updates==============================
  servicedivisionCreated (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    const inputs = { }
    if (opts && Array.isArray(opts.populate) && opts.populate.length > 0) {
      inputs.params = { }
      inputs.params.populate = opts.populate
    }
    axios
      .get(`/api/servicedivision/${id}`, inputs)
      .then(({ data }) => {
        if (data.id) {
          context.commit('SERVICEDIVISION_LIST_ADD', data)
        }
      }).catch(() => {
      })
  },
  servicedivisionUpdated (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    const inputs = { }
    if (opts && Array.isArray(opts.populate) && opts.populate.length > 0) {
      inputs.params = { }
      inputs.params.populate = opts.populate
    }
    const inList = context.state.list.find((i) => i.id === id)
    if (context.state.current.id === id || inList) {
      axios
        .get(`/api/servicedivision/${id}`, inputs)
        .then(({ data }) => {
          if (data.id) {
            if (context.state.current.id === data.id) {
              data.socketUpdate = true
              context.commit('SERVICEDIVISION_CURRENT', data)
            }
            if (inList) {
              context.commit('SERVICEDIVISION_LIST_UPDATE', data)
            }
          }
        }).catch(() => {
        })
    }
  },
  servicedivisionDestroyed (context, opts) {
    // always try to remove from list
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    context.commit('SERVICEDIVISION_LIST_REMOVE', id)
    if (id === context.state.current.id) {
      context.commit('SERVICEDIVISION_CURRENT', null)
    }
  },
  // ===========================List Edits===============================
  servicedivisionAdd (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/servicedivision', opts)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  servicedivisionDelete (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/api/servicedivision/${opts}`)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  // ======================================================================
  // ==========================Item Management=============================
  // ======================================================================
  servicedivisionDetail (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/servicedivision/${id}`)
        .then(({ data }) => {
          context.commit('SERVICEDIVISION_CURRENT', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  servicedivisionUpdate (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/api/servicedivision/${context.state.current.id}`, context.state.update)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  }
}
