export default {
  HVACCONTROLLER_CURRENT (state, value) {
    if (value) {
      const props = [
        'active',
        'building',
        'changeDetected',
        'discover',
        'id',
        'ip',
        'network',
        'parentController',
        'path',
        'protocol',
        'reenableTime'
      ]
      for (const prop of props) {
        if (value[prop] !== state.current[prop]) {
          state.current[prop] = value[prop]
          state.update[prop] = value[prop]
        }
      }
      const currentProps = [
        'address',
        'alarm',
        'deviceId',
        'firmware',
        'history',
        'model',
        'name',
        'notes',
        'pointCount',
        'serial',
        'status',
        'system',
        'triggered'
      ]
      for (const prop of currentProps) {
        if (value[prop] !== state.current[prop]) {
          state.current[prop] = value[prop]
        }
      }
      if (!value.socketUpdate) { // todo: needs to be changed. ref. building.actions
        state.current.points = value.points
        state.current.childPoints = value.childPoints
        state.current.childHealthChecks = value.childHealthChecks
      }
    } else {
      state.current = {
        id: '',
        active: false,
        address: null,
        building: null,
        changeDetected: false,
        childHealthChecks: [],
        childPoints: [],
        controllers: [],
        deviceId: 0,
        discover: false,
        firmware: '',
        healthChecks: [],
        history: {},
        ip: '',
        model: '',
        name: '',
        network: null,
        notes: false,
        parentController: null,
        path: '',
        pointCount: 0,
        points: [],
        protocol: '',
        reenableTime: null,
        serial: '',
        status: '',
        system: null
      }
      state.update = {
        id: '',
        ip: '',
        network: null,
        protocol: '',
        reenableTime: null,
        active: false,
        building: null,
        changeDetected: false,
        discover: false,
        path: ''
      }
    }
  },
  HVACCONTROLLER_CURRENT_HISTORY (state, value) {
    if (value && Object.keys(value).length > 0) {
      state.current.history = value
    } else {
      state.current.history = {}
    }
  },
  HVACCONTROLLER_CLEAR_CURRENT_HISTORY (state, value) {
    state.current.history = []
  },
  HVACCONTROLLER_CURRENT_NOTES (state, value) {
    state.current.notes = value
  },
  HVACCONTROLLER_PARENT (state, value) {
    if (value) {
      for (const prop of ['id', 'ip', 'network', 'protocol', 'active', 'reenableTime', 'building', 'changeDetected', 'discover',
        'name', 'model', 'serial', 'firmware', 'status', 'parentController', 'system', 'pointCount', 'deviceId', 'address', 'path'
      ]) {
        if (value[prop] !== state.parent[prop]) {
          state.parent[prop] = value[prop]
        }
      }
      state.parent.points = value.points
      state.parent.controllers = value.controllers
      state.parent.healthChecks = value.healthChecks
      state.parent.childPoints = value.childPoints
      state.parent.childHealthChecks = value.childHealthChecks
    } else {
      state.parent = {
        id: '',
        ip: '',
        name: '',
        protocol: '',
        model: '',
        serial: '',
        firmware: '',
        status: '',
        reenableTime: null,
        active: false,
        parentController: null,
        building: null,
        system: null,
        points: [],
        controllers: [],
        healthChecks: [],
        childPoints: [],
        childHealthChecks: [],
        pointCount: 0,
        deviceId: 0,
        network: null,
        address: null,
        changeDetected: false,
        discover: false,
        path: ''
      }
    }
  },
  HVACCONTROLLER_LIST (state, value) {
    state.list = (value && Array.isArray(value)) ? [...(value.sort((a, b) => a.name.localeCompare(b.name)))] : []
  },
  HVACCONTROLLER_LIST_UPDATE (state, value) {
    const index = state.list.findIndex((i) => i.id === value.id)
    if (index !== -1) {
      state.list.splice(index, 1, Object.assign(state.list[index], value))
    } else {
      state.list.push(value) // see fetchModel.js
    }
  },
  HVACSUBCONTROLLER_LIST (state, value) {
    state.childControllerList = (value && Array.isArray(value)) ? [...(value.sort((a, b) => a.name.localeCompare(b.name)))] : []
  },
  HVACCONTROLLER_LIST_PATCH (state, value) {
    try {
      const index = state.current.controllers.findIndex(c => c.id === value.id)
      const listIndex = state.list.findIndex(c => c.id === value.id)
      if (index > -1) {
        const old = state.current.controllers[index]
        state.current.controllers.splice(index, 1, Object.assign(old, value))
      }
      if (listIndex > -1) {
        const old = state.list[listIndex]
        state.list.splice(listIndex, 1, Object.assign(old, value))
      }
    } catch (e) {
    }
  },
  HVACCONTROLLER_LIST_ITEM_REMOVE (state, value) {
    const id = typeof value === 'string' ? value : typeof value === 'object' && value.id ? value.id : ''
    const index = state.current.controllers.findIndex(c => c.id === id)
    if (index > -1) {
      state.current.controllers.splice(index, 1)
    }
    const listIndex = state.list.findIndex(lc => lc.id === id)
    if (listIndex > -1) {
      state.list.splice(listIndex, 1)
    }
  },
  HVACCONTROLLER_UPDATE_HEALTH_CHECK_HISTORY (state, value) {
    if (state.current?.history && state.current.history[value.name]) {
      state.current.history[value.name] = value.history
    }
  },
  HVACCONTROLLER_UPDATE_PATH (state, value) {
    state.update.path = value
  },
  HVACCONTROLLER_UPDATE_PROTOCOL (state, value) {
    state.update.protocol = value
  },
  HVACCONTROLLER_UPDATE_IP (state, value) {
    state.update.ip = value
  },
  HVACCONTROLLER_UPDATE_NETWORK (state, value) {
    state.update.network = value
  },
  HVACCONTROLLER_UPDATE_ACTIVE (state, value) {
    state.update.active = value
  },
  HVACCONTROLLER_UPDATE_REENABLE_TIME (state, value) {
    state.update.reenableTime = value
  },
  HVACCONTROLLER_UPDATE_BUILDING (state, value) {
    state.update.building = value
  },
  HVACCONTROLLER_UPDATE_CHANGE_DETECTED (state, value) {
    state.update.changeDetected = value
  },
  HVACCONTROLLER_UPDATE_DISCOVER (state, value) {
    state.update.discover = value
  }
}
