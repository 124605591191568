export default {
  ORGANIZATION_CLIENT_LOG_FILENAMES (state, value) {
    state.clientLogFileNames = value
  },
  ORGANIZATION_FETCHING_CLIENT_LOG_FILENAMES (state, value) {
    state.fetchingClientLogFileNames = value
  },
  ORGANIZATION_CREATE_BRANCH (state, value) {
    state.create.branch = value
  },
  ORGANIZATION_CREATE_CHECK_INTERVAL (state, value) {
    state.create.checkInterval = value
  },
  ORGANIZATION_CREATE_DAY_CONTACT (state, value) {
    state.create.dayContact = value
  },
  ORGANIZATION_CREATE_ID (state, value) {
    state.create.id = typeof value === 'string' ? value : typeof value === 'object' && value.id ? value.id : ''
  },
  ORGANIZATION_CREATE_NAME (state, value) {
    state.create.name = value
  },
  ORGANIZATION_CREATE_RESET (state, value) {
    state.create.id = value
    state.create.name = value
    state.create.branch = value
    state.create.zip = value
  },
  ORGANIZATION_CREATE_ZIP (state, value) {
    state.create.zip = value
  },
  ORGANIZATION_LIST (state, value) {
    if (value) {
      state.list = value // sorted in controller
    }
  },
  ORGANIZATION_ENERGY_USAGE (state, value) {
    state.energyUsage = value
  },
  ORGANIZATION_HEALTH_CHECKS (state, value) {
    state.healthChecks = value
  },
  ORGANIZATION_HVAC_CONTROLLERS (state, value) {
    state.hvacControllers = value
  },
  ORGANIZATION_HVAC_SUB_CONTROLLERS (state, value) {
    state.hvacSubControllers = value
  },
  ORGANIZATION_HEALTH_CHECK_KINDS (state, value) {
    state.healthCheckKinds = value
  },
  ORGANIZATION_HEALTH (state, value) {
    state.inactive = value.inactive
    state.organizations = value.organizations
    state.organizationsOk = value.organizationsOk
    state.systems = value.systems
    state.buildings = value.buildings
    state.systemsOk = value.systemsOk
  },
  ORGANIZATION_LIST_ADD (state, value) {
    if (state.list.findIndex((i) => i.id === value.id) === -1) {
      // handle newly added org
      value.systems = value.systems && Array.isArray(value.systems) ? value.systems.length : 0
      value.buildings = value.buildings && Array.isArray(value.buildings) ? value.buildings.length : 0
      state.list.push(value)
    }
  },
  ORGANIZATION_LIST_ITEM_REMOVE (state, value) {
    const id = typeof value === 'string' ? value : typeof value === 'object' && value.id ? value.id : ''
    const index = state.list.findIndex((i) => i.id === id)
    if (index !== -1) {
      state.list.splice(index, 1)
    }
  },
  ORGANIZATION_LIST_UPDATE (state, value) {
    const index = state.list.findIndex((i) => i.id === value.id)
    if (index !== -1) {
      state.list.splice(index, 1, Object.assign(state.list[index], value))
    } else {
      state.list.push(value) // see fetchModel.js
    }
  },
  ORGANIZATION_LAST_UPDATED_BY (state, value) {
    for (const [index, org] of Object.entries(state.list)) {
      const lstUpBy = { lastUpdatedBy: '-' }
      if (value[org.id]) {
        lstUpBy.lastUpdatedBy = value[org.id]
      }
      state.list.splice(index, 1, Object.assign(state.list[index], lstUpBy))
    }
  },
  ORGANIZATION_CURRENT (state, value) {
    // need to make a more graceful update to minimize screen disruptions
    if (value) {
      for (const prop of [
        'active',
        'allowUpgrade',
        'branch',
        'checkInterval',
        'clientLogsPath',
        'dayContact',
        'hasClient',
        'hasRpiClient',
        'id',
        'name',
        'reenableTime',
        'zip'
      ]) {
        if (value[prop] !== state.current[prop]) {
          state.current[prop] = value[prop]
          state.update[prop] = value[prop]
        }
      }
      for (const prop of [
        'checkFinished',
        'checkStarted',
        'clientCommFailure',
        'clientHostInfo',
        'clientVersion',
        'externalIp',
        'internalIp',
        'keyConnected',
        'lastLog',
        'lastestClientVersion',
        'rpiMacAddress',
        'status'
      ]) {
        if (value[prop] !== state.current[prop]) {
          state.current[prop] = value[prop]
        }
      }
    } else {
      state.current = {
        id: '',
        active: false,
        alarms: {},
        allowUpgrade: false,
        branch: null,
        checkInterval: 600,
        checkStarted: null,
        checkFinished: null,
        dayContact: '',
        clientLogsPath: '',
        clientVersion: '',
        clientHostInfo: '',
        externalIp: '',
        energyUsage: {},
        forecast: [],
        hasClient: false,
        hasRpiClient: false,
        internalIp: '',
        keyConnected: false,
        lastLog: '',
        lastLogList: {},
        lastestClientVersion: '',
        name: '',
        reenableTime: null,
        status: '',
        zip: ''
      }
      state.update = {
        id: '',
        active: false,
        allowUpgrade: false,
        branch: null,
        checkInterval: 600,
        clientLogsPath: '',
        hasClient: false,
        hasRpiClient: false,
        name: '',
        reenableTime: null,
        zip: ''
      }
    }
  },
  ORGANIZATION_CURRENT_DAY_CONTACT (state, value) {
    state.current.dayContact = value
  },
  ORGANIZATION_CURRENT_FORECAST (state, value) {
    state.current.forecast = value
  },
  ORGANIZATION_CURRENT_SERVERS (state, value) {
    state.current.servers = value
  },
  ORGANIZATION_CURRENT_HEALTH_CHECKS (state, value) {
    state.current.healthChecks = value
  },
  ORGANIZATION_CURRENT_LAST_LOG (state, value) {
    if (value && value !== '') {
      if (state.current.lastLog && state.current.lastLog !== '') {
        const key = new Date().toISOString()
        state.current.lastLogList[key] = ''
        state.current.lastLogList[key] = state.current.lastLog
      }
      state.current.lastLog = value
    }
  },
  ORGANIZATION_CURRENT_TAGS (state, value) {
    state.current.tags = value
  },
  ORGANIZATION_CURRENT_VERSION_HISTORY (state, value) {
    state.current.versionHistory = value
  },
  ORGANIZATION_UPDATE_DOWNLOADABLE_CLIENT_LOG_URL (state, value) {
    state.downloadableClientLogUrl = value
  },
  ORGANIZATION_UPDATE_NAME (state, value) {
    state.update.name = value
  },
  ORGANIZATION_UPDATE_BRANCH (state, value) {
    state.update.branch = value
  },
  ORGANIZATION_UPDATE_CLIENT_LOGS_PATH (state, value) {
    state.update.clientLogsPath = value
  },
  ORGANIZATION_UPDATE_ZIP (state, value) {
    state.update.zip = value
  },
  ORGANIZATION_UPDATE_ACTIVE (state, value) {
    state.update.active = value
  },
  ORGANIZATION_UPDATE_DAY_CONTACT (state, value) {
    state.update.dayContact = value
  },
  ORGANIZATION_UPDATE_REENABLE_TIME (state, value) {
    state.update.reenableTime = value
  },
  ORGANIZATION_UPDATE_HAS_CLIENT (state, value) {
    state.update.hasClient = value
  },
  ORGANIZATION_UPDATE_HAS_RPI_CLIENT (state, value) {
    state.update.hasRpiClient = value
  },
  ORGANIZATION_UPDATE_CHECK_INTERVAL (state, value) {
    state.update.checkInterval = value
  },
  ORGANIZATION_UPDATE_ALLOW_UPGRADE (state, value) {
    state.update.allowUpgrade = value
  }
}
