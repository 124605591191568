export default {
  USERORG_LIST (state, value) {
    if (value && Array.isArray(value)) {
      state.list = value.reduce((res, val) => {
        if (val.user?.id) {
          val.email = val.user.email ?? ''
        }
        res.push(val)
        return res
      }, [])
    } else {
      state.list = []
    }
  },
  USERORG_LIST_CLEAR (state, value) {
    state.list = []
  },
  USERORG_LIST_UPDATE (state, value) {
    const index = state.list.findIndex((i) => i.id === value.id)
    if (index !== -1) {
      state.list.splice(index, 1, Object.assign(state.list[index], value))
    } else {
      state.list.push(value) // new
    }
  },
  USERORG_LIST_REMOVE (state, value) {
    const index = state.list.findIndex((i) => i.id === value.id)
    if (index !== -1) {
      state.list.splice(index, 1)
    }
  }
}
