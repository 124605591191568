export default {
  BOSCHPANEL_LIST (state, value) {
    state.list = (value && Array.isArray(value)) ? [...(value.sort((a, b) => a.name.localeCompare(b.name)))] : []
  },
  BOSCHPANEL_LIST_ADD (state, value) {
    if (state.list.findIndex((i) => i.id === value.id) === -1) {
      state.list.push(Object.assign({}, value))
    }
  },
  BOSCHPANEL_LIST_ITEM_REMOVE (state, value) {
    const id = typeof value === 'string' ? value : typeof value === 'object' && value.id ? value.id : ''
    const index = state.list.findIndex((i) => i.id === id)
    if (index !== -1) {
      state.list.splice(index, 1)
    }
  },
  BOSCHPANEL_LIST_UPDATE (state, value) {
    const index = state.list.findIndex((i) => i.id === value.id)
    if (index !== -1) {
      state.list.splice(index, 1, Object.assign(state.list[index], value))
    } else {
      state.list.push(value)
    }
  },
  BOSCHPANEL_CURRENT (state, value) {
    const props = [
      'firmware',
      'name',
      'server',
      'status',
      'statusNumber',
      'notes',
      'system'
    ]
    if (value) {
      for (const prop of props) {
        if (value[prop] !== state.current[prop]) {
          state.current[prop] = value[prop]
        }
      }
      for (const prop of ['id', 'active', 'reenableTime', 'building']) {
        if (value[prop] !== state.current[prop]) {
          state.current[prop] = value[prop]
          state.update[prop] = value[prop]
        }
      }
    } else {
      state.current = {
        id: '',
        active: false,
        building: '',
        firmware: '',
        history: {},
        name: '',
        reenableTime: '',
        server: '',
        statusNumber: null,
        status: '',
        system: '',
        notes: false
      }
      state.update = {
        id: '',
        building: ''
      }
    }
  },
  BOSCHPANEL_CURRENT_HISTORY (state, value) {
    if (value && Object.keys(value).length > 0) {
      state.current.history = value
    } else {
      state.current.history = {}
    }
  },
  BOSCHPANEL_CURRENT_NOTES (state, value) {
    state.current.notes = value
  },
  BOSCHPANEL_UPDATE_ACTIVE (state, value) {
    state.update.active = value
  },
  BOSCHPANEL_UPDATE_HEALTH_CHECK_HISTORY (state, value) {
    if (state.current?.history && state.current.history[value.name]) {
      state.current.history[value.name] = value.history
    }
  },
  BOSCHPANEL_UPDATE_REENABLE_TIME (state, value) {
    state.update.reenableTime = value
  },
  BOSCHPANEL_UPDATE_BUILDING (state, value) {
    state.update.building = value
  }
}
