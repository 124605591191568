export default function () {
  return {
    ip: { type: 'string' },
    current: {
      id: '',
      active: false,
      building: '',
      description: '',
      inputPortCount: 0,
      outputPortCount: 0,
      name: '',
      ip: '',
      reenableTime: null,
      system: '',
      vendorId: ''
    },
    update: {
      active: false,
      building: '',
      reenableTime: null
    },
    fetchingList: false,
    list: []
  }
}
