export default function () {
  return {
    count: 0,
    ok: 0,
    list: [],
    inactive: {},
    current: {
      id: '',
      active: false,
      alarm2Delay: 0,
      alarm3Delay: 0,
      allowImages: false,
      allowUpgrade: false,
      backupId: '',
      bbmd: '',
      checkFinished: null,
      checkInterval: 600,
      checkStarted: null,
      clientVersion: '',
      dayContact: '',
      defaultRecording: 0,
      defaultRetention: 0,
      externalIp: '',
      hasClient: false,
      healthChecks: [],
      internalIp: '',
      isMonitored: false,
      keyConnected: false,
      kind: '',
      lastLog: '',
      organization: null,
      recordInformacastLogs: false,
      reenableTime: null,
      repeatAlarm3: false,
      resourceCheckInterval: null,
      serviceDivision: {},
      status: '',
      timeout: 15,
      cameras: [],
      encoders: [],
      hvacControllers: [],
      points: [],
      servers: []
    },
    update: {
      active: false,
      alarm2Delay: 0,
      alarm3Delay: 0,
      allowImages: false,
      allowUpgrade: false,
      backupId: '',
      bbmd: '',
      checkInterval: 600,
      dayContact: '',
      defaultRecording: 0,
      defaultRetention: 0,
      hasClient: false,
      id: '',
      isMonitored: false,
      kind: '',
      recordInformacastLogs: false,
      repeatAlarm3: false,
      reenableTime: null,
      resourceCheckInterval: null,
      serviceDivision: null,
      timeout: 15
    },
    create: {
      alarm2Delay: 360,
      alarm3Delay: 1080,
      backupId: null,
      bbmd: null,
      dayContact: null,
      defaultRecording: 5,
      defaultRetention: 15,
      kind: null,
      repeatAlarm3: false,
      timeout: 15
    },
    kinds: [
      '',
      'Access',
      'Energy',
      'Health Monitor',
      'HVAC',
      'Lighting',
      'MassNotification',
      'Surveillance'
    ],
    canInactivateForever: false
  }
}
