import axios from 'axios'
export default {
  // ======================================================================
  // ==========================List Management=============================
  // ======================================================================

  roomList (context, opts) {
    let noVuex = false
    if (opts && opts.noVuex) {
      noVuex = true
      delete opts.noVuex
    }
    return new Promise((resolve, reject) => {
      axios
        .get('/api/room', { params: opts })
        .then(({ data }) => {
          if (!noVuex) {
            context.commit('ROOM_LIST', data)
          }
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  // ==========================Socket Updates==============================
  roomCreated (context, opts) {
    const buildingId = context.rootState.Building.current.id
    const permission = (context.rootState.User.permissions.room && context.rootState.User.permissions.room.read)
    if (permission && opts?.building && opts.building === buildingId) {
      const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
      const inputs = { }
      if (opts && Array.isArray(opts.populate) && opts.populate.length > 0) {
        inputs.params = { }
        inputs.params.populate = opts.populate
      }
      axios
        .get(`/api/room/${id}`, inputs)
        .then(({ data }) => {
          if (data.id) {
            context.commit('ROOM_LIST_UPDATE', data)
          }
        }).catch(() => {
        })
    }
  },
  roomUpdated (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    const inputs = { }
    if (opts && Array.isArray(opts.populate) && opts.populate.length > 0) {
      inputs.params = { }
      inputs.params.populate = opts.populate
    }
    if (context.state.current.id === id || context.state.list.find((i) => i.id === id) !== undefined) {
      axios
        .get(`/api/room/${id}`, inputs)
        .then(({ data }) => {
          if (data.id) {
            data.socketUpdate = true
            context.commit('ROOM_LIST_UPDATE', data)
            if (data.id === context.state.current.id) {
              context.commit('ROOM_CURRENT', data)
            }
          }
        }).catch(() => {
        })
    }
  },
  roomDestroyed (context, opts) {
    // always try to remove from list
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    if (id === context.state.current.id) {
      context.commit('ROOM_CURRENT', null)
    }
    context.commit('ROOM_LIST_ITEM_REMOVE', opts)
  },
  // ===========================List Edits===============================
  roomAdd (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/room', opts)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  roomDelete (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/api/room/${opts}`)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  // ======================================================================
  // ==========================Item Management=============================
  // ======================================================================
  roomDetail (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    const inputs = { }
    if (opts && Array.isArray(opts.populate) && opts.populate.length > 0) {
      inputs.params = { }
      inputs.params.populate = opts.populate
    }
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/room/${id}`, inputs)
        .then(({ data }) => {
          context.commit('ROOM_CURRENT', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  roomUpdate (context, opts) {
    const updates = Object.assign({}, context.state.update)
    for (const key of Object.keys(updates)) {
      if (updates[key] == null) {
        delete updates[key]
      }
    }
    return new Promise((resolve, reject) => {
      axios
        .patch(`/api/room/${context.state.current.id}`, updates)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  roomAddTags (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/roomtag', opts)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  roomRemoveTags (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .delete('/api/roomtag', { data: opts })
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  roomSetArea (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/api/room/${opts.id}`, { area: opts.area })
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  }
}
