import store from '../store/index'

const routes = [
  {
    path: '/auth',
    component: () => import('../layouts/bareLayout.vue'),
    children: [
      {
        path: 'login',
        name: 'Login',
        component: () => import('../pages/auth/login.vue'),
        meta: { requiresUnAuth: true }
      },
      {
        path: 'logout',
        name: 'Logout',
        component: () => import('../pages/auth/login.vue'),
        beforeEnter: async (to, from, next) => {
          await store().dispatch('userSignOut')
          next()
        },
        meta: { noFlash: true, requiresAuth: true }
      },
      {
        path: '/auth/oauthCallback',
        name: 'oauth-callback',
        component: () => import('../pages/auth/oauthCallback.vue'),
        meta: { noFlash: true, requiresAuth: false }
      },
      {
        path: 'confirm-email',
        name: 'ConfirmEmail',
        component: () => import('../pages/auth/confirmEmail.vue'),
        meta: { }
      },
      {
        path: 'recover-password',
        name: 'RecoverPassword',
        component: () => import('../pages/auth/recoverPassword.vue'),
        beforeEnter: async (to, from, next) => {
          await store().dispatch('userSignOut')
          next()
        },
        meta: { }
      },
      {
        path: 'forgot-password',
        name: 'ForgotPassword',
        component: () => import('../pages/auth/forgotPassword.vue'),
        beforeEnter: async (to, from, next) => {
          await store().dispatch('userSignOut')
          next()
        },
        meta: { }
      }
    ]
  },
  {
    path: '/email',
    component: () => import('../layouts/emailLayout.vue'),
    children: [
      {
        path: 'summary',
        name: 'Summary',
        component: () => import('../pages/email/summary.vue'),
        meta: { requiresAuth: false, noFlash: true }
      }
    ]
  },
  {
    path: '/unsubscribe',
    component: () => import('../pages/unsubscribe.vue'),
    meta: { noFlash: true }
  },
  {
    path: '/admin/',
    component: () => import('../layouts/mainLayout.vue'),
    children: [
      {
        path: 'area/rooms',
        name: 'Area Rooms',
        components: {
          default: () => import('../pages/admin/area/rooms.vue')
        },
        meta: { requiresAuth: true, drawer: 'settings', resource: 'area' }
      },
      {
        path: 'area/schedule',
        name: 'Area Schedule',
        components: {
          default: () => import('../pages/admin/area/schedule.vue')
        },
        meta: { requiresAuth: true, drawer: 'settings', resource: 'area' }
      },
      {
        path: 'area/settings',
        name: 'Area Settings',
        components: {
          default: () => import('../pages/admin/area/settings.vue')
        },
        meta: { requiresAuth: true, drawer: 'settings', resource: 'area' }
      },
      {
        path: 'branches',
        name: 'Branches',
        component: () => import('../pages/admin/branches.vue'),
        meta: { requiresAuth: true, drawer: 'settings' }
      },
      {
        path: 'budget/settings',
        name: 'Budget Settings',
        components: {
          default: () => import('../pages/admin/budget/settings.vue')
        },
        meta: { requiresAuth: true, drawer: 'settings', resource: 'budget' }
      },
      {
        path: 'branch/settings',
        name: 'Branch Settings',
        components: {
          default: () => import('../pages/admin/branch/settings.vue')
        },
        meta: { requiresAuth: true, drawer: 'settings' }
      },
      {
        path: 'branch/organizations',
        name: 'Branch Organizations',
        components: {
          default: () => import('../pages/admin/branch/organizations.vue')
        },
        meta: { requiresAuth: true, drawer: 'settings', resource: 'branch' }
      },
      {
        path: 'branch/users',
        name: 'Branch Users',
        components: {
          default: () => import('../pages/admin/branch/users.vue')
        },
        meta: { requiresAuth: true, drawer: 'settings', resource: 'branch' }
      },
      /* Building */
      {
        path: 'building/areas',
        name: 'Building Areas',
        components: {
          default: () => import('../pages/admin/building/areas.vue')
        },
        meta: { requiresAuth: true, drawer: 'settings', resource: 'building' }
      },
      {
        path: 'building/budgets',
        name: 'Building Budgets',
        components: {
          default: () => import('../pages/admin/building/budgets.vue')
        },
        meta: { requiresAuth: true, drawer: 'settings', resource: 'building' }
      },
      {
        path: 'building/devices',
        name: 'Building Devices',
        components: {
          default: () => import('../pages/admin/building/devices.vue')
        },
        meta: { requiresAuth: true, drawer: 'settings', resource: 'building' }
      },
      {
        path: 'building/rooms',
        name: 'Building Rooms',
        components: {
          default: () => import('../pages/admin/building/rooms.vue')
        },
        meta: { requiresAuth: true, drawer: 'settings', resource: 'building' }
      },
      {
        path: 'building/servers',
        name: 'Building Servers',
        components: {
          default: () => import('../pages/admin/building/servers.vue')
        },
        meta: { requiresAuth: true, drawer: 'settings', resource: 'building' }
      },
      {
        path: 'building/settings',
        name: 'Building Settings',
        components: {
          default: () => import('../pages/admin/building/settings.vue')
        },
        meta: { requiresAuth: true, drawer: 'settings', resource: 'building' }
      },
      {
        path: 'building/tags',
        name: 'Building Tags',
        components: {
          default: () => import('../pages/admin/building/tags.vue')
        },
        meta: { requiresAuth: true, drawer: 'settings', resource: 'building' }
      },
      {
        path: 'building/points',
        name: 'Building Points',
        components: {
          default: () => import('../pages/admin/building/points.vue')
        },
        meta: { requiresAuth: true, drawer: 'settings', resource: 'building' }
      },
      {
        path: 'building/healthchecks',
        name: 'Building Health Checks',
        components: {
          default: () => import('../pages/admin/building/healthchecks.vue')
        },
        meta: { requiresAuth: true, drawer: 'settings', resource: 'building' }
      },
      /* Camera */
      {
        path: 'camera/settings',
        name: 'Camera Settings',
        components: {
          default: () => import('../pages/admin/camera/settings.vue')
        },
        meta: { requiresAuth: true, drawer: 'settings', resource: 'camera' }
      },
      {
        path: 'camera/healthchecks',
        name: 'Camera Health Checks',
        components: {
          default: () => import('../pages/admin/camera/healthchecks.vue')
        },
        meta: { requiresAuth: true, drawer: 'settings', resource: 'camera' }
      },
      /* Door */
      {
        path: 'door/settings',
        name: 'Door Settings',
        components: {
          default: () => import('../pages/admin/door/settings.vue')
        },
        meta: { requiresAuth: true, drawer: 'settings', resource: 'door' }
      },
      {
        path: 'door/healthchecks',
        name: 'Door Health Checks',
        components: {
          default: () => import('../pages/admin/door/healthchecks.vue')
        },
        meta: { requiresAuth: true, drawer: 'settings', resource: 'door' }
      },
      /* File */
      {
        path: 'file/installers',
        name: 'File Installers',
        component: () => import('../pages/admin/file/installers.vue'),
        meta: { requiresAuth: true, drawer: 'settings' }
      },
      {
        path: 'file/downloads',
        name: 'File Downloads',
        component: () => import('../pages/admin/file/downloads.vue'),
        meta: { requiresAuth: true, drawer: 'settings' }
      },
      /* Group */
      {
        path: 'groups',
        name: 'Groups',
        component: () => import('../pages/admin/groups.vue'),
        meta: { requiresAuth: true, drawer: 'settings' }
      },
      {
        path: 'group/permissions',
        name: 'Group Permissions',
        components: {
          default: () => import('../pages/admin/group/permissions.vue')
        },
        meta: { requiresAuth: true, drawer: 'settings', resource: 'group' }
      },
      {
        path: 'group/settings',
        name: 'Group Settings',
        components: {
          default: () => import('../pages/admin/group/settings.vue')
        },
        meta: { requiresAuth: true, drawer: 'settings', resource: 'group' }
      },
      {
        path: 'group/users',
        name: 'Group Users',
        components: {
          default: () => import('../pages/admin/group/users.vue')
        },
        meta: { requiresAuth: true, drawer: 'settings', resource: 'group' }
      },
      // health checks
      {
        path: 'healthcheckdefs',
        name: 'Health Check Defs',
        component: () => import('../pages/admin/healthcheckdefs.vue'),
        meta: { requiresAuth: true, drawer: 'settings' }
      },
      {
        path: 'healthcheckdef/settings',
        name: 'Health Check Def Settings',
        components: {
          default: () => import('../pages/admin/healthcheckdef/settings.vue')
        },
        meta: { requiresAuth: true, drawer: 'settings', resource: 'healthcheckdef' }
      },
      {
        path: 'healthcheck/settings',
        name: 'Health Check Settings',
        components: {
          default: () => import('../pages/admin/healthcheck/settings.vue')
        },
        meta: { requiresAuth: true, drawer: 'settings', resource: 'healthcheck' }
      },
      /* Help page */
      {
        path: 'help',
        name: 'Help',
        component: () => import('../pages/admin/help.vue'),
        meta: { requiresAuth: true, drawer: 'settings' }
      },
      /* On Call Schedule */
      {
        path: 'oncallschedules',
        name: 'On Call Schedule',
        components: {
          default: () => import('../pages/admin/oncallschedules.vue')
        },
        meta: { requiresAuth: true, drawer: 'settings' }
      },
      {
        path: 'oncallschedule/settings',
        name: 'On Call Schedule Settings',
        components: {
          default: () => import('../pages/admin/oncallschedule/settings.vue')
        },
        meta: { requiresAuth: true, drawer: 'settings', resource: 'oncallschedule' }
      },
      {
        path: 'oncallschedule/upload',
        name: 'On Call Schedule Upload',
        components: {
          default: () => import('../pages/admin/oncallschedule/upload.vue')
        },
        meta: { requiresAuth: true, drawer: 'settings' }
      },
      {
        path: 'servicedivisions',
        name: 'Service Divisions',
        components: {
          default: () => import('../pages/admin/servicedivisions.vue')
        },
        meta: { requiresAuth: true, drawer: 'settings' }
      },
      {
        path: 'hvaccontroller/settings',
        name: 'HVAC Controller Settings',
        components: {
          default: () => import('../pages/admin/hvaccontroller/settings.vue')
        },
        meta: { requiresAuth: true, drawer: 'settings', resource: 'hvaccontroller' }
      },
      {
        path: 'hvaccontroller/parent',
        name: 'Parent HVAC Controller Settings',
        components: {
          default: () => import('../pages/admin/hvaccontroller/parent.vue')
        },
        meta: { requiresAuth: true, drawer: 'settings', resource: 'hvaccontroller' }
      },
      {
        path: 'hvaccontroller/subcontrollers',
        name: 'HVAC Controller Sub Controllers',
        components: {
          default: () => import('../pages/admin/hvaccontroller/subcontrollers.vue')
        },
        meta: { requiresAuth: true, drawer: 'settings', resource: 'hvaccontroller' }
      },
      {
        path: 'hvaccontroller/healthchecks',
        name: 'HVAC Controller Health Checks',
        components: {
          default: () => import('../pages/admin/hvaccontroller/healthchecks.vue')
        },
        meta: { requiresAuth: true, drawer: 'settings', resource: 'hvaccontroller' }
      },
      {
        path: 'hvaccontroller/points',
        name: 'HVAC Controller Points',
        components: {
          default: () => import('../pages/admin/hvaccontroller/points.vue')
        },
        meta: { requiresAuth: true, drawer: 'settings', resource: 'hvaccontroller' }
      },
      {
        path: 'rpiclients',
        name: 'RPI Clients',
        component: () => import('../pages/admin/rpiclients.vue'),
        meta: { requiresAuth: true, drawer: 'settings' }
      },
      /* Organization */
      {
        path: 'organizations',
        name: 'Organizations',
        component: () => import('../pages/admin/organizations.vue'),
        meta: { requiresAuth: true, drawer: 'settings' }
      },
      {
        path: 'organization/budgets',
        name: 'Organization Budgets',
        components: {
          default: () => import('../pages/admin/organization/budgets.vue')
        },
        meta: { requiresAuth: true, drawer: 'settings', resource: 'organization' }
      },
      {
        path: 'organization/buildings',
        name: 'Organization Buildings',
        components: {
          default: () => import('../pages/admin/organization/buildings.vue')
        },
        meta: { requiresAuth: true, drawer: 'settings', resource: 'organization' }
      },
      {
        path: 'organization/client',
        name: 'Organization Client',
        components: {
          default: () => import('../pages/admin/organization/client.vue')
        },
        meta: { requiresAuth: true, drawer: 'settings', resource: 'organization' }
      },
      {
        path: 'organization/create',
        name: 'Organization Create',
        components: {
          default: () => import('../pages/admin/organization/create.vue')
        },
        meta: { requiresAuth: true, drawer: 'general' }
      },
      {
        path: 'organization/healthchecks',
        name: 'Organization Health Checks',
        components: {
          default: () => import('../pages/admin/organization/healthchecks.vue')
        },
        meta: { requiresAuth: true, drawer: 'settings', resource: 'organization' }
      },
      {
        path: 'organization/schedules',
        name: 'Organization Schedules',
        components: {
          default: () => import('../pages/admin/organization/schedules.vue')
        },
        meta: { requiresAuth: true, drawer: 'settings', resource: 'organization' }
      },
      {
        path: 'organization/settings',
        name: 'Organization Settings',
        components: {
          default: () => import('../pages/admin/organization/settings.vue')
        },
        meta: { requiresAuth: true, drawer: 'settings', resource: 'organization' }
      },
      {
        path: 'organization/systems',
        name: 'Organization Systems',
        components: {
          default: () => import('../pages/admin/organization/systems.vue')
        },
        meta: { requiresAuth: true, drawer: 'settings', resource: 'organization' }
      },
      {
        path: 'organization/tags',
        name: 'Organization Tags',
        components: {
          default: () => import('../pages/admin/organization/tags.vue')
        },
        meta: { requiresAuth: true, drawer: 'settings', resource: 'organization' }
      },
      {
        path: 'organization/users',
        name: 'Organization Groups',
        components: {
          default: () => import('../pages/admin/organization/users.vue')
        },
        meta: { requiresAuth: true, drawer: 'settings', resource: 'organization' }
      },
      /* Panel */
      {
        path: 'panel/settings',
        name: 'Panel Settings',
        components: {
          default: () => import('../pages/admin/panel/settings.vue')
        },
        meta: { requiresAuth: true, drawer: 'settings', resource: 'organization' }
      },
      {
        path: 'panel/doors',
        name: 'Panel Doors',
        components: {
          default: () => import('../pages/admin/panel/doors.vue')
        },
        meta: { requiresAuth: true, drawer: 'settings', resource: 'panel' }
      },
      {
        path: 'panel/healthchecks',
        name: 'Panel Health Checks',
        components: {
          default: () => import('../pages/admin/panel/healthchecks.vue')
        },
        meta: { requiresAuth: true, drawer: 'settings', resource: 'panel' }
      },
      /* Point */
      {
        path: 'point/settings',
        name: 'Point Settings',
        components: {
          default: () => import('../pages/admin/point/settings.vue')
        },
        meta: { requiresAuth: true, drawer: 'settings', resource: 'point' }
      },
      {
        path: 'point/healthchecks',
        name: 'Point Health Checks',
        components: {
          default: () => import('../pages/admin/point/healthchecks.vue')
        },
        meta: { requiresAuth: true, drawer: 'settings', resource: 'point' }
      },
      /* Server */
      {
        path: 'server/cameras',
        name: 'Server Cameras',
        components: {
          default: () => import('../pages/admin/server/cameras.vue')
        },
        meta: { requiresAuth: true, drawer: 'settings', resource: 'server' }
      },
      {
        path: 'server/settings',
        name: 'Server Settings',
        components: {
          default: () => import('../pages/admin/server/settings.vue')
        },
        meta: { requiresAuth: true, drawer: 'settings', resource: 'server' }
      },
      {
        path: 'server/healthchecks',
        name: 'Server Health Checks',
        components: {
          default: () => import('../pages/admin/server/healthchecks.vue')
        },
        meta: { requiresAuth: true, drawer: 'settings', resource: 'server' }
      },
      /* Room */
      {
        path: 'room/settings',
        name: 'Room Settings',
        components: {
          default: () => import('../pages/admin/room/settings.vue')
        },
        meta: { requiresAuth: true, drawer: 'settings', resource: 'room' }
      },
      {
        path: 'room/tags',
        name: 'Room Tags',
        components: {
          default: () => import('../pages/admin/room/tags.vue')
        },
        meta: { requiresAuth: true, drawer: 'settings', resource: 'room' }
      },
      {
        path: 'room/points',
        name: 'Room Points',
        components: {
          default: () => import('../pages/admin/room/points.vue')
        },
        meta: { requiresAuth: true, drawer: 'settings', resource: 'room' }
      },
      {
        path: 'room/healthchecks',
        name: 'Room Health Checks',
        components: {
          default: () => import('../pages/admin/room/healthchecks.vue')
        },
        meta: { requiresAuth: true, drawer: 'settings', resource: 'room' }
      },
      /* Service Division */
      {
        path: 'servicedivision/settings',
        name: 'Service Division Settings',
        components: {
          default: () => import('../pages/admin/servicedivision/settings.vue')
        },
        meta: { requiresAuth: true, drawer: 'settings', resource: 'servicedivision' }
      },
      {
        path: 'servicedivision/systems',
        name: 'Service Division Systems',
        components: {
          default: () => import('../pages/admin/servicedivision/systems.vue')
        },
        meta: { requiresAuth: true, drawer: 'settings', resource: 'servicedivision' }
      },
      /* System */
      {
        path: 'system/client',
        name: 'System Client',
        components: {
          default: () => import('../pages/admin/system/client.vue')
        },
        meta: { requiresAuth: true, drawer: 'settings', resource: 'system' }
      },
      {
        path: 'system/devices',
        name: 'System Devices',
        components: {
          default: () => import('../pages/admin/system/devices.vue')
        },
        meta: { requiresAuth: true, drawer: 'settings', resource: 'system' }
      },
      {
        path: 'system/healthchecks',
        name: 'System Health Checks',
        components: {
          default: () => import('../pages/admin/system/healthchecks.vue')
        },
        meta: { requiresAuth: true, drawer: 'settings', resource: 'system' }
      },
      {
        path: 'system/settings',
        name: 'System Settings',
        components: {
          default: () => import('../pages/admin/system/settings.vue')
        },
        meta: { requiresAuth: true, drawer: 'settings', resource: 'system' }
      },
      {
        path: 'system/servers',
        name: 'System Servers',
        components: {
          default: () => import('../pages/admin/system/servers.vue')
        },
        meta: { requiresAuth: true, drawer: 'settings', resource: 'system' }
      },
      {
        path: 'tag/settings',
        name: 'Tag Settings',
        components: {
          default: () => import('../pages/admin/tag/settings.vue')
        },
        meta: { requiresAuth: true, drawer: 'settings', resource: 'tag' }
      },
      /* User */
      {
        path: 'users',
        name: 'Users',
        component: () => import('../pages/admin/users.vue'),
        meta: { requiresAuth: true, drawer: 'settings' }
      },
      {
        path: 'user/create',
        name: 'User Create',
        components: {
          default: () => import('../pages/admin/user/create.vue')
        },
        meta: { requiresAuth: true, drawer: 'general' }
      },
      {
        path: 'user/emaillogs',
        name: 'User Email Logs',
        components: {
          default: () => import('../pages/admin/user/emaillogs.vue')
        },
        meta: { requiresAuth: true, drawer: 'settings', resource: 'user' }
      },
      {
        path: 'user/groups',
        name: 'User Groups',
        components: {
          default: () => import('../pages/admin/user/groups.vue')
        },
        meta: { requiresAuth: true, drawer: 'settings', resource: 'user' }
      },
      {
        path: 'user/settings',
        name: 'User Settings',
        components: {
          default: () => import('../pages/admin/user/settings.vue')
        },
        meta: { requiresAuth: true, drawer: 'settings', resource: 'user' }
      },
      {
        path: 'user/organizations',
        name: 'User Organizations',
        components: {
          default: () => import('../pages/admin/user/organizations.vue')
        },
        meta: { requiresAuth: true, drawer: 'settings', resource: 'user' }
      }
    ]
  },
  {
    path: '/',
    component: () => import('../layouts/mainLayout.vue'),
    children: [
      {
        path: '',
        name: 'Overview',
        component: () => import('../pages/home.vue'),
        meta: { requiresAuth: true, startAtMenu: true, drawer: 'homepage' }
      },
      {
        path: 'access/accessGroups',
        name: 'Access Groups',
        component: () => import('../pages/access/accessGroups.vue'),
        meta: { requiresAuth: true, drawer: 'access' }
      },
      {
        path: 'access/acmPolicies',
        name: 'ACM Policies',
        component: () => import('../pages/access/acmPolicies.vue'),
        meta: { requiresAuth: true, drawer: 'access' }
      },
      {
        path: 'access/boschPanels',
        name: 'Bosch Panels',
        component: () => import('../pages/access/boschPanels.vue'),
        meta: { requiresAuth: true, drawer: 'access' }
      },
      {
        path: 'access/boschPanelPoints',
        name: 'Bosch Panel Points',
        component: () => import('../pages/access/boschPanelPoints.vue'),
        meta: { requiresAuth: true, drawer: 'access' }
      },
      {
        path: 'access/doors',
        name: 'Access Doors',
        component: () => import('../pages/access/doors.vue'),
        meta: { requiresAuth: true, drawer: 'access' }
      },
      {
        path: 'access/door',
        name: 'Access Door',
        component: () => import('../pages/access/door.vue'),
        meta: { requiresAuth: true, drawer: 'access', resource: 'door' }
      },
      {
        path: 'access/events',
        name: 'Access Events',
        component: () => import('../pages/access/events.vue'),
        meta: { requiresAuth: true, drawer: 'access' }
      },
      {
        path: 'access/globalActions',
        name: 'Global Actions',
        component: () => import('../pages/access/globalActions.vue'),
        meta: { requiresAuth: true, drawer: 'access' }
      },
      {
        path: 'access/home',
        name: 'Access Home',
        component: () => import('../pages/access/home.vue'),
        meta: { requiresAuth: true, drawer: 'access', startAtMenu: true }
      },
      {
        path: 'access/inactive',
        name: 'Access Inactive',
        component: () => import('../pages/access/inactive.vue'),
        meta: { requiresAuth: true, drawer: 'access' }
      },
      {
        path: 'access/lockdownConfig',
        name: 'Lockdown Config',
        component: () => import('../pages/access/lockdownConfig.vue'),
        meta: { requiresAuth: true, drawer: 'access' }
      },
      {
        path: 'access/overview',
        name: 'Access Overview',
        component: () => import('../pages/access/home.vue'),
        meta: { requiresAuth: true, drawer: 'access' }
      },
      {
        path: 'access/panels',
        name: 'Access Panels',
        component: () => import('../pages/access/panels.vue'),
        meta: { requiresAuth: true, drawer: 'access' }
      },
      {
        path: 'access/panel',
        name: 'Access Panel',
        component: () => import('../pages/access/panel.vue'),
        meta: { requiresAuth: true, drawer: 'access', resource: 'panel' }
      },
      {
        path: 'access/server',
        name: 'Access Server',
        component: () => import('../pages/access/server.vue'),
        meta: { requiresAuth: true, drawer: 'access', resource: 'server' }
      },
      {
        path: 'access/servers',
        name: 'Access Servers',
        component: () => import('../pages/access/servers.vue'),
        meta: { requiresAuth: true, drawer: 'access' }
      },
      {
        path: 'adhoc',
        name: 'Ad Hoc',
        component: () => import('../pages/adhoc/adhoc.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'alarms/:kind?/:type?',
        name: 'General Alarms',
        component: () => import('../pages/alarms.vue'),
        meta: { requiresAuth: true } // drawer handled by Kind parameter. see mainLayout displayDrawer
      },
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: () => import('../pages/user/dashboard.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'energy/events',
        name: 'Energy Events',
        component: () => import('../pages/energy/events.vue'),
        meta: { requiresAuth: true, drawer: 'energy' }
      },
      {
        path: 'energy/home',
        name: 'Energy Home',
        component: () => import('../pages/energy/home.vue'),
        meta: { requiresAuth: true, drawer: 'energy', startAtMenu: true }
      },
      {
        path: 'energy/overview',
        name: 'Energy Overview',
        component: () => import('../pages/energy/home.vue'),
        meta: { requiresAuth: true, drawer: 'energy' }
      },
      {
        path: 'energy/budget',
        name: 'Energy Budget',
        component: () => import('../pages/energy/budget.vue'),
        meta: { requiresAuth: true, drawer: 'energy', resource: 'budget' }
      },
      {
        path: 'energy/usage',
        name: 'Energy Usage',
        component: () => import('../pages/energy/usage.vue'),
        meta: { requiresAuth: true, drawer: 'energy' }
      },
      {
        path: 'energy/messages',
        name: 'Energy Messages',
        component: () => import('../pages/energy/messages.vue'),
        meta: { requiresAuth: true, drawer: 'energy' }
      },
      {
        path: 'healthCheck',
        name: 'Frontend Health Check',
        component: () => import('../pages/healthCheck.vue'),
        meta: { requiresAuth: true, resource: 'healthcheck' }
      },
      {
        path: 'howToVideos',
        name: 'howToVideos',
        component: () => import('../pages/howToVideos.vue'),
        meta: { requiresAuth: true, startAtMenu: true }
      },
      {
        path: 'hvac/comfort',
        name: 'HVAC Comfort',
        component: () => import('../pages/hvac/comfort.vue'),
        meta: { requiresAuth: true, drawer: 'hvac' }
      },
      {
        path: 'hvac/controller',
        name: 'HVAC Controller',
        component: () => import('../pages/hvac/controller.vue'),
        meta: { requiresAuth: true, drawer: 'hvac', resource: 'hvaccontroller' }
      },
      {
        path: 'hvac/controller/child',
        name: 'HVAC Controller Child',
        component: () => import('../pages/hvac/childController.vue'),
        meta: { requiresAuth: true, drawer: 'hvac', resource: 'hvaccontroller' }
      },
      {
        path: 'hvac/controllers',
        name: 'HVAC Controllers',
        component: () => import('../pages/hvac/controllers.vue'),
        meta: { requiresAuth: true, drawer: 'hvac' }
      },
      {
        path: 'hvac/events',
        name: 'HVAC Events',
        component: () => import('../pages/hvac/events.vue'),
        meta: { requiresAuth: true, drawer: 'hvac' }
      },
      {
        path: 'hvac/home',
        name: 'HVAC Home',
        component: () => import('../pages/hvac/overview.vue'),
        meta: { requiresAuth: true, drawer: 'hvac', startAtMenu: true }
      },
      {
        path: 'hvac/hvaccontroller',
        name: 'HVAC HVACController',
        component: () => import('../pages/hvac/controller.vue'),
        meta: { requiresAuth: true, drawer: 'hvac', resource: 'hvaccontroller' }
      },
      {
        path: 'hvac/inactive',
        name: 'HVAC Inactive',
        component: () => import('../pages/hvac/inactive.vue'),
        meta: { requiresAuth: true, drawer: 'hvac' }
      },
      {
        path: 'hvac/overview',
        name: 'HVAC Overview',
        component: () => import('../pages/hvac/overview.vue'),
        meta: { requiresAuth: true, drawer: 'hvac' }
      },
      {
        path: 'hvac/point',
        name: 'Point',
        component: () => import('../pages/hvac/point.vue'),
        meta: { requiresAuth: true, drawer: 'hvac', resource: 'point' }
      },
      {
        path: 'hvac/points',
        name: 'Points',
        component: () => import('../pages/hvac/points.vue'),
        meta: { requiresAuth: true, drawer: 'hvac' }
      },
      {
        path: 'hvac/server',
        name: 'HVAC Server',
        component: () => import('../pages/hvac/server.vue'),
        meta: { requiresAuth: true, drawer: 'hvac', resource: 'server' }
      },
      {
        path: 'hvac/servers',
        name: 'HVAC Servers',
        component: () => import('../pages/hvac/servers.vue'),
        meta: { requiresAuth: true, drawer: 'hvac' }
      },
      {
        path: 'lighting/devices',
        name: 'Lighting Devices',
        component: () => import('../pages/lighting/devices.vue'),
        meta: { requiresAuth: true, drawer: 'lighting' }
      },
      {
        path: 'lighting/home',
        name: 'Lighting Home',
        component: () => import('../pages/lighting/home.vue'),
        meta: { requiresAuth: true, drawer: 'lighting', startAtMenu: true }
      },
      {
        path: 'lighting/inactive',
        name: 'Lighting Inactive',
        component: () => import('../pages/lighting/inactive.vue'),
        meta: { requiresAuth: true, drawer: 'lighting' }
      },
      {
        path: 'lighting/overview',
        name: 'Lighting Overview',
        component: () => import('../pages/lighting/home.vue'),
        meta: { requiresAuth: true, drawer: 'lighting' }
      },
      {
        path: 'lighting/servers',
        name: 'Lighting Servers',
        component: () => import('../pages/lighting/servers.vue'),
        meta: { requiresAuth: true, drawer: 'lighting' }
      },
      {
        path: 'massnotification/events',
        name: 'Mass Notification Events',
        component: () => import('../pages/massnotification/events.vue'),
        meta: { requiresAuth: true, drawer: 'massnotification' }
      },
      {
        path: 'massnotification/dialcasts',
        name: 'Mass Notification Dialcasts',
        component: () => import('../pages/massnotification/dialcasts.vue'),
        meta: { requiresAuth: true, drawer: 'massnotification' }
      },
      {
        path: 'massnotification/icfusionEndpoints',
        name: 'Mass Notification IC Fusion Endpoints',
        component: () => import('../pages/massnotification/icfusionendpoints.vue'),
        meta: { requiresAuth: true, drawer: 'massnotification' }
      },
      {
        path: 'massnotification/home',
        name: 'Mass Notification Home',
        component: () => import('../pages/massnotification/home.vue'),
        meta: { requiresAuth: true, drawer: 'massnotification', startAtMenu: true }
      },
      {
        path: 'massnotification/inactive',
        name: 'Mass Notification Inactive',
        component: () => import('../pages/massnotification/inactive.vue'),
        meta: { requiresAuth: true, drawer: 'massnotification' }
      },
      {
        path: 'massnotification/overview',
        name: 'Mass Notification Overview',
        component: () => import('../pages/massnotification/home.vue'),
        meta: { requiresAuth: true, drawer: 'massnotification' }
      },
      {
        path: 'massnotification/logs',
        name: 'Mass Notification Logs',
        component: () => import('../pages/massnotification/logs.vue'),
        meta: { requiresAuth: true, drawer: 'massnotification' }
      },
      {
        path: 'massnotification/m2ms',
        name: 'Mass Notification M2Ms',
        component: () => import('../pages/massnotification/m2ms.vue'),
        meta: { requiresAuth: true, drawer: 'massnotification' }
      },
      {
        path: 'massnotification/messages',
        name: 'Mass Notification Messages',
        component: () => import('../pages/massnotification/messages.vue'),
        meta: { requiresAuth: true, drawer: 'massnotification' }
      },
      {
        path: 'massnotification/outboundEmails',
        name: 'Mass Notification Outbound Emails',
        component: () => import('../pages/massnotification/outboundEmails.vue'),
        meta: { requiresAuth: true, drawer: 'massnotification' }
      },
      {
        path: 'massnotification/recipientGroups',
        name: 'Mass Notification Recipient Groups',
        component: () => import('../pages/massnotification/recipientGroups.vue'),
        meta: { requiresAuth: true, drawer: 'massnotification' }
      },
      {
        path: 'massnotification/server',
        name: 'Mass Notification Server',
        component: () => import('../pages/massnotification/server.vue'),
        meta: { requiresAuth: true, drawer: 'massnotification', resource: 'server' }
      },
      {
        path: 'massnotification/servers',
        name: 'Mass Notification Servers',
        component: () => import('../pages/massnotification/servers.vue'),
        meta: { requiresAuth: true, drawer: 'massnotification' }
      },
      {
        path: 'maintenance/:kind?/:type?',
        name: 'General Maintenance Alarms',
        component: () => import('../pages/alarms.vue'),
        meta: { requiresAuth: true, maintenance: true } // drawer handled by Kind parameter. see mainLayout displayDrawer
      },
      {
        path: 'releaseNotes',
        name: 'releaseNotes',
        component: () => import('../pages/releaseNotes.vue'),
        meta: { requiresAuth: true, startAtMenu: true }
      },
      {
        path: 'surveillance/home',
        name: 'Surveillance Home',
        component: () => import('../pages/surveillance/home.vue'),
        meta: { requiresAuth: true, drawer: 'surveillance' }
      },
      {
        path: 'surveillance/overview',
        name: 'Surveillance Overview',
        component: () => import('../pages/surveillance/home.vue'),
        meta: { requiresAuth: true, drawer: 'surveillance' }
      },
      {
        path: 'surveillance/camera',
        name: 'Surveillance Camera',
        component: () => import('../pages/surveillance/camera.vue'),
        meta: { requiresAuth: true, drawer: 'surveillance', resource: 'camera' }
      },
      {
        path: 'surveillance/cameras',
        name: 'Surveillance Cameras',
        component: () => import('../pages/surveillance/cameras.vue'),
        meta: { requiresAuth: true, drawer: 'surveillance' }
      },
      {
        path: 'surveillance/encoder',
        name: 'Surveillance Encoder',
        component: () => import('../pages/surveillance/encoder.vue'),
        meta: { requiresAuth: true, drawer: 'surveillance', resource: 'encoder' }
      },
      {
        path: 'surveillance/encoders',
        name: 'Surveillance Encoders',
        component: () => import('../pages/surveillance/encoders.vue'),
        meta: { requiresAuth: true, drawer: 'surveillance' }
      },
      {
        path: 'surveillance/events',
        name: 'Surveillance Events',
        component: () => import('../pages/surveillance/events.vue'),
        meta: { requiresAuth: true, drawer: 'surveillance' }
      },
      {
        path: 'surveillance/inactive',
        name: 'Surveillance Inactive',
        component: () => import('../pages/surveillance/inactive.vue'),
        meta: { requiresAuth: true, drawer: 'surveillance' }
      },
      {
        path: 'surveillance/server',
        name: 'Surveillance Server',
        component: () => import('../pages/surveillance/server.vue'),
        meta: { requiresAuth: true, drawer: 'surveillance', resource: 'server' }
      },
      {
        path: 'surveillance/servers',
        name: 'Surveillance Servers',
        component: () => import('../pages/surveillance/servers.vue'),
        meta: { requiresAuth: true, drawer: 'surveillance' }
      },
      {
        path: 'user/account',
        name: 'Account',
        component: () => import('../pages/user/account.vue'),
        meta: { requiresAuth: true, resource: 'user' }
      },
      {
        path: 'user/dashboards',
        name: 'User Dashboards',
        component: () => import('../pages/user/dashboard.vue'),
        meta: { requiresAuth: true, resource: 'user' }
      }
    ]
  }
]

// Always leave this as last one
routes.push(
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/error/404.vue')
  },
  { path: '/:pathMatch(.*)*', name: 'not-found', component: () => import('pages/error/404.vue') },
  // if you omit the last `*`, the `/` character in params will be encoded when resolving or pushing
  { path: '/:pathMatch(.*)', name: 'bad-not-found', component: () => import('pages/error/404.vue') }
)

export default routes
