import axios from 'axios'

export default {
  // ======================================================================
  // ==========================List Management=============================
  // ======================================================================
  branchList (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/branch', { params: opts })
        .then(({ data }) => {
          context.commit('BRANCH_LIST', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  branchOrganizations (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.branch ? opts.branch : '#'
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/branch/organizations/${id}`)
        .then(({ data }) => {
          context.commit('BRANCH_ORGANIZATION_LIST', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  branchUsers (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.branch ? opts.branch : '#'
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/branch/users/${id}`)
        .then(({ data }) => {
          context.commit('BRANCH_USER_LIST', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  // ==========================Socket Updates==============================
  branchCreated (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    const inputs = { }
    if (opts && Array.isArray(opts.populate) && opts.populate.length > 0) {
      inputs.params = { }
      inputs.params.populate = opts.populate
    }
    axios
      .get(`/api/branch/${id}`, inputs)
      .then(({ data }) => {
        data.socketUpdate = true
        context.commit('BRANCH_LIST_ADD', data)
      }).catch(() => {
      })
  },
  branchUpdated (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    const inputs = { }
    if (opts && Array.isArray(opts.populate) && opts.populate.length > 0) {
      inputs.params = { }
      inputs.params.populate = opts.populate
    }
    const inList = context.state.list.find((i) => i.id === id)
    if (context.state.current.id === id || inList) {
      axios
        .get(`/api/branch/${id}`, inputs)
        .then(({ data }) => {
          if (data.id === context.state.current.id) {
            context.commit('BRANCH_CURRENT', data)
          }
          if (inList) {
            context.commit('BRANCH_LIST_UPDATE', data)
          }
        }).catch(() => {
        })
    }
  },
  branchDestroyed (context, opts) {
    // always try to remove from list
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    context.commit('BRANCH_LIST_REMOVE', id)
    if (id === context.state.current.id) {
      context.commit('BRANCH_CURRENT', null)
    }
  },
  // ===========================List Edits===============================
  branchAdd (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/branch', opts)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  branchDelete (context, opts) {
    if (opts.deleteFlag) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/branch/${opts.id}`, { data: { deleteFlag: opts.deleteFlag } })
          .then(({ data }) => {
            resolve(data)
          }).catch((error) => reject(error))
      })
    } else {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/branch/${opts}`)
          .then(({ data }) => {
            resolve(data)
          }).catch((error) => reject(error))
      })
    }
  },
  // ======================================================================
  // ==========================Item Management=============================
  // ======================================================================
  branchDetail (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    const inputs = { }
    if (opts && Array.isArray(opts.populate) && opts.populate.length > 0) {
      inputs.params = { }
      inputs.params.populate = opts.populate
    }
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/branch/${id}`, inputs)
        .then(({ data }) => {
          context.commit('BRANCH_CURRENT', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  branchUpdate (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/api/branch/${context.state.current.id}`, context.state.update)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  }
}
