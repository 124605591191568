import axios from 'axios'

// function setChildLists (context, data, resourceChanged) {
//   const children = ['schedules', 'rooms']
//   for (const prop of children) {
//     const list = data[prop] || []
//     const mutation = prop.substring(0, (prop.length - 1)).toUpperCase()
//     try {
//       if (resourceChanged) {
//         context.commit(`${mutation}_LIST`, list)
//       } else {
//         for (const item of list) {
//           context.commit(`${mutation}_LIST_UPDATE`, item)
//         }
//       }
//     } catch (e) {
//     }
//   }
// }

export default {
  // ======================================================================
  // ==========================List Management=============================
  // ======================================================================

  areaList (context, opts) {
    let noVuex = false
    if (opts && opts.noVuex) {
      noVuex = true
      delete opts.noVuex
    }
    return new Promise((resolve, reject) => {
      axios
        .get('/api/area', { params: opts })
        .then(({ data }) => {
          if (!noVuex) {
            context.commit('AREA_LIST', data)
          }
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  // ==========================Socket Updates==============================
  areaCreated (context, opts) {
    // if we're an admin, go fetch the new data
    if (context.rootState.User.permissions.area && context.rootState.User.permissions.area.read) {
      const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
      const inputs = { }
      if (opts && Array.isArray(opts.populate) && opts.populate.length > 0) {
        inputs.params = { }
        inputs.params.populate = opts.populate
      }
      axios
        .get(`/api/area/${id}`, inputs)
        .then(({ data }) => {
          if (data.id && data.building && data.building === context.rootState.Building.current.id) {
            context.commit('AREA_LIST_ADD', data)
          }
        }).catch(() => {
        })
    }
  },
  areaUpdated (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    const inputs = { }
    if (opts && Array.isArray(opts.populate) && opts.populate.length > 0) {
      inputs.params = { }
      inputs.params.populate = opts.populate
    }
    if (context.state.current.id === id || context.state.list.find((i) => i.id === id) !== undefined) {
      axios
        .get(`/api/area/${id}`, inputs)
        .then(({ data }) => {
          if (data.id) {
            if (context.state.list.find((i) => i.id === id) !== undefined) {
              context.commit('AREA_LIST_UPDATE', data)
            }
            if (data.id === context.state.current.id) {
              context.commit('AREA_CURRENT', data)
            }
          }
        }).catch(() => {
        })
    }
  },
  areaDestroyed (context, opts) {
    // always try to remove from list
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    if (id === context.state.current.id) {
      context.commit('AREA_CURRENT', null)
    }
    context.commit('AREA_LIST_ITEM_REMOVE', opts)
  },
  // ===========================List Edits===============================
  areaAdd (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/area', opts)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  areaDelete (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/api/area/${opts}`)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  // ======================================================================
  // ==========================Item Management=============================
  // ======================================================================
  areaDetail (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    // const changed = (context.state.current.id !== id)
    const inputs = { }
    if (opts && Array.isArray(opts.populate) && opts.populate.length > 0) {
      inputs.params = { }
      inputs.params.populate = opts.populate
    }
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/area/${id}`, inputs)
        .then(({ data }) => {
          context.commit('AREA_CURRENT', data)
          // if (opts && !opts.overrideFetch) {
          //   setChildLists(context, data, changed)
          // }
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  areaUpdate (context, opts) {
    const updates = Object.assign({}, context.state.update)
    for (const key of Object.keys(updates)) {
      if (updates[key] == null) {
        delete updates[key]
      }
    }
    return new Promise((resolve, reject) => {
      axios
        .patch(`/api/area/${context.state.current.id}`, updates)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  }
}
