export default function () {
  return {
    current: {
      id: '',
      active: false,
      alias: '',
      building: '',
      dn: '',
      installed: false,
      name: '',
      notes: false,
      panel: '',
      position: '',
      reenableTime: '',
      healthChecks: [],
      history: {},
      status: '',
      system: ''
    },
    update: {
      id: '',
      active: false,
      alias: '',
      building: null,
      reenableTime: null
    },
    doorSocketUpdate: null,
    list: [],
    fetchingList: false,
    canInactivateForever: true
  }
}
