export default {
  NOTE_LIST (state, value) {
    state.list = (value && Array.isArray(value)) ? value : []
  },
  NOTE_LIST_ADD (state, value) {
    if (Object.keys(value).length > 0) {
      state.list.unshift(value)
    }
  },
  NOTE_LIST_UPDATE (state, value) {
    const index = state.list.findIndex((i) => i.id === value.id)
    if (index !== -1) {
      state.list.splice(index, 1, Object.assign(state.list[index], value))
    } else {
      state.list.push(value) // reason: see fetchModel.js
    }
  },
  NOTE_LIST_REMOVE (state, value) {
    const index = state.list.findIndex((i) => i.id === value)
    if (index !== -1) {
      state.list.splice(index, 1)
    }
  }
}
