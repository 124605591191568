export default function () {
  return {
    current: {
      id: '',
      active: false,
      building: '',
      dialcode: '',
      exclusionary: false,
      name: '',
      numberOfPhones: 0,
      numberOfPlugins: 0,
      numberOfSpeakers: 0,
      reenableTime: null,
      system: '',
      syncId: '',
      tag: '',
      vendorId: ''
    },
    update: {
      active: false,
      building: '',
      reenableTime: null
    },
    fetchingList: false,
    list: []
  }
}
