import axios from 'axios'

export default {
  // ======================================================================
  // ==========================Authentication==============================
  // ======================================================================
  userGet (context) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/auth/check-user')
        .then(({ data }) => {
          if (data.user) {
            context.commit('USER_SET', data)
            resolve(true)
          } else {
            context.commit('USER_RESET')
            resolve(false)
          }
        }).catch((error) => {
          reject(error.response.data)
        })
    })
  },
  userEmailExists (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/auth/check-email', opts)
        .then(({ data }) => {
          resolve(data)
        }).catch((e) => {
          reject(false)
        })
    })
  },
  userOauth (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/auth/oauth', opts)
        .then(({ data }) => {
          resolve(data)
        }).catch((e) => {
          reject(false)
        })
    })
  },
  userOauthLogin (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/auth/oauth-login', opts)
        .then(({ data }) => {
          context.commit('USER_SET', data)
          resolve(true)
        }).catch((e) => {
          reject(false)
        })
    })
  },
  userOauthLogout (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/auth/oauth-logout', opts)
        .then(({ data }) => {
          resolve(data)
        }).catch((e) => {
          reject(false)
        })
    })
  },
  userSignIn (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/auth/login', opts)
        .then(({ data }) => {
          context.commit('USER_SET', data)
          resolve(data)
        }).catch((error) => {
          reject(error.response.data)
        })
    })
  },
  userSignOut (context, opts) {
    context.commit('USER_RESET')
    return new Promise((resolve, reject) => {
      axios
        .post('/api/auth/logout', opts)
        .then((res) => {
          resolve()
        }).catch((error) => {
          reject(error.response.data)
        })
    })
  },
  // userChangeEmail (context, opts) {
  //   opts.mode = 'change'
  //   return new Promise((resolve, reject) => {
  //     axios
  //       .post('/api/user/token', opts)
  //       .then(({ data }) => {
  //         context.commit('USER_SET', data)
  //         resolve(data)
  //       }).catch((error) => {
  //         reject(error.response.data)
  //       })
  //   })
  // },
  userConfirmEmail (context, opts) {
    opts.mode = 'confirm'
    return new Promise((resolve, reject) => {
      axios
        .post('/api/user/token', opts)
        .then(({ data }) => {
          context.commit('USER_SET', data)
          resolve(data)
        }).catch((error) => {
          reject(error.response.data)
        })
    })
  },
  userResetPassword (context, opts) {
    opts.mode = 'reset'
    return new Promise((resolve, reject) => {
      axios
        .post('/api/user/token', opts)
        .then(({ data }) => {
          context.commit('USER_SET', data)
          resolve(data)
        }).catch((error) => {
          reject(error.response.data)
        })
    })
  },
  userForgotPassword (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/auth/send-reset-email', opts)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => {
          reject(error.response.data)
        })
    })
  },
  // ======================================================================
  // ==========================List Management=============================
  // ======================================================================
  userList (context, opts) {
    const inputs = { }
    if (opts && Array.isArray(opts.populate) && opts.populate.length > 0) {
      inputs.params = { }
      inputs.params.populate = opts.populate
    }
    return new Promise((resolve, reject) => {
      axios
        .get('/api/user', inputs)
        .then(({ data }) => {
          context.commit('USER_LIST', data)
          resolve(data)
        }).catch((error) => {
          reject(error.response.data)
        })
    })
  },
  // ==========================Socket Updates==============================
  userCreated (context, opts) {
    // if the list isn't empty and can read user and belong to same org
    if (context.state.list.length > 0 && context.state.permissions.user?.read && context.state.orgIds.find((id) => opts.orgIds.includes(id)) !== undefined) {
      const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
      const inputs = { }
      if (opts && Array.isArray(opts.populate) && opts.populate.length > 0) {
        inputs.params = { }
        inputs.params.populate = opts.populate
      }
      axios
        .get(`/api/user/${id}`, inputs)
        .then(({ data }) => {
          if (data.id) {
            context.commit('USER_LIST_ADD', data)
          }
        }).catch(() => {
        })
    }
  },
  userUpdated (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    if (id === context.state.loggedIn.id) {
      context.dispatch('userGet')
    }
    const inputs = { }
    if (opts && Array.isArray(opts.populate) && opts.populate.length > 0) {
      inputs.params = { }
      inputs.params.populate = opts.populate
    }
    const inList = context.state.list.find((i) => i.id === id)
    if (context.state.current.id === id || inList) {
      axios
        .get(`/api/user/${id}`, inputs)
        .then(({ data }) => {
          if (data.id) {
            if (data.id === context.state.current.id) {
              data.socketUpdate = true
              context.commit('USER_CURRENT', data)
            }
            if (inList) {
              context.commit('USER_LIST_UPDATE', data)
            }
          }
        }).catch(() => {
        })
    }
  },
  userDestroyed (context, opts) {
    // always try to remove from list
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    if (id === context.state.current.id) {
      context.commit('USER_CURRENT', null)
    }
    context.commit('USER_LIST_ITEM_REMOVE', opts)
  },
  // ===========================List Edits===============================
  userAdd (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/user', opts)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => {
          reject(error.response)
        })
    })
  },
  userUpdate (context, opts) {
    return new Promise((resolve, reject) => {
      const updates = Object.assign({}, context.state.update)
      const current = Object.assign({}, context.state.current)
      for (const key of Object.keys(updates)) {
        if ((updates[key] === null && key !== 'branch') || current[key] === updates[key]) {
          delete updates[key]
        }
      }
      axios
        .patch(`/api/user/${context.state.current.id}`, updates)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  userDelete (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/api/user/${opts}`)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => {
          if (error.response && error.response.data && error.response.data.message) {
            reject(error.response.data)
          } else {
            reject(error)
          }
        })
    })
  },
  userDetail (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    const inputs = { }
    if (opts && Array.isArray(opts.populate) && opts.populate.length > 0) {
      inputs.params = { }
      inputs.params.populate = opts.populate
    }
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/user/${id}`, inputs)
        .then(({ data }) => {
          context.commit('USER_CURRENT', data)
          resolve(data)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  userUpdateAccount (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .patch('/api/user/edit-account', opts)
        .then(({ data }) => {
          if (data) {
            context.commit('USER_ACCOUNT_UPDATE', data)
          } else { throw new Error() }
          resolve(data)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  userCreate (context, opts) {
    return new Promise((resolve, reject) => {
      const create = Object.assign({}, context.state.create)
      for (const key of Object.keys(create)) {
        if (create[key] == null) {
          delete create[key]
        }
      }
      axios
        .post('/api/user', create)
        .then(({ data }) => {
          context.commit('USER_CREATE_ID', data.id)
          resolve(data)
        }).catch((error) => {
          if (error.response && error.response.data && error.response.data.message) {
            reject(error.response.data)
          } else {
            reject(error)
          }
        })
    })
  },
  userCreateDetail (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/user/${id}`)
        .then(({ data }) => {
          context.commit('USER_CREATE', data)
          resolve(data)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  userCreateStatusEmails (context, opts) {
    return new Promise((resolve, reject) => {
      const alarmsByBuilding = context.state.create.alarmsByBuilding ?? false
      const statusEmailVersion = context.state.create.statusEmailVersion
      const obj = { alarmsByBuilding, statusEmailVersion }
      for (const key of ['firstStatusEmail', 'secondStatusEmail', 'thirdStatusEmail']) {
        obj[key] = context.state.create[key] !== null ? context.state.create[key] : ''
      }
      axios
        .patch(`/api/user/${context.state.create.id}`, obj)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  userCreateGetClone (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/user/${id}`)
        .then(({ data }) => {
          data.clone = true
          context.commit('USER_CREATE_ID', null)
          context.commit('USER_CREATE', data)
          context.commit('USER_CURRENT', data)
          resolve(data)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  userCreateFromClone (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    const options = { user: id }
    for (const [key, value] of Object.entries(context.state.create)) {
      if (value) {
        options[key] = value
      }
    }
    return new Promise((resolve, reject) => {
      axios
        .post('/api/user/create-clone', options)
        .then(({ data }) => {
          context.commit('USER_CREATE', data)
          context.commit('USER_CURRENT', data)
          resolve(data)
        }).catch((error) => {
          if (error.response && error.response.data && error.response.data.message) {
            reject(error.response.data)
          } else {
            reject(error)
          }
        })
    })
  },
  userCloneFromUserorg (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/user/create-clone', opts)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => {
          if (error.response && error.response.data && error.response.data.message) {
            reject(error.response.data)
          } else {
            reject(error)
          }
        })
    })
  }
}
