import axios from 'axios'

export default {
  // ======================================================================
  // ==========================List Management=============================
  // ======================================================================
  groupList (context, opts) {
    const p = {}
    if (opts && typeof opts.assignableGroups !== 'undefined' && opts.assignableGroups !== null) {
      p.params = opts
    }
    return new Promise((resolve, reject) => {
      axios
        .get('/api/group', p)
        .then(({ data }) => {
          context.commit('GROUP_LIST', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  // ==========================Socket Updates==============================
  groupCreated (context, opts) {
    // if we're an admin, go fetch the new data
    if ((context.rootState.User.permissions.group && context.rootState.User.permissions.group.read)) {
      const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
      axios
        .get(`/api/group/${id}`)
        .then(({ data }) => {
          if (data.id) {
            context.commit('GROUP_LIST_ADD', data)
          }
        }).catch(() => {
        })
    }
  },
  groupUpdated (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    const inputs = { }
    if (opts && Array.isArray(opts.populate) && opts.populate.length > 0) {
      inputs.params = { }
      inputs.params.populate = opts.populate
    }
    const inList = context.state.list.find((i) => i.id === id)
    if (context.state.current.id === id || inList) {
      axios
        .get(`/api/group/${id}`, inputs)
        .then(({ data }) => {
          if (data.id) {
            if (data.id === context.state.current.id) {
              context.commit('GROUP_CURRENT', data)
            }
            if (inList) {
              context.commit('GROUP_LIST_UPDATE', data)
            }
          }
        }).catch(() => {
        })
    }
  },
  groupDestroyed (context, opts) {
    // always try to remove from list
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    if (id === context.state.current.id) {
      context.commit('GROUP_CURRENT', null)
    }
    context.commit('GROUP_LIST_ITEM_REMOVE', opts)
  },
  // ===========================List Edits===============================
  groupAdd (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/group', opts)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  groupDelete (context, opts) {
    if (opts.deleteFlag) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/group/${opts.id}`, { data: { deleteFlag: opts.deleteFlag } })
          .then(({ data }) => {
            resolve(data)
          }).catch((error) => reject(error))
      })
    } else {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/group/${opts}`)
          .then(({ data }) => {
            resolve(data)
          }).catch((error) => reject(error))
      })
    }
  },
  // ======================================================================
  // ==========================Item Management=============================
  // ======================================================================
  groupDetail (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/group/${opts}`)
        .then(({ data }) => {
          context.commit('GROUP_CURRENT', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  groupUpdate (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/api/group/${context.state.current.id}`, context.state.update)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  groupAddPermissions (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/grouppermission', opts)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  groupRemovePermissions (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .delete('/api/grouppermission', { data: opts })
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  }
}
