export default {
  ONCALLSCHEDULE_LIST (state, value) {
    if (value) {
      state.list = value.results
      state.years = value.years
      state.selectedYear = value.selectedYear
    } else {
      state.list = []
      state.years = []
      state.selectedYear = null
    }
  },
  ONCALLSCHEDULE_FUTURE_LIST (state, value) {
    if (value) {
      state.nextYearList = value.results
    } else {
      state.nextYearList = []
    }
  },
  ONCALLSCHEDULE_CURRENT (state, value) {
    if (value) {
      for (const prop of ['id', 'user', 'weekNumber', 'startDate', 'endDate', 'year', 'kind']) {
        if (value[prop] !== state.current[prop]) {
          state.current[prop] = value[prop]
          state.update[prop] = value[prop]
        }
      }
      for (const prop of ['branch', 'branchUsers', 'kindUsers', 'serviceDivision', 'allPossibleUsers', 'serviceDivisionUsers']) {
        if (value[prop] !== state.current[prop]) {
          state.current[prop] = value[prop]
        }
      }
    } else {
      state.current = {
        id: '',
        weekNumber: '',
        startDate: '',
        endDate: '',
        year: '',
        user: {
          id: '',
          firstName: '',
          lastName: '',
          email: '',
          mobilePhone: ''
        },
        serviceDivisionUsers: [],
        allPossibleUsers: [],
        serviceDivision: { name: '' }
      }
    }
  },
  ONCALLSCHEDULE_CURRENT_USER (state, value) {
    state.current.user = value
  },
  ONCALLSCHEDULE_SELECTED_YEAR (state, value) {
    state.selectedYear = value
  },
  ONCALLSCHEDULE_UPDATE_USER (state, value) {
    state.update.user = value
  }
}
