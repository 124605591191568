export default function () {
  return {
    current: {
      id: '',
      name: '',
      building: '',
      schedules: [],
      rooms: []
    },
    update: {
      id: '',
      name: ''
    },
    list: []
  }
}
