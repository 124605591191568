export default function () {
  return {
    current: {
      id: '',
      active: false,
      building: '',
      dn: '',
      libraryType: 0,
      name: '',
      priority: 0,
      qualifier: 0,
      reenableTime: null,
      system: '',
      toggle: false,
      type: 0,
      typeName: '',
      vendorId: ''
    },
    update: {
      active: false,
      building: '',
      reenableTime: null
    },
    fetchingList: false,
    list: []
  }
}
