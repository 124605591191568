export default {
  SET_FLASH_MESSAGES (state, messages) {
    state.messages = messages
  },
  SET_FLASH_TIMEOUT (state, timeout) {
    state.timeout = timeout
  },
  SET_FLASH_KIND (state, kind) {
    state.kind = kind
  },
  SET_FLASH_PAGE_SPECIFIC (state, value) {
    state.pageSpecific = value
  },
  SET_FLASH_VISIBLE (state, visible) {
    state.visible = visible
  }
}
