export default function () {
  return {
    current: {
      id: '',
      name: '',
      organization: null,
      buildings: [],
      rooms: []
    },
    update: {
      id: '',
      name: ''
    },
    list: []
  }
}
