export default {
  USERSERVICEDIVISION_LIST (state, value) {
    if (value && Array.isArray(value)) {
      state.current.list = value.map(a => Object.assign({}, a))
      state.update.list = value.map(a => Object.assign({}, a))
    } else {
      state.current.list = []
      state.update.list = []
    }
  },
  USERSERVICEDIVISION_UPDATE_LIST (state, value) {
    if (value && Array.isArray(value)) {
      state.update.list = value.map(a => Object.assign({}, a))
    } else {
      state.update.list = []
    }
  },
  USERSERVICEDIVISION_LIST_ITEM_REMOVE (state, value) {
    const id = typeof value === 'string' ? value : typeof value === 'object' && value.id ? value.id : ''
    const index = state.current.list.findIndex((i) => i.id === id)
    if (index !== -1) {
      const obj = Object.assign(state.current.list[index], { enabled: false })
      state.current.list.splice(index, 1, obj)
      state.update.list.splice(index, 1, obj)
    }
  },
  USERSERVICEDIVISION_LIST_UPDATED (state, value) {
    const index = state.list.findIndex((i) => i.id === value.id)
    if (index !== -1) {
      state.current.list.splice(index, 1, value)
    } else {
      state.current.list.push(value) // see fetchModel.js
    }
  },
  USERSERVICEDIVISION_RESET (state) {
    state.update.list = []
    state.current.list = []
  },
  USERSERVICEDIVISION_UPDATE (state, obj) {
    for (const updateDivision of state.update.list) {
      if (updateDivision.id === obj.value.id) {
        updateDivision.enabled = obj.enabled
      }
    }
  }
}
