export default function () {
  return {
    current: {
      vendorId: '',
      active: false,
      building: '',
      deletedAt: null,
      encoder: null,
      firmwareVersion: '',
      healthChecks: [],
      history: {},
      id: '',
      ip: '',
      location: '',
      logicalId: '',
      mac: '',
      manufacturer: '',
      model: '',
      name: '',
      notes: false,
      operatingPriority: 0,
      reenableTime: null,
      serialNumber: '',
      server: '',
      siteServer: '',
      status: '',
      system: null
    },
    update: {
      id: '',
      building: null,
      active: false,
      reenableTime: null
    },
    kinds: [
      'IP',
      'Analog'
    ],
    bulkEdit: [],
    list: [],
    canInactivateForever: true
  }
}
