export default function () {
  return {
    current: {
      // cameras: [],
      active: false,
      alarms: {},
      allowUpgrade: false,
      branch: null,
      budgets: [],
      buildings: [],
      checkFinished: null,
      checkInterval: 600,
      checkStarted: null,
      childBudgets: [],
      clientCommFailure: false,
      clientHostInfo: '',
      clientLogsPath: '',
      clientVersion: '',
      dayContact: '',
      energyUsage: {},
      externalIp: '',
      forecast: [],
      hasClient: false,
      hasRpiClient: false,
      healthCheckKinds: [],
      healthChecks: [],
      hvacControllers: [],
      hvacSubControllers: [],
      id: '',
      internalIp: '',
      keyConnected: false,
      lastestClientVersion: '',
      lastLog: '',
      lastLogList: {},
      name: '',
      reenableTime: null,
      rpiMacAddress: '',
      servers: [],
      status: '',
      systems: [],
      tags: [],
      users: [],
      versionHistory: [],
      zip: ''
    },
    update: {
      active: false,
      allowUpgrade: false,
      branch: null,
      checkInterval: 600,
      dayContact: '',
      hasClient: false,
      hasRpiClient: false,
      id: '',
      name: '',
      reenableTime: null,
      rpiMacAddress: '',
      clientLogsPath: '',
      zip: ''
    },
    create: {
      id: null,
      branch: null,
      checkInterval: 600,
      dayContact: '',
      name: null,
      zip: null
    },
    alarms: {},
    budgets: [],
    buildings: [],
    cameras: [],
    canInactivateForever: false,
    clientLogFileNames: [],
    fetchingClientLogFileNames: false,
    downloadableClientLogUrl: null,
    energyUsage: {},
    hvacControllers: [],
    hvacSubControllers: [],
    inactive: 0,
    list: [],
    organizations: 0,
    organizationsOk: 0,
    servers: [],
    systems: 0,
    systemsOk: 0
  }
}
