import axios from 'axios'

export default {
  noteList (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/note', { params: { resource: opts } })
        .then(({ data }) => {
          context.commit('NOTE_LIST', data)
          resolve(data)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  // ===========================Socket Updates=====================
  noteCreated (context, opts) {
    // do nothing for now
  },
  noteUpdated (context, opts) {
    const id = opts && opts.id ? opts.id : opts
    const populated = opts && opts.populate ? opts.populate : []
    const inList = context.state.list.find((i) => i.id === id)
    if (inList) {
      axios
        .get(`/api/note/${id}`, { params: populated })
        .then(({ data }) => {
          if (data.id) {
            data.socketUpdate = true
            context.commit('NOTE_UPDATE', data)
          }
        }).catch(() => {})
    }
  },
  noteDestroyed (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    const inList = context.state.list.find((i) => i.id === id)
    if (id === context.state.current.id) {
      context.commit('NOTE_CURRENT', null)
    }
    if (inList) {
      context.commit('NOTE_LIST_REMOVE', id)
    }
  },
  noteAdd (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/note', opts)
        .then(async ({ data }) => {
          context.commit('NOTE_LIST_ADD', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  noteDelete (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/api/note/${opts}`)
        .then(({ data }) => {
          context.commit('NOTE_LIST_REMOVE', opts)
          resolve()
        }).catch((error) => reject(error))
    })
  },
  noteUpdate (context, opts) {
    if (opts.id && opts.id !== '' && opts.value && opts.value !== '') { // should just destroy if blank
      return new Promise((resolve, reject) => {
        axios
          .patch(`/api/note/${opts.id}`, { value: opts.value })
          .then(({ data }) => {
            context.commit('NOTE_LIST_UPDATE', data)
            resolve(data)
          }).catch((error) => reject(error))
      })
    }
  }
}
