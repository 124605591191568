import axios from 'axios'

export default {
  // ======================================================================
  // ==========================List Management=============================
  // ======================================================================
  // TODO: keep org list current all the time
  healthcheckdefList (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/healthCheckDef')
        .then(({ data }) => {
          context.commit('HEALTHCHECKDEF_LIST', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  // ==========================Socket Updates==============================
  healthcheckdefCreated (context, opts) {
    // if we're an admin, go fetch the new data
    if (context.rootState.User.permissions.healthcheckdef && context.rootState.User.permissions.healthcheckdef.read) {
      const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
      axios
        .get(`/api/healthcheckdef/${id}`)
        .then(({ data }) => {
          if (data.id && !data.organization) {
            context.commit('HEALTHCHECKDEF_LIST_ADD', data)
          }
        }).catch(() => {
        })
    }
  },
  healthcheckdefUpdated (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    const inputs = { }
    if (opts && Array.isArray(opts.populate) && opts.populate.length > 0) {
      inputs.params = { }
      inputs.params.populate = opts.populate
    }
    const inList = context.state.list.find((i) => i.id === id)
    if (context.state.current.id === id || inList) {
      axios
        .get(`/api/healthcheckdef/${id}`, inputs)
        .then(({ data }) => {
          if (context.state.current.id === data.id) {
            data.socketUpdate = true
            context.commit('HEALTHCHECKDEF_CURRENT', data)
          }
          if (inList) {
            context.commit('HEALTHCHECKDEF_LIST_UPDATE', data)
          }
        }).catch(() => {
        })
    }
  },
  healthcheckdefDestroyed (context, opts) {
    // always try to remove from list
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    context.commit('HEALTHCHECKDEF_LIST_REMOVE', id)
    if (id === context.state.current.id) {
      context.commit('HEALTHCHECKDEF_CURRENT', null)
    }
  },
  // ===========================List Edits=====================
  healthcheckdefAdd (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/healthcheckdef', opts)
        .then(({ data }) => {
          context.commit('HEALTHCHECKDEF_CURRENT', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  healthcheckdefDelete (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/api/healthcheckdef/${opts}`)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  // ======================================================================
  // ==========================Item Management=============================
  // ======================================================================
  healthcheckdefDetail (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/healthcheckdef/${opts}`)
        .then(({ data }) => {
          context.commit('HEALTHCHECKDEF_CURRENT', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  healthcheckdefUpdate (context, opts) {
    const updates = Object.assign({}, context.state.update)
    const current = Object.assign({}, context.state.current)
    for (const key of Object.keys(updates)) {
      if (updates[key] === null || updates[key] === current[key]) {
        delete updates[key]
      }
    }
    return new Promise((resolve, reject) => {
      axios
        .patch(`/api/healthcheckdef/${context.state.current.id}`, updates)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => {
          if (error.response && error.response.data) {
            reject(`${error.response.data.name}: ${error.response.data.raw}`)
          } else {
            reject(error)
          }
        })
    })
  }
}
