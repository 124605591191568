import axios from 'axios'

export default {
  // ======================================================================
  // ==========================List Management=============================
  // ======================================================================

  scheduleList (context, opts) {
    let noVuex = false
    if (opts && opts.noVuex) {
      noVuex = true
      delete opts.noVuex
    }
    return new Promise((resolve, reject) => {
      axios
        .get('/api/schedule', { params: opts })
        .then(({ data }) => {
          if (!noVuex) {
            context.commit('SCHEDULE_LIST', data)
          }
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  // ==========================Socket Updates==============================
  scheduleCreated (context, opts) {
    if (context.rootState.User.permissions.schedule && context.rootState.User.permissions.schedule.read) {
      if (opts && opts.id && opts.area && opts.area === context.rootState.Area.current.id) {
        axios
          .get(`/api/schedule/${opts.id}`)
          .then(({ data }) => {
            if (data.id && data.area && data.area === context.rootState.Area.current.id) {
              context.commit('SCHEDULE_LIST_ADD', data)
            }
          }).catch(() => {
          })
      }
    }
  },
  scheduleUpdated (context, opts) {
    if (opts && opts.id && opts.area && opts.area === context.rootState.Area.current.id) {
      axios
        .get(`/api/schedule/${opts.id}`)
        .then(({ data }) => {
          if (data.id) {
            if (context.state.list.find((i) => i.id === data.id) !== undefined) {
              context.commit('SCHEDULE_LIST_UPDATE', data)
            } else if (data.id && data.area && data.area === context.rootState.Area.current.id) {
              context.commit('SCHEDULE_LIST_ADD', data)
            }
          }
        }).catch(() => {
        })
    }
  },
  scheduleDestroyed (context, opts) {
    // always try to remove from list
    context.commit('SCHEDULE_LIST_ITEM_REMOVE', opts)
  },
  // ===========================List Edits===============================
  scheduleAdd (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/schedule', opts)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  scheduleDelete (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/api/schedule/${opts}`)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  // ======================================================================
  // ==========================Item Management=============================
  // ======================================================================
  scheduleDetail (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/schedule/${id}`)
        .then(({ data }) => {
          context.commit('SCHEDULE_CURRENT', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  scheduleUpdate (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/api/schedule/${opts.id}`, opts)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  }
}
