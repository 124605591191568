import axios from 'axios'

export default {
  // ======================================================================
  // ==========================List Management=============================
  // ======================================================================
  acmApplianceList (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/acmappliance')
        .then(({ data }) => {
          context.commit('ACMAPPLIANCE_LIST', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  // ==========================Socket Updates==============================
  acmApplianceCreated (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    const inputs = { }
    if (opts && Array.isArray(opts.populate) && opts.populate.length > 0) {
      inputs.params = { }
      inputs.params.populate = opts.populate
    }
    axios
      .get(`/api/acmappliance/${id}`, inputs)
      .then(({ data }) => {
        data.socketUpdate = true
        context.commit('ACMAPPLIANCE_LIST_ADD', data)
      }).catch(() => {
      })
  },
  acmApplianceUpdated (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    const inputs = { }
    if (opts && Array.isArray(opts.populate) && opts.populate.length > 0) {
      inputs.params = { }
      inputs.params.populate = opts.populate
    }
    const inList = context.state.list.find((i) => i.id === id)
    if (context.state.current.id === id || inList) {
      axios
        .get(`/api/acmappliance/${id}`, inputs)
        .then(({ data }) => {
          if (data.id === context.state.current.id) {
            context.commit('ACMAPPLIANCE_CURRENT', data)
          }
          if (inList) {
            context.commit('ACMAPPLIANCE_LIST_UPDATE', data)
          }
        }).catch(() => {
        })
    }
  },
  acmApplianceDestroyed (context, opts) {
    // always try to remove from list
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    context.commit('ACMAPPLIANCE_LIST_REMOVE', id)
    if (id === context.state.current.id) {
      context.commit('ACMAPPLIANCE_CURRENT', null)
    }
  },
  // ======================================================================
  // ==========================Item Management=============================
  // ======================================================================
  acmApplianceDetail (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    const inputs = { }
    if (opts && Array.isArray(opts.populate) && opts.populate.length > 0) {
      inputs.params = { }
      inputs.params.populate = opts.populate
    }
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/acmappliance/${id}`, inputs)
        .then(({ data }) => {
          context.commit('ACMAPPLIANCE_CURRENT', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  }
}
