export default function () {
  return {
    current: {
      id: '',
      name: '',
      organizationCount: 0,
      userCount: 0,
      organizations: [],
      users: []
    },
    update: {
      id: '',
      name: ''
    },
    list: []
  }
}
