export default function () {
  return {
    current: {
      id: '',
      name: '',
      resource: '',
      organization: '',
      description: '',
      columns: ['kind', 'name', 'value'],
      filters: {}
    },
    update: {
      name: '',
      resource: '',
      organization: '',
      description: '',
      columns: ['kind', 'name', 'value'],
      filters: {}
    },
    list: []
  }
}
