import axios from 'axios'

export default {
  // ==========================Socket Updates==============================
  dashboardCreated (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    axios
      .get(`/api/dashboard/${id}`)
      .then(({ data }) => {
        if (data.id) {
          context.commit('DASHBOARD_LIST_ADD', data)
        }
      }).catch(() => {
      })
  },
  dashboardUpdated (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    const inList = context.state.list.find((i) => i.id === id)
    if (context.state.current.id === id || inList) {
      axios
        .get(`/api/dashboard/${id}`)
        .then(({ data }) => {
          if (data.id) {
            if (context.state.current.id === data.id) {
              data.socketUpdate = true
              context.commit('DASHBOARD_CURRENT', data)
            }
            if (inList) {
              context.commit('DASHBOARD_LIST_UPDATE', data)
            }
          }
        }).catch(() => {
        })
    }
  },
  dashboardDestroyed (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    const inList = context.state.list.find((i) => i.id === id)
    if (inList) {
      context.commit('DASHBOARD_LIST_REMOVE', id)
    }
    if (context.state.current.id === id) {
      if (context.state.list.length > 0) {
        context.commit('DASHBOARD_CURRENT', context.state.list[0])
      } else {
        context.commit('DASHBOARD_CURRENT', null)
      }
    }
  },
  // ===========================List Edits===============================
  dashboardAdd (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/dashboard', opts)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  dashboardDelete (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .delete('/api/dashboard', { data: opts })
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  // ======================================================================
  // ==========================Item Management=============================
  // ======================================================================
  dashboardList (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/dashboard')
        .then(({ data }) => {
          context.commit('DASHBOARD_LIST', data.dashboards)
          context.commit('POINT_LIST', data.points)
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  dashboardRefresh (context, opts) {
    const ids = {}
    const descend = (parent) => {
      if (parent.children) {
        for (const child of parent.children) {
          if (child.meta && child.meta.point) {
            ids[child.meta.point] = true
          }
          if (child.meta && child.meta.points) {
            try {
              for (const point of JSON.parse(child.meta.points)) {
                ids[point.id] = true
              }
            } catch (e) {
            }
          }
          if (child.children) {
            descend(child)
          }
        }
      }
    }
    descend(JSON.parse(context.state.update.data))
    return new Promise((resolve, reject) => {
      axios
        .post('/api/point/get-values', { ids: Object.keys(ids) })
        .then(({ data }) => {
          context.commit('DASHBOARD_UPDATE_VALUES', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  dashboardUpdate (context, opts) {
    const updates = Object.assign({}, context.state.update)
    for (const key of Object.keys(updates)) {
      if (updates[key] === null && key !== 'containers') {
        delete updates[key]
      }
      if (key === 'containers') {
        updates.containers = [...context.state.update.containers] // not fixed above with Object.assign
      }
    }
    const lastIndex = updates.containers.length - 1
    for (let int = lastIndex; int >= 1; int--) {
      if (!updates.containers[int].occupied) {
        updates.containers.splice(int, 1)
      } else {
        break // not stopping would change structure
      }
    }
    return new Promise((resolve, reject) => {
      axios
        .patch(`/api/dashboard/${context.state.current.id}`, updates)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  }
}
