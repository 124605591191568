export default function () {
  return {
    list: [],
    current: {
      aggregation: '',
      aggregationLowerIsWorse: false,
      aggregationThreshold: 0,
      alarmEmailSubject: null,
      conditions: '',
      defaultThreshold: 0,
      delay: null,
      description: '',
      id: '',
      kind: '',
      lowerIsWorse: false,
      name: '',
      normalEmailSubject: null,
      organization: '',
      protected: false,
      resourceType: '',
      showTriggeredValue: false,
      systemKind: '',
      unitOfMeasure: ''
    },
    update: {
      aggregation: '',
      aggregationLowerIsWorse: false,
      aggregationThreshold: 0,
      alarmEmailSubject: null,
      conditions: '',
      defaultThreshold: 0,
      delay: null,
      description: '',
      id: '',
      kind: '',
      lowerIsWorse: false,
      name: '',
      normalEmailSubject: null,
      showTriggeredValue: false,
      unitOfMeasure: ''
    }
  }
}
