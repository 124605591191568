export default function () {
  return {
    current: {
      id: '',
      name: '',
      building: '',
      tags: [],
      healthChecks: [],
      healthCheckKinds: []
    },
    update: {
      id: '',
      name: ''
    },
    list: []
  }
}
