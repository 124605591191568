export default function () {
  return {
    io: null,
    enrolled: false,
    enrolling: false,
    initialConnection: true,
    offline: false,
    inhibitUpdate: false
  }
}
