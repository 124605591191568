<template lang="pug">
<router-view />
</template>

<script>
import './init'
export default {
  name: 'App',
  created () {
    this.$store.dispatch('socketInit')
    if (window.matchMedia('(display-mode: standalone)').matches) { // chrome
      this.$store.commit('INSTALLED', true)
    } else if (window.navigator && window.navigator.standalone === true) { // safari
      this.$store.commit('INSTALLED', true)
    }
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault()
      // Stash the event so it can be triggered later.
      this.$store.commit('INSTALL_PROMPT', e)
    })
    window.addEventListener('appinstalled', (evt) => {
      this.$store.commit('INSTALLED', true)
    })
    this.$q.iconMapFn = (iconName) => {
      if (iconName.startsWith('d-') === true) {
        return {
          cls: 'dayicon ' + iconName
        }
      }
    }
  },
  computed: {
    authenticated: {
      get () {
        return this.$store.state.User.authenticated
      }
    }
  },
  methods: {
  },
  watch: {
    authenticated (val) {
      const context = this.$store
      if (val) {
        setTimeout(() => {
          context.dispatch('socketEnroll')
        }, 1000)
      } else {
        context.dispatch('socketUnenroll')
      }
    }
  }
}
</script>

<style>
</style>
