import axios from 'axios'

export default {
  diagnosticCameraTimeline (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    const inputs = { params: {} }
    if (opts && opts.minTime) {
      inputs.params.minTime = opts.minTime
    }
    if (opts && opts.maxTime) {
      inputs.params.maxTime = opts.maxTime
    }
    if (opts && opts.scope) {
      inputs.params.scope = opts.scope
    }
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/camera/${id}/request-timeline`, inputs)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  }
}
