import axios from 'axios'
export default {
  // ======================================================================
  // ==========================List Management=============================
  // ======================================================================
  async adHocList (context, opts) {
    try {
      const { data } = await axios.get('api/adhoc')
      context.commit('ADHOC_LIST', data)
    } catch (err) {}
  },
  async adHocDetail (context, id) {
    try {
      const { data } = await axios.get(`api/adhoc/${id}`)
      context.commit('ADHOC_CURRENT', data)
    } catch (err) {}
  },
  async adHocColumns (context, model) {
    try {
      const { data } = await axios.get(`/api/adhoc/get-columns?model=${model}`)
      context.commit('ADHOC_COLUMNS', data)
      return data
    } catch (err) {}
  },
  async adHocUpdate (context, opts) {
    try {
      const updates = Object.assign({}, context.state.update)
      for (const key of Object.keys(updates)) {
        if (updates[key] == null) {
          delete updates[key]
        }
      }
      updates.search = {
        filters: updates.filters,
        columns: updates.columns
      }
      await axios.patch(`/api/adhoc/${context.state.current.id}`, updates)
    } catch (err) {}
  },
  async adHocAdd (context, opts) {
    try {
      await axios.post('/api/adhoc', opts)
    } catch (err) {}
  },
  async adHocDelete (context, id) {
    try {
      await axios.delete(`/api/adhoc/${id}`)
    } catch (err) {}
  },
  // ==========================Socket Updates==============================
  async adhocCreated (context, opts) {
    const { data } = await axios.get(`/api/adhoc/${opts.id}`)
    context.commit('ADHOC_LIST_ADD', data)
  },
  async adhocUpdated (context, opts) {
    const { data } = await axios.get(`/api/adhoc/${opts.id}`)
    context.commit('ADHOC_LIST_UPDATE', data)
  },
  adhocDestroyed (context, opts) {
    context.commit('ADHOC_LIST_REMOVE', opts.id)
  }
}
