export default {
  ICFUSIONENDPOINT_LIST (state, value) {
    state.list = (value && Array.isArray(value)) ? [...(value.sort((a, b) => a.description.localeCompare(b.description)))] : []
  },
  ICFUSIONENDPOINT_LIST_UPDATE (state, value) {
    const index = state.list.findIndex((i) => i.id === value.id)
    if (index !== -1) {
      state.list.splice(index, 1, Object.assign(state.list[index], value))
    } else {
      state.list.push(value)
    }
  },
  ICFUSIONENDPOINT_CURRENT (state, value) {
    const props = [
      'id',
      'description',
      'deviceIdentifier',
      'endpointId',
      'icopId',
      'ipAddress',
      'isLicensed',
      'name',
      'reenableTime',
      'system',
      'type',
      'vendorId'
    ]
    if (value) {
      for (const prop of props) {
        if (value[prop] !== state.current[prop]) {
          state.current[prop] = value[prop]
        }
      }
    } else {
      state.current = {
        description: '',
        deviceIdentifier: '',
        endpointId: '',
        icopId: '',
        id: '',
        ipAddress: '',
        isLicensed: '',
        name: '',
        reenableTime: '',
        system: '',
        type: '',
        vendorId: ''
      }
    }
  },
  ICFUSIONENDPOINT_FETCHING_LIST (state, value) {
    state.fetchingList = value
  }
}
