import axios from 'axios'

export default {
  grouppermissionList (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/grouppermission', { params: { group: opts } })
        .then(({ data }) => {
          context.commit('GROUPPERMISSION_LIST', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  }
}
