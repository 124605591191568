export default {
  ACMAPPLIANCE_LIST (state, value) {
    state.list = (value && Array.isArray(value)) ? [...(value.sort((a, b) => (a.name > b.name) ? 1 : -1))] : []
  },
  ACMAPPLIANCE_LIST_ADD (state, value) {
    if (state.list.findIndex((i) => i.id === value.id) === -1) {
      state.list.push(value)
    }
  },
  ACMAPPLIANCE_LIST_REMOVE (state, value) {
    const index = state.list.findIndex((i) => i.id === value)
    if (index !== -1) {
      state.list.splice(index, 1)
    }
  },
  ACMAPPLIANCE_LIST_UPDATE (state, value) {
    const index = state.list.findIndex((i) => i.id === value.id)
    if (index !== -1) {
      state.list.splice(index, 1, Object.assign(state.list[index], value))
    } else {
      state.list.push(value)
    }
  },
  ACMAPPLIANCE_CURRENT (state, value) {
    if (value) {
      for (const prop of ['id', 'name', 'dn', 'port', 'reenableTime', 'active', 'system', 'versionNumber']) {
        if (value[prop] !== state.current[prop]) {
          state.current[prop] = value[prop]
        }
      }
    } else {
      state.current = {
        id: '',
        name: '',
        userCount: 0,
        organizationCount: 0
      }
    }
  }
}
