import axios from 'axios'

export default {
  // ==========================Socket Updates==============================
  healthcheckCreated (context, opts) {
    // do nothing
  },
  healthcheckUpdated (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    const inputs = { }
    if (opts && Array.isArray(opts.populate) && opts.populate.length > 0) {
      inputs.params = { }
      inputs.params.populate = opts.populate
    }
    const inList = context.state.list.find((i) => i.id === id)
    if (context.state.current.id === id || inList) {
      axios
        .get(`/api/healthcheck/${id}`, inputs)
        .then(({ data }) => {
          if (data.id && context.state.current.id === id) {
            data.socketUpdate = true
            context.commit('HEALTHCHECK_CURRENT', data)
          }
          if (inList) {
            context.commit('HEALTHCHECK_LIST_UPDATE', data)
          }
        }).catch(() => {
        })
    }
  },
  healthcheckDestroyed (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    if (id === context.state.current.id) {
      context.commit('HEALTHCHECK_CURRENT', null)
    }
    context.commit('HEALTHCHECK_LIST_ITEM_REMOVE', opts)
  },
  // ======================================================================
  // ==========================Item Management=============================
  // ======================================================================
  healthcheckList (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/healthcheck', { params: opts })
        .then(({ data }) => {
          context.commit('HEALTHCHECK_LIST', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  healthcheckDetail (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    const inputs = { }
    if (opts && Array.isArray(opts.populate) && opts.populate.length > 0) {
      inputs.params = { }
      inputs.params.populate = opts.populate
    }
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/healthcheck/${id}`, inputs)
        .then(({ data }) => {
          context.commit('HEALTHCHECK_CURRENT', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  healthcheckHistory (context, opts) {
    const inputs = typeof opts === 'string' ? { id: opts } : opts && opts.id ? opts : '#'
    return new Promise((resolve, reject) => {
      axios
        .post('/api/healthcheck/history', inputs)
        .then(({ data }) => {
          context.commit('HEALTHCHECK_CURRENT_HISTORY', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  healthcheckExtendedHistory (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/healthcheck/history', opts)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  healthcheckUpdate (context, opts) {
    const updates = Object.assign({}, context.state.update)
    const current = Object.assign({}, context.state.current)
    for (const key of Object.keys(updates)) {
      if (updates[key] === null || updates[key] === current[key]) { // allow null value. see aggregationThreshold
        delete updates[key]
      }
    }
    if (updates && Object.keys(updates).length > 0) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/api/healthcheck/${context.state.current.id}`, updates)
          .then(({ data }) => {
            resolve(data)
          }).catch((error) => reject(error))
      })
    } else {
      throw new Error('Unable to save changes. try refreshing the page.')
    }
  },
  bulkUpdateHealthChecks (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .patch('/api/healthcheck/bulkupdate', opts)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  }
}
