export default function () {
  return {
    current: {
      comfort: '',
      coolSetpoint: '',
      coolSetpoints: '',
      delta: '',
      heatSetpoint: '',
      highChildCount: '',
      history: [],
      lowChildCount: '',
      maxChildComfort: '',
      maxChildDelta: '',
      minChildComfort: '',
      minChildDelta: '',
      name: '',
      resourceId: '',
      space: '',
      time: ''
    },
    children: {},
    history: {},
    list: []
  }
}
