import { createRouter, createMemoryHistory, createWebHistory, createWebHashHistory } from 'vue-router'
import routes from './routes'

export default function ({ store /*, ssrContext */ }) {
  const createHistory = process.env.SERVER
    ? createMemoryHistory
    : (process.env.VUE_ROUTER_MODE === 'history' ? createWebHistory : createWebHashHistory)

  const Router = createRouter({
    scrollBehavior: () => ({ left: 0, top: 0 }),
    routes,
    history: createHistory(process.env.MODE === 'ssr' ? undefined : process.env.VUE_ROUTER_BASE)
  })

  Router.beforeEach(async (to, from, next) => {
    if (!store.state.User.authenticated) {
      try {
        await store.dispatch('userGet')
      } catch (ex) {}
    }
    const authenticated = store.state.User.authenticated
    const requiresAuth = to.matched.some((record) => record.meta && record.meta.requiresAuth)
    const requiresUnAuth = to.matched.some((record) => record.meta && record.meta.requiresUnAuth)
    const noFlashComponent = !(to.matched.some((record) => record.meta && record.meta.noFlash))
    store.commit('SET_FLASH_PAGE_SPECIFIC', noFlashComponent)
    if (requiresAuth && !authenticated) {
      const loginpath = to.fullPath
      if (loginpath.match(/logout|login/)) {
        next({ name: 'Login' })
      } else {
        next({ name: 'Login', query: { to: loginpath } })
      }
    } else if (requiresUnAuth && authenticated) {
      if (store.state.User.loggedIn.dashboardHomepage) {
        next({ name: 'Dashboard' })
      } else {
        next({ name: 'Overview' })
      }
    } else {
      next()
    }
  })
  Router.afterEach(async (to, from) => {
    const adminPath = /^\/admin\/.*/
    const mutationHash = store._mutations
    // clear vuex when moving away from admin pages
    if ((!adminPath.test(to.path) && adminPath.test(from.path)) || (!to.query.id && from.query.id)) {
      for (const key of Object.keys(store.state)) {
        if (mutationHash[`${key.toUpperCase()}_LIST`]) {
          store.commit(`${key.toUpperCase()}_LIST`, null)
        }
        if (mutationHash[`${key.toUpperCase()}_CURRENT`]) {
          store.commit(`${key.toUpperCase()}_CURRENT`, null)
        }
      }
      store.commit('BACK_TO', null)
      console.log('vuex cleaned by router/index')
    }
  })
  return Router
}
