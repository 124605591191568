export default {
  ADHOC_CURRENT (state, value) {
    const props = ['id', 'name', 'resource', 'organization', 'description']
    for (const prop of props) {
      if (value && value[prop]) {
        if (value[prop] !== state.current[prop]) {
          state.current[prop] = value[prop]
        }
        if (value[prop] !== state.update[prop]) {
          state.update[prop] = value[prop]
        }
      } else {
        state.current[prop] = ''
        state.update[prop] = ''
      }
    }
    state.current.columns = value?.search ? value.search.columns : []
    state.update.columns = value?.search ? value.search.columns : []
    state.current.filters = value?.search ? value.search.filters : {}
    state.update.filters = value?.search ? value.search.filters : {}
  },
  ADHOC_RESET (state) {
    state.current.id = ''
    state.current.name = ''
    state.current.resource = ''
    state.current.organization = ''
    state.current.description = ''
    state.current.columns = []
    state.current.filters = {}

    state.update.id = ''
    state.update.name = ''
    state.update.resource = ''
    state.update.organization = ''
    state.update.description = ''
    state.update.columns = []
    state.update.filters = {}
  },
  ADHOC_LIST (state, value) {
    state.list = value || []
  },
  ADHOC_LIST_ADD (state, value) {
    state.list.push(value)
  },
  ADHOC_LIST_UPDATE (state, value) {
    if (state.current.id === value.id) {
      state.current = value
    }
  },
  ADHOC_LIST_REMOVE (state, id) {
    const index = state.list.findIndex(i => i.id === id)
    if (index) {
      state.list.splice(index, 1)
    }
  },
  ADHOC_ORGANIZATION (state, value) {
    state.current.organization = value
  },
  ADHOC_RESOURCE (state, value) {
    state.current.resource = value
  },
  ADHOC_COLUMNS (state, value) {
    state.current.columns = value
  },
  ADHOC_UPDATE_COLUMNS (state, value) {
    state.update.columns = value
  },
  ADHOC_UPDATE_COLUMN (state, value) {
    const index = state.update.columns.findIndex(c => c === value)
    if (index) {
      state.update.columns.splice(index, 1)
    } else {
      state.update.columns.push(value)
    }
  },
  ADHOC_FILTERS (state, value) {
    state.current.filters = value
  },
  ADHOC_ADD_FILTER (state, value) {
    state.update.filters[value.key] = value.filter
  },
  ADHOC_UPDATE_FILTER (state, value) {
    if (value.firstParam !== undefined) state.update.filters[value.name].firstParam = value.firstParam
    if (value.secondParam !== undefined) state.update.filters[value.name].secondParam = value.secondParam
    if (value.comparator !== undefined) state.update.filters[value.name].comparator = value.comparator
  },
  ADHOC_UPDATE_OR_FILTER (state, value) {
    state.update.filters[value.name].params[value.index] = value.value
  },
  ADHOC_ADD_OR (state, name) {
    state.update.filters[name].params.push('')
  },
  ADHOC_REMOVE_OR_FILTER (state, value) {
    if (state.update.filters[value]?.params.length > 1) {
      state.update.filters[value].params.pop()
    }
  },
  ADHOC_REMOVE_FILTER (state, name) {
    delete state.update.filters[name]
  },
  ADHOC_UPDATE_CURRENT (state) {
    state.current.name = state.update.name
    state.current.resource = state.update.resource
    state.current.organization = state.update.organization
    state.current.description = state.update.description
    state.current.columns = state.update.columns
    state.current.filters = state.update.filters
  },
  ADHOC_UPDATE_NAME (state, value) {
    state.update.name = value
  },
  ADHOC_UPDATE_ORGANIZATION (state, value) {
    state.update.organization = value
  },
  ADHOC_UPDATE_RESOURCE (state, value) {
    state.update.resource = value
  },
  ADHOC_UPDATE_DESCRIPTION (state, value) {
    state.update.description = value
  }
}
