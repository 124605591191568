import axios from 'axios'

export default {
  // ==========================Socket Updates==============================
  pointCreated (context, opts) {
    const permission = (context.rootState.User.permissions.point && context.rootState.User.permissions.point.read)
    const parentId = context.rootState.Hvaccontroller.current.id ?? ''
    const parentResult = (opts?.hvacController && opts.hvacController === parentId)
    if (permission && parentResult) {
      const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
      const inputs = { }
      if (opts && Array.isArray(opts.populate) && opts.populate.length > 0) {
        inputs.params = { }
        inputs.params.populate = opts.populate
      }
      axios
        .get(`/api/point/${id}`, inputs)
        .then(({ data }) => {
          if (data.id) {
            context.commit('POINT_LIST_UPDATE', data)
          }
        }).catch(() => {
        })
    }
  },
  pointUpdated (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    const inputs = { }
    if (opts && Array.isArray(opts.populate) && opts.populate.length > 0) {
      inputs.params = { }
      inputs.params.populate = opts.populate
    }
    const inList = context.state.list.find((i) => i.id === id)
    if (context.state.current.id === id || inList) {
      axios
        .get(`/api/point/${id}`, inputs)
        .then(({ data }) => {
          if (context.state.current.id === data.id) {
            data.socketUpdate = true
            context.commit('POINT_CURRENT', data)
          }
          if (inList) {
            context.commit('POINT_LIST_UPDATE', data)
          }
        }).catch(() => {
        })
    }
  },
  pointValue (context, opts) {
    if (context.state.current.id === opts.id) {
      context.commit('POINT_CURRENT_VALUE', opts.value)
    }
    if (context.state.list.find((i) => i.id === opts.id)) {
      context.commit('POINT_LIST_UPDATE', opts)
    }
  },
  pointDestroyed (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    if (id === context.state.current.id) {
      context.commit('POINT_CURRENT', null)
    }
    if (context.state.list.find((i) => i.id === id)) {
      context.commit('POINT_LIST_REMOVE', id)
    }
  },
  // ===========================List Edits===============================
  pointAdd (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/point', opts)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  pointBulkAdd (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/point/bulk-create', opts)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  pointDelete (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/api/point/${opts}`)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  // ======================================================================
  // ==========================Item Management=============================
  // ======================================================================
  pointList (context, opts) {
    let noVuex = false
    if (opts && opts.noVuex) {
      noVuex = true
      delete opts.noVuex
    }
    return new Promise((resolve, reject) => {
      axios
        .get('/api/point', { params: opts })
        .then(({ data }) => {
          if (!noVuex) {
            context.commit('POINT_LIST', data)
          }
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  pointDetail (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    const inputs = { }
    if (opts && Array.isArray(opts.populate) && opts.populate.length > 0) {
      inputs.params = { }
      inputs.params.populate = opts.populate
    }
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/point/${id}`, inputs)
        .then(({ data }) => {
          if (!opts.noVuex) {
            context.commit('POINT_CURRENT', data)
          }
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  pointHistory (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/point/history', { params: { id: opts } })
        .then(({ data }) => {
          context.commit('POINT_CURRENT_HISTORY', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  pointValueHistory (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/point/value-history', { params: opts })
        .then(({ data }) => {
          context.commit('POINT_CURRENT_VALUE_HISTORY', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  listPointHistory (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : null
    const inputs = { params: { populate: ['history'] } }
    if (id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/point/${id}`, inputs)
          .then(({ data }) => {
            context.commit('POINT_LIST_UPDATE', data) // history is attached
            resolve(data)
          }).catch((error) => reject(error))
      })
    }
  },
  listAppendPoint (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    const inputs = { }
    if (opts && Array.isArray(opts.populate) && opts.populate.length > 0) {
      inputs.params = { }
      inputs.params.populate = opts.populate
    }
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/point/${id}`, inputs)
        .then(({ data }) => {
          context.commit('POINT_LIST_UPDATE', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  pointUpdate (context, opts) {
    const updates = Object.assign({}, context.state.update)
    for (const key of Object.keys(updates)) {
      if (updates[key] == null && key !== 'building' && key !== 'room') {
        delete updates[key]
      }
    }
    return new Promise((resolve, reject) => {
      axios
        .patch(`/api/point/${context.state.current.id}`, updates)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  pointActiveUpdate (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/api/point/${opts.id}`, opts.data)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  pointSet (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/point/set', opts)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  pointUpdateRoom (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/api/point/${opts.id}`, { building: opts.building, room: opts.room })
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  bulkUpdatePoints (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .patch('/api/point/bulkupdate', opts)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  async pointSubscribe (context, opts) {
    try {
      await axios.post('/api/point/subscribe', { ids: opts })
    } catch (err) {
    }
  },
  async pointUnsubscribe (context, opts) {
    try {
      await axios.post('/api/point/unsubscribe', { ids: opts })
    } catch (err) {}
  }
}
