import axios from 'axios'

export default {
  dashboardUsers (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/userdashboard', { params: opts })
        .then(({ data }) => {
          context.commit('USERDASHBOARD_USERS', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  userDashboards (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/userdashboard', { params: opts })
        .then(({ data }) => {
          context.commit('USERDASHBOARD_DASHBOARDS', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  userdashboardUpdate (context, opts) {
    const id = opts.id
    delete opts.id
    return new Promise((resolve, reject) => {
      axios
        .patch(`/api/userdashboard/${id}`, Object.assign({}, opts))
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  userdashboardUpdated (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    const listItem = (context.state.user.dashboards.find((i) => i.id === id) || context.state.dashboard.users.find((i) => i.id === id))
    if (listItem) {
      axios
        .get(`/api/userdashboard/${id}`)
        .then(({ data }) => {
          context.commit('USERDASHBOARD_UPDATE_LISTS', data)
        }).catch(() => {
        })
    }
  },
  userdashboardCreated (context, opts) {
    const currentDash = (opts.dashboard && opts.dashboard === context.rootState.Dashboard.current.id)
    const currentUser = (opts.user && opts.user === context.rootState.User.loggedIn.id)
    if (currentDash || currentUser) {
      axios
        .get(`/api/userdashboard/${opts.id}`)
        .then(({ data }) => {
          if (currentDash) {
            context.commit('USERDASHBOARD_USER_LIST_ADD', data)
          }
          if (currentUser) {
            context.commit('USERDASHBOARD_DASHBOARD_LIST_ADD', data)
          }
        }).catch(() => {
        })
    }
  },
  userdashboardCreate (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/userdashboard', opts)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  userdashboardDestroy (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/api/userdashboard/${opts}`)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  userdashboardDestroyed (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    const listItem = (context.state.user.dashboards.find((i) => i.id === id) || context.state.dashboard.users.find((i) => i.id === id))
    if (listItem) {
      context.commit('USERDASHBOARD_DESTROYED', id)
    }
  }
}
