import axios from 'axios'

export default {
  helppageDetail (context, path) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/helppage?path=${path}`)
        .then(({ data }) => {
          context.commit('HELPPAGE_CURRENT', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  helppageUpdate (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/api/helppage?path=${context.state.current.path}`, context.state.update || ' ')
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  }
}
