export default {
  USER_CREATE (state, value) {
    const specific = [
      'email',
      'firstName',
      'id',
      'lastName',
      'mobilePhone'
    ]
    const props = [
      'branch',
      'firstStatusEmail',
      'secondStatusEmail',
      'systemKinds',
      'thirdStatusEmail'
    ]
    if (value?.clone && value?.email) {
      const split = value.email.split('@')
      state.create.email = split[1] ? `@${split[1]}` : ''
    } else {
      props.push(...specific)
    }
    for (const prop of props) {
      state.create[prop] = value && value[prop] ? value[prop] : null
    }
    state.create.alarmsByBuilding = value?.alarmsByBuilding ?? true
    state.create.isDay = value?.isDay ?? false
    state.create.sendSetupEmail = value?.sendSetupEmail ?? true
    state.create.userorgs = value?.userorgs ?? []
    state.create.statusEmailVersion = value?.statusEmailVersion ?? 0
  },
  USER_CREATE_ALARMS_BY_BUILDING (state, value) {
    state.create.alarmsByBuilding = value
  },
  USER_CREATE_BRANCH (state, value) {
    state.create.branch = value
  },
  USER_CREATE_EMAIL (state, value) {
    state.create.email = value
  },
  USER_CREATE_FIRST_NAME (state, value) {
    state.create.firstName = value
  },
  USER_CREATE_FIRST_STATUS_EMAIL (state, value) {
    state.create.firstStatusEmail = value
  },
  USER_CREATE_ID (state, value) {
    state.create.id = value
  },
  USER_CREATE_IS_DAY (state, value) {
    state.create.isDay = value
  },
  USER_CREATE_LAST_NAME (state, value) {
    state.create.lastName = value
  },
  USER_CREATE_MOBILE_PHONE (state, value) {
    state.create.mobilePhone = value
  },
  USER_CREATE_SECOND_STATUS_EMAIL (state, value) {
    state.create.secondStatusEmail = value
  },
  USER_CREATE_SEND_SETUP_EMAIL (state, value) {
    state.create.sendSetupEmail = value
  },
  USER_CREATE_SERVICE_DIVISIONS (state, value) {
    state.create.serviceDivisions = value
  },
  USER_CREATE_STATUS_EMAIL_VERSION (state, value) {
    state.create.statusEmailVersion = value
  },
  USER_CREATE_SYSTEM_KINDS (state, value) {
    state.create.systemKinds = value
  },
  USER_CREATE_THIRD_STATUS_EMAIL (state, value) {
    state.create.thirdStatusEmail = value
  },
  USER_CREATE_USER_ORGS (state, value) {
    state.create.userorgs = value
  },
  USER_CREATE_USER_ORGS_UPDATE (state, value) {
    const index = state.create.userorgs.findIndex((i) => i.id === value.id)
    if (index !== -1) {
      state.list.splice(index, 1, Object.assign(state.create.userorgs, value))
    }
  },
  USER_ACCOUNT_UPDATE (state, data) {
    if (data) {
      const props = [
        'active',
        'alarmsByBuilding',
        'email',
        'favoriteOrgId',
        'firstName',
        'firstStatusEmail',
        'lastName',
        'mobilePhone',
        'password',
        'reenableTime',
        'secondStatusEmail',
        'statusEmailVersion',
        'thirdStatusEmail'
      ]
      for (const prop of props) {
        if (data[prop] !== state.loggedIn[prop]) {
          state.loggedIn[prop] = data[prop]
        }
        if (data[prop] !== state.updateLoggedIn[prop]) {
          state.updateLoggedIn[prop] = data[prop]
        }
      }
    }
  },
  USER_SET (state, data) {
    if (data && data.user) {
      Object.assign(state.loggedIn, data.user)
      state.loggedIn.dashboardHomepage = data.dashboardHomepage

      state.updateLoggedIn.active = data.user.active
      state.updateLoggedIn.alarmsByBuilding = data.user.alarmsByBuilding
      state.updateLoggedIn.email = data.user.email
      state.updateLoggedIn.favoriteOrgId = data.user.favoriteOrgId
      state.updateLoggedIn.firstName = data.user.firstName
      state.updateLoggedIn.firstStatusEmail = data.user.firstStatusEmail
      state.updateLoggedIn.lastName = data.user.lastName
      state.updateLoggedIn.mobilePhone = data.user.mobilePhone
      state.updateLoggedIn.password = data.user.password
      state.updateLoggedIn.reenableTime = data.user.reenableTime
      state.updateLoggedIn.secondStatusEmail = data.user.secondStatusEmail
      state.updateLoggedIn.statusEmailVersion = data.user.statusEmailVersion
      state.updateLoggedIn.thirdStatusEmail = data.user.thirdStatusEmail

      state.loggedIn.bookmarks = data.bookmarks || []
      state.loggedIn.feedbacks = data.feedbacks || []
      if (data.permissions) {
        state.permissions = data.permissions
      }
      if (data.groupIds) {
        state.groupIds = data.groupIds
      }
      if (data.orgIds) {
        state.orgIds = data.orgIds
      }
      if (data.systemKinds) {
        state.systemKinds = data.systemKinds
      }
      state.authenticated = state.loggedIn.id !== null
    } else {
      state.authenticated = false
      state.groupIds = []
      state.loggedIn.active = false
      state.loggedIn.alarmsByBuilding = false
      state.loggedIn.allOrganizations = false
      state.loggedIn.bookmarks = []
      state.loggedIn.feedbacks = []
      state.loggedIn.branch = ''
      state.loggedIn.dashboardHomepage = false
      state.loggedIn.email = ''
      state.loggedIn.favoriteOrgId = null
      state.loggedIn.firstName = ''
      state.loggedIn.firstStatusEmail = null
      state.loggedIn.id = ''
      state.loggedIn.isDay = false
      state.loggedIn.lastName = ''
      state.loggedIn.mobilePhone = ''
      state.loggedIn.reenableTime = null
      state.loggedIn.secondStatusEmail = null
      state.loggedIn.statusEmailVersion = 0
      state.loggedIn.thirdStatusEmail = null
      state.orgIds = []
      state.permissions = {}
      state.systemKinds = []

      state.updateLoggedIn.active = false
      state.updateLoggedIn.alarmsByBuilding = null
      state.updateLoggedIn.favoriteOrgId = null
      state.updateLoggedIn.firstStatusEmail = null
      state.updateLoggedIn.reenableTime = null
      state.updateLoggedIn.secondStatusEmail = null
      state.updateLoggedIn.thirdStatusEmail = null
    }
  },
  USER_RESET (state) {
  },
  USER_LIST (state, value) {
    state.list = (value && Array.isArray(value)) ? [...(value.sort((a, b) => a.email.localeCompare(b.email)))] : []
  },
  USER_LIST_ADD (state, value) {
    if (state.list.findIndex((i) => i.id === value.id) === -1) {
      state.list.push(value)
    }
  },
  USER_LIST_ITEM_REMOVE (state, value) {
    const id = typeof value === 'string' ? value : typeof value === 'object' && value.id ? value.id : ''
    const index = state.list.findIndex((i) => i.id === id)
    if (index !== -1) {
      state.list.splice(index, 1)
    }
  },
  USER_LIST_UPDATE (state, value) {
    const index = state.list.findIndex((i) => i.id === value.id)
    if (index !== -1) {
      state.list.splice(index, 1, Object.assign(state.list[index], value))
    } else {
      state.list.push(value)
    }
  },
  USER_LOGGEDIN_DASHBOARD_HOMEPAGE (state, value) {
    state.loggedIn.dashboardHomepage = value
  },
  USER_LOGGEDIN_FAVORITE_ORG_ID (state, value) {
    state.loggedIn.favoriteOrgId = value
  },
  USER_CURRENT (state, value) {
    if (value) {
      const props = [
        'id',
        'email',
        'branch',
        'isDay',
        'alarmsByBuilding',
        'allOrganizations',
        'active',
        'firstName',
        'lastName',
        'mobilePhone',
        'firstStatusEmail',
        'firstStatusSent',
        'secondStatusEmail',
        'thirdStatusEmail',
        'statusEmailVersion',
        'systemKinds',
        'reenableTime',
        'serviceDivisions'
      ]
      for (const prop of props) {
        if (value[prop] !== state.current[prop]) {
          state.current[prop] = value[prop]
          state.update[prop] = value[prop]
        }
      }
      for (const prop of ['lastLogin', 'secondStatusSent', 'thirdStatusSent']) {
        if (value[prop] !== state.current[prop]) {
          state.current[prop] = value[prop]
        }
      }
      if (!value.socketUpdate) {
        state.current.emailLogs = value.emailLogs
        state.current.groups = value.groups
        state.current.organizations = value.organizations
      }
    } else {
      state.current = {
        id: '',
        email: '',
        branch: null,
        isDay: false,
        alarmsByBuilding: false,
        allOrganizations: false,
        active: false,
        firstName: '',
        lastName: '',
        lastLogin: null,
        mobilePhone: '',
        firstStatusEmail: null,
        secondStatusEmail: null,
        thirdStatusEmail: null,
        firstStatusSent: false,
        secondStatusSent: false,
        thirdStatusSent: false,
        statusEmailVersion: 0,
        emailLogs: [],
        groups: [],
        organizations: [],
        reenableTime: null,
        systemKinds: [],
        serviceDivisions: []
      }
      state.update = {
        id: null,
        email: '',
        branch: null,
        isDay: false,
        alarmsByBuilding: false,
        allOrganizations: false,
        active: false,
        firstName: '',
        lastName: '',
        mobilePhone: '',
        firstStatusEmail: null,
        firstStatusSent: false,
        secondStatusEmail: null,
        thirdStatusEmail: null,
        reenableTime: null,
        statusEmailVersion: 0,
        systemKinds: [],
        serviceDivisions: []
      }
    }
  },
  USER_UPDATE_EMAIL (state, value) {
    state.update.email = value
  },
  USER_UPDATE_BRANCH (state, value) {
    state.update.branch = value
  },
  USER_UPDATE_ISDAY (state, value) {
    state.update.isDay = value
  },
  USER_UPDATE_ALARMS_BY_BUILDING (state, value) {
    state.update.alarmsByBuilding = value
  },
  USER_UPDATE_ALL_ORGANIZATIONS (state, value) {
    state.update.allOrganizations = value
  },
  USER_UPDATE_ACTIVE (state, value) {
    state.update.active = value
  },
  USER_UPDATE_FIRSTNAME (state, value) {
    state.update.firstName = value
  },
  USER_UPDATE_LASTNAME (state, value) {
    state.update.lastName = value
  },
  USER_UPDATE_MOBILEPHONE (state, value) {
    state.update.mobilePhone = value
  },
  USER_UPDATE_REENABLE_TIME (state, value) {
    state.update.reenableTime = value
  },
  USER_UPDATE_LOGGEDIN_ACTIVE (state, value) {
    state.updateLoggedIn.active = value
  },
  USER_UPDATE_LOGGEDIN_ALARMS_BY_BUILDING (state, value) {
    state.updateLoggedIn.alarmsByBuilding = value
  },
  USER_UPDATE_LOGGEDIN_BOOKMARKS (state, value) {
    const index = state.loggedIn.bookmarks.findIndex((i) => i.id === value.id)
    if (value.remove && index !== -1) {
      state.loggedIn.bookmarks.splice(index, 1)
    } else if (index === -1) {
      state.loggedIn.bookmarks.push(value)
    }
  },
  USER_UPDATE_LOGGEDIN_FEEDBACKS (state, value) {
    const index = state.loggedIn.feedbacks.findIndex((i) => i.id === value.id)
    if (index !== -1) {
      state.loggedIn.feedbacks.splice(index, 1, Object.assign(state.loggedIn.feedbacks[index], value))
    } else if (index === -1) {
      state.loggedIn.feedbacks.push(value)
    }
  },
  USER_UPDATE_LOGGEDIN_REENABLE_TIME (state, value) {
    state.updateLoggedIn.reenableTime = value
  },
  USER_UPDATE_LOGGEDIN_EMAIL (state, value) {
    state.updateLoggedIn.email = value
  },
  USER_UPDATE_LOGGEDIN_FIRSTNAME (state, value) {
    state.updateLoggedIn.firstName = value
  },
  USER_UPDATE_LOGGEDIN_LASTNAME (state, value) {
    state.updateLoggedIn.lastName = value
  },
  USER_UPDATE_LOGGEDIN_MOBILEPHONE (state, value) {
    state.updateLoggedIn.mobilePhone = value
  },
  USER_UPDATE_LOGGEDIN_PASSWORD (state, value) {
    state.updateLoggedIn.password = value
  },
  USER_UPDATE_LOGGEDIN_FIRST_STATUS_EMAIL (state, value) {
    state.updateLoggedIn.firstStatusEmail = value
  },
  USER_UPDATE_LOGGEDIN_SECOND_STATUS_EMAIL (state, value) {
    state.updateLoggedIn.secondStatusEmail = value
  },
  USER_UPDATE_LOGGEDIN_THIRD_STATUS_EMAIL (state, value) {
    state.updateLoggedIn.thirdStatusEmail = value
  },
  USER_UPDATE_LOGGEDIN_STATUS_EMAIL_VERSION (state, value) {
    state.updateLoggedIn.statusEmailVersion = value
  },
  USER_UPDATE_FIRST_STATUS_EMAIL (state, value) {
    state.update.firstStatusEmail = value
  },
  USER_UPDATE_SECOND_STATUS_EMAIL (state, value) {
    state.update.secondStatusEmail = value
  },
  USER_UPDATE_THIRD_STATUS_EMAIL (state, value) {
    state.update.thirdStatusEmail = value
  },
  USER_UPDATE_STATUS_EMAIL_VERSION (state, value) {
    state.update.statusEmailVersion = value
  },
  USER_UPDATE_FIRST_STATUS_SENT (state, value) {
    state.update.firstStatusSent = value
  }
}
