import axios from 'axios'

export default {
  // ======================================================================
  // ==========================List Management=============================
  // ======================================================================
  boschpanelList (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/boschpanel', { params: opts })
        .then(({ data }) => {
          context.commit('BOSCHPANEL_LIST', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  // ==========================Socket Updates==============================
  boschpanelCreated (context, opts) {
    // do nothing
  },
  boschpanelUpdated (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    const inputs = { }
    if (opts && Array.isArray(opts.populate) && opts.populate.length > 0) {
      inputs.params = { }
      inputs.params.populate = opts.populate
    }
    const inList = context.state.list.find((i) => i.id === id)
    if (context.state.current.id === id || inList) {
      axios
        .get(`/api/boschpanel/${id}`, inputs)
        .then(({ data }) => {
          if (data.id === context.state.current.id) {
            context.commit('BOSCHPANEL_CURRENT', data)
          }
          if (inList) {
            context.commit('BOSCHPANEL_LIST_UPDATE', data)
          }
        }).catch(() => {
        })
    }
  },
  boschpanelDestroyed (context, opts) {
    // always try to remove from list
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    if (id === context.state.current.id) {
      context.commit('BOSCHPANEL_CURRENT', null)
    }
    context.commit('BOSCHPANEL_LIST_ITEM_REMOVE', opts)
    context.commit('BOSCHPANELPOINT_LIST_ITEM_REMOVE', opts)
  },
  // ======================================================================
  // ==========================Item Management=============================
  // ======================================================================
  boschpanelDetail (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    const inputs = { }
    if (opts && Array.isArray(opts.populate) && opts.populate.length > 0) {
      inputs.params = { }
      inputs.params.populate = opts.populate
    }
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/boschpanel/${id}`, inputs)
        .then(({ data }) => {
          context.commit('BOSCHPANEL_CURRENT', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  boschpanelHistory (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/boschpanel/history', { params: { id: opts } })
        .then(({ data }) => {
          context.commit('BOSCHPANEL_CURRENT_HISTORY', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  boschpanelUpdate (context, opts) {
    const updates = Object.assign({}, context.state.update)
    for (const key of Object.keys(updates)) {
      if (updates[key] === null) {
        delete updates[key]
      }
    }
    return new Promise((resolve, reject) => {
      axios
        .patch(`/api/boschpanel/${context.state.current.id}`, updates)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  boschpanelDelete (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/api/boschpanel/${opts}`)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  bulkUpdatePanels (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .patch('/api/boschpanel/bulkupdate', opts)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  }
}
