export default function () {
  return {
    authenticated: false,
    loggedIn: {
      active: false,
      alarmsByBuilding: false,
      allOrganizations: false,
      bookmarks: [],
      feedbacks: [],
      branch: null,
      dashboardHomepage: false,
      email: '',
      favoriteOrgId: null,
      firstName: '',
      firstStatusEmail: null,
      id: '',
      isDay: false,
      lastName: '',
      mobilePhone: '',
      reenableTime: null,
      secondStatusEmail: null,
      statusEmailVersion: 0,
      thirdStatusEmail: null
    },
    orgIds: [],
    groupIds: [],
    systemKinds: [],
    permissions: {},
    updateLoggedIn: {
      active: false,
      reenableTime: null,
      alarmsByBuilding: false,
      email: '',
      firstName: '',
      lastName: '',
      mobilePhone: '',
      password: '',
      firstStatusEmail: null,
      secondStatusEmail: null,
      thirdStatusEmail: null,
      statusEmailVersion: 0
    },
    create: {
      alarmsByBuilding: true, //
      branch: null,
      email: null,
      firstName: null,
      firstStatusEmail: null,
      id: null,
      isDay: false,
      lastName: null,
      lastLogin: null,
      mobilePhone: null,
      secondStatusEmail: null,
      sendSetupEmail: true,
      serviceDivisions: null,
      statusEmailVersion: 0,
      systemKinds: null,
      thirdStatusEmail: null,
      userorgs: []
    },
    current: {
      id: '',
      email: '',
      branch: null,
      isDay: false,
      allOrganizations: false,
      alarmsByBuilding: false,
      active: false,
      firstName: '',
      lastName: '',
      mobilePhone: '',
      firstStatusEmail: null,
      secondStatusEmail: null,
      thirdStatusEmail: null,
      firstStatusSent: false,
      secondStatusSent: false,
      thirdStatusSent: false,
      statusEmailVersion: 0,
      emailLogs: [],
      groups: [],
      organizations: [],
      systemKinds: [],
      reenableTime: null,
      serviceDivisions: []
    },
    update: {
      id: null,
      email: '',
      branch: null,
      isDay: false,
      allOrganizations: false,
      alarmsByBuilding: false,
      active: false,
      firstName: '',
      lastName: '',
      mobilePhone: '',
      firstStatusEmail: null,
      firstStatusSent: false,
      secondStatusEmail: null,
      thirdStatusEmail: null,
      reenableTime: null,
      statusEmailVersion: 0,
      systemKinds: [],
      serviceDivisions: []
    },
    list: []
  }
}
