export default {
  PANEL_LIST (state, value) {
    state.list = (value && Array.isArray(value)) ? [...(value.sort((a, b) => a.name.localeCompare(b.name)))] : []
  },
  PANEL_LIST_ADD (state, value) {
    if (state.list.findIndex((i) => i.id === value.id) === -1) {
      state.list.push({
        id: value.id,
        name: value.name,
        organizations: value.organizations.length,
        users: value.users.length
      })
    }
  },
  PANEL_LIST_ITEM_REMOVE (state, value) {
    const id = typeof value === 'string' ? value : typeof value === 'object' && value.id ? value.id : ''
    const index = state.list.findIndex((i) => i.id === id)
    if (index !== -1) {
      state.list.splice(index, 1)
    }
  },
  PANEL_LIST_UPDATE (state, value) {
    const index = state.list.findIndex((i) => i.id === value.id)
    if (index !== -1) {
      state.list.splice(index, 1, Object.assign(state.list[index], value))
    } else {
      state.list.push(value)
    }
  },
  PANEL_CURRENT (state, value) {
    // need to make a more graceful update to minimize screen disruptions
    const props = [
      'dn',
      'ip',
      'installed',
      'name',
      'notes',
      'system'
    ]
    if (value) {
      for (const prop of props) {
        if (value[prop] !== state.current[prop]) {
          state.current[prop] = value[prop]
        }
      }
      for (const prop of ['id', 'active', 'alias', 'reenableTime', 'building']) {
        if (value[prop] !== state.current[prop]) {
          state.current[prop] = value[prop]
          state.update[prop] = value[prop]
        }
      }
    } else {
      state.current = {
        id: '',
        acmAppliance: '',
        active: false,
        alias: '',
        building: '',
        dn: '',
        ip: '',
        installed: false,
        name: '',
        notes: false,
        reenableTime: '',
        status: '',
        system: ''
      }
      state.update = {
        id: '',
        active: false,
        alias: ''
      }
    }
  },
  PANEL_CURRENT_HISTORY (state, value) {
    if (value && Object.keys(value).length > 0) {
      state.current.history = value
    } else {
      state.current.history = {}
    }
  },
  PANEL_CURRENT_NOTES (state, value) {
    state.current.notes = value
  },
  PANEL_UPDATE_ALIAS (state, value) {
    state.update.alias = value
  },
  PANEL_UPDATE_ACTIVE (state, value) {
    state.update.active = value
  },
  PANEL_UPDATE_HEALTH_CHECK_HISTORY (state, value) {
    if (state.current?.history && state.current.history[value.name]) {
      state.current.history[value.name] = value.history
    }
  },
  PANEL_UPDATE_REENABLE_TIME (state, value) {
    state.update.reenableTime = value
  },
  PANEL_UPDATE_BUILDING (state, value) {
    state.update.building = value
  }
}
