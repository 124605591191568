import axios from 'axios'

export default {
  // ==========================Socket Updates==============================
  encoderCreated (context, opts) {
    // do nothing
  },
  encoderUpdated (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    const inputs = { }
    if (opts && Array.isArray(opts.populate) && opts.populate.length > 0) {
      inputs.params = { }
      inputs.params.populate = opts.populate
    }
    const inList = context.state.list.find((i) => i.id === id)
    if (context.state.current.id === id || inList) {
      axios
        .get(`/api/encoder/${id}`, inputs)
        .then(({ data }) => {
          if (context.state.current.id === data.id) {
            data.socketUpdate = true
            context.commit('ENCODER_CURRENT', data)
          }
          if (inList) {
            context.commit('ENCODER_LIST_UPDATE', data)
          }
        }).catch(() => {
        })
    }
  },
  encoderDestroyed (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    if (id === context.state.current.id) {
      context.commit('ENCODER_CURRENT', null)
    }
    context.commit('ENCODER_LIST_ITEM_REMOVE', opts)
  },
  // ===========================List Edits===============================
  encoderAdd (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/encoder', opts)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  encoderDelete (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/api/encoder/${opts}`)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  // ======================================================================
  // ==========================Item Management=============================
  // ======================================================================
  encoderList (context, opts) {
    const params = opts ? `?parentType=${opts.parent}&parentId=${opts.id}` : ''
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/encoder${params}`)
        .then(({ data }) => {
          context.commit('ENCODER_LIST', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  encoderDetail (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/encoder/${opts}`)
        .then(({ data }) => {
          context.commit('ENCODER_CURRENT', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  encoderHistory (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/encoder/history', { params: { id: opts } })
        .then(({ data }) => {
          context.commit('ENCODER_CURRENT_HISTORY', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  encoderUpdate (context, opts) {
    const updates = Object.assign({}, context.state.update)
    for (const key of Object.keys(updates)) {
      if (updates[key] == null && key !== 'building') {
        delete updates[key]
      }
    }
    return new Promise((resolve, reject) => {
      axios
        .patch(`/api/encoder/${context.state.current.id}`, updates)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  encoderUpdateBuilding (context, opts) {
    const id = opts.id
    delete opts.id
    return new Promise((resolve, reject) => {
      axios
        .patch(`/api/encoder/${id}`, opts)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  bulkUpdateEncoders (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .patch('/api/encoder/bulkupdate', opts)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  }
}
