import axios from 'axios'

export default {
  // ======================================================================
  // ==========================List Management=============================
  // ======================================================================
  informacastdialcastList (context, opts) {
    if (!context.state.fetchingList) {
      context.commit('INFORMACASTDIALCAST_FETCHING_LIST', true)
      return new Promise((resolve, reject) => {
        axios
          .get('/api/informacastdialcast', { params: opts })
          .then(({ data }) => {
            context.commit('INFORMACASTDIALCAST_LIST', data)
            resolve(data)
          })
          .catch((error) => reject(error))
          .finally(() => context.commit('INFORMACASTDIALCAST_FETCHING_LIST', false))
      })
    }
  },
  // ==========================Socket Updates==============================
  informacastdialcastCreated (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    const inputs = { }
    if (opts && Array.isArray(opts.populate) && opts.populate.length > 0) {
      inputs.params = { }
      inputs.params.populate = opts.populate
    }
    axios
      .get(`/api/informacastdialcast/${id}`, inputs)
      .then(({ data }) => {
        data.socketUpdate = true
        context.commit('INFORMACASTDIALCAST_LIST_ADD', data)
      }).catch(() => {
      })
  },
  informacastdialcastUpdated (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    const inputs = { }
    if (opts && Array.isArray(opts.populate) && opts.populate.length > 0) {
      inputs.params = { }
      inputs.params.populate = opts.populate
    }
    const inList = context.state.list.find((i) => i.id === id)
    if (context.state.current.id === id || inList) {
      axios
        .get(`/api/informacastdialcast/${id}`, inputs)
        .then(({ data }) => {
          if (data.id === context.state.current.id) {
            context.commit('INFORMACASTDIALCAST_CURRENT', data)
          }
          if (inList) {
            context.commit('INFORMACASTDIALCAST_LIST_UPDATE', data)
          }
        }).catch(() => {
        })
    }
  },
  informacastdialcastDestroyed (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    if (id === context.state.current.id) {
      context.commit('INFORMACASTDIALCAST_CURRENT', null)
    }
    context.commit('INFORMACASTDIALCAST_LIST_ITEM_REMOVE', opts)
  },
  // ======================================================================
  // ==========================Item Management=============================
  // ======================================================================
  informacastdialcastDetail (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    const inputs = { }
    if (opts && Array.isArray(opts.populate) && opts.populate.length > 0) {
      inputs.params = { }
      inputs.params.populate = opts.populate
    }
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/informacastdialcast/${id}`, inputs)
        .then(({ data }) => {
          context.commit('INFORMACASTDIALCAST_CURRENT', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  informacastdialcastUpdate (context, opts) {
    const updates = Object.assign({}, context.state.update)
    for (const key of Object.keys(updates)) {
      if ((updates[key] === null || updates[key] === context.state.current[key]) && key !== 'id') {
        delete updates[key]
      }
    }
    return new Promise((resolve, reject) => {
      axios
        .patch(`/api/informacastdialcast/${context.state.current.id}`, updates)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  bulkUpdateInformacastDialcasts (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .patch('/api/informacastdialcast/bulkupdate', opts)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  }
}
