export default function () {
  return {
    current: {
      active: false,
      backupDiscovered: false,
      building: null,
      dataType: '',
      decimalPlaces: 2,
      healthChecks: [],
      history: {},
      hvacController: null,
      id: '',
      kind: '',
      name: '',
      notes: false,
      path: '',
      reenableTime: null,
      room: null,
      scale: 1,
      status: '',
      units: null,
      value: 0
    },
    list: [],
    update: {
      id: null,
      building: null,
      dataType: '',
      decimalPlaces: 2,
      room: null,
      name: null,
      path: '',
      kind: null,
      active: null,
      scale: 1,
      units: null,
      reenableTime: null
    },
    canInactivateForever: true
  }
}
