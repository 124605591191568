export default function () {
  return {
    current: {
      id: '',
      active: false,
      building: '',
      firmware: '',
      history: {},
      name: '',
      reenableTime: '',
      server: '',
      statusNumber: null,
      status: '',
      system: '',
      notes: false
    },
    update: {
      id: '',
      building: ''
    },
    list: [],
    canInactivateForever: true
  }
}
