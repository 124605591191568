export default function () {
  return {
    list: [],
    current: {
      id: '',
      activateFailureCount: null,
      activationDuration: null,
      active: false,
      authenticationErrors: null,
      broadcastDuration: null,
      deactivationDuration: null,
      gatheringDuration: null,
      idnsBroadcasted: null,
      masterTemplate: false,
      networkErrors: null,
      phonesBroadcasted: null,
      recipientsBroadcasted: null,
      reenableTime: null,
      speakersBroadcasted: null,
      system: null,
      unknownErrors: null
    },
    update: {
      masterTemplate: false
    }
  }
}
