import axios from 'axios'
export default {
  lockdownconfigList (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/lockdownConfig', { params: opts })
        .then(({ data }) => {
          context.commit('UPDATE_LOCKDOWN_CONFIG', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  }
}
