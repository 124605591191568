export default {
  COMFORT_LIST (state, value) {
    state.list = (value && Object.keys(value).length > 0) ? value : []
  },
  COMFORT_CURRENT (state, value) {
    const props = [
      'comfort', 'coolSetpoint', 'coolSetpoints', 'delta', 'heatSetpoint', 'highChildCount',
      'lowChildCount', 'maxChildComfort', 'maxChildDelta', 'minChildComfort', 'minChildDelta',
      'name', 'resourceId', 'space', 'time'
    ]
    for (const prop of props) {
      if (!value) {
        state.current[prop] = ''
      } else if (value[prop] !== state.current[prop]) {
        state.current[prop] = value[prop]
      }
    }
    state.current.history = value?.history ? value.history : []
  },
  COMFORT_HISTORY (state, value) {
    if (value && Object.keys(value).length > 0) {
      state.history = value
    } else {
      state.history = {}
    }
  }
}
