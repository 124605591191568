export default {
  SERVICEDIVISION_LIST (state, value) {
    state.list = (value && Array.isArray(value)) ? [...(value.sort((a, b) => a.name.localeCompare(b.name)))] : []
  },
  SERVICEDIVISION_LIST_ADD (state, value) {
    if (state.list.findIndex((i) => i.id === value.id) === -1) {
      state.list.push({
        id: value.id,
        name: value.name,
        manager: value.manager,
        systems: []
      })
    }
  },
  SERVICEDIVISION_LIST_REMOVE (state, value) {
    const index = state.list.findIndex((i) => i.id === value)
    if (index !== -1) {
      state.list.splice(index, 1)
    }
  },
  SERVICEDIVISION_LIST_UPDATE (state, value) {
    const index = state.list.findIndex((i) => i.id === value.id)
    if (index !== -1) {
      state.list.splice(index, 1, Object.assign(state.list[index], value))
    } else {
      state.list.push(value)
    }
  },
  SERVICEDIVISION_CURRENT (state, value) {
    // need to make a more graceful update to minimize screen disruptions
    if (value) {
      for (const prop of ['id', 'name', 'manager']) {
        if (value[prop] !== state.current[prop]) {
          state.current[prop] = value[prop]
          state.update[prop] = value[prop]
        }
      }
      state.current.users = value.users
      state.current.managers = value.managers
      state.current.systems = value.systems
    } else {
      state.current = {
        id: '',
        name: '',
        manager: {},
        managers: [],
        systems: []
      }
      state.update = {
        id: '',
        name: '',
        manager: {}
      }
    }
  },
  SERVICEDIVISION_UPDATE_NAME (state, value) {
    state.update.name = value
  },
  SERVICEDIVISION_UPDATE_MANAGER (state, value) {
    state.update.manager = value
  },
  SERVICEDIVISION_UPDATE_USERS (state, value) {
    const id = typeof value === 'string' ? value : typeof value === 'object' && value.id ? value.id : ''
    const index = state.current.users.findIndex((i) => i.id === id)
    if (index !== -1) {
      state.current.users.splice(index, 1)
    }
  }
}
