import axios from 'axios'

export default {
  // ==========================Socket Updates==============================
  budgetCreated (context, opts) {
    // do nothing
  },
  budgetList (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/budget', { params: opts })
        .then(({ data }) => {
          context.commit('BUDGET_LIST', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  budgetUpdated (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    const inputs = { }
    if (opts && Array.isArray(opts.populate) && opts.populate.length > 0) {
      inputs.params = { }
      inputs.params.populate = opts.populate
    }
    const inList = context.state.list.find((i) => i.id === id)
    if (context.state.current.id === id || inList) {
      axios
        .get(`/api/budget/${id}`, inputs)
        .then(({ data }) => {
          if (data.id) {
            context.commit('BUDGET_CURRENT', data)
          }
          if (inList) {
            context.commit('BUDGET_LIST_UPDATE', data)
          }
        }).catch(() => {
        })
    }
  },
  budgetDestroyed (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    if (id === context.state.current.id) {
      context.commit('BUDGET_CURRENT', null)
    }
  },
  // ===========================List Edits=====================
  budgetAdd (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/budget', opts)
        .then(({ data }) => {
          context.commit('BUDGET_CURRENT', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  budgetBulkAdd (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/budget/bulk-create', opts)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  budgetDelete (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/api/budget/${opts}`)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  // ======================================================================
  // ==========================Item Management=============================
  // ======================================================================
  budgetDetail (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/budget/${id}`)
        .then(({ data }) => {
          context.commit('BUDGET_CURRENT', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  budgetUpdate (context, opts) {
    const updates = Object.assign({}, context.state.update)
    for (const key of Object.keys(updates)) {
      if (updates[key] == null) {
        delete updates[key]
      }
    }
    return new Promise((resolve, reject) => {
      axios
        .patch(`/api/budget/${context.state.current.id}`, updates)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  }
}
