export default {
  BUILDING_CREATE_NAME (state, value) {
    state.create.name = value
  },
  BUILDING_CREATE_ID (state, value) {
    state.create.id = value
  },
  BUILDING_CREATE_RESET (state, value) {
    state.create.name = value
    state.create.id = value
  },
  BUILDING_CURRENT (state, value) {
    if (value) {
      state.current.organization = value.organization
      state.current.alarms = value.alarms || []
      state.current.id = value.id ? value.id : ''
      for (const prop of ['name', 'active', 'reenableTime', 'acronym', 'alias']) {
        if (value[prop] !== state.current[prop]) {
          state.current[prop] = value[prop]
        }
        if (value[prop] !== state.update[prop]) {
          state.update[prop] = value[prop]
        }
      }
    } else {
      state.current = {
        id: '',
        acronym: '',
        alias: '',
        name: '',
        organization: null,
        reenableTime: null,
        active: false
      }
    }
  },
  BUILDING_LIST_UPDATE (state, value) {
    const index = state.list.findIndex((i) => i.id === value.id)
    if (index !== -1) {
      state.list.splice(index, 1, Object.assign(state.list[index], value))
    } else {
      state.list.push(Object.assign({}, value)) // reason: see fetchModel.js
    }
  },
  BUILDING_LIST (state, value) {
    state.list = (value && Array.isArray(value)) ? [...(value.sort((a, b) => a.name.localeCompare(b.name)))] : []
  },
  BUILDING_LIST_ITEM_REMOVE (state, value) {
    const id = typeof value === 'string' ? value : typeof value === 'object' && value.id ? value.id : ''
    const index = state.list.findIndex(c => c.id === id)
    if (index > -1) {
      state.list.splice(index, 1)
    }
  },
  BUILDING_UPDATE_NAME (state, value) {
    state.update.name = value
  },
  BUILDING_UPDATE_ACRONYM (state, value) {
    state.update.acronym = value
  },
  BUILDING_UPDATE_ALIAS (state, value) {
    state.update.alias = value
  },
  BUILDING_UPDATE_REENABLE_TIME (state, value) {
    state.update.reenableTime = value
  },
  BUILDING_UPDATE_ACTIVE (state, value) {
    state.update.active = value
  }
}
