export default {
  POINT_LIST (state, value) {
    state.list = (value && Array.isArray(value)) ? value : []
  },
  POINT_LIST_UPDATE (state, value) {
    const index = state.list.findIndex((i) => i.id === value.id)
    if (index !== -1) {
      state.list.splice(index, 1, Object.assign(state.list[index], value)) // keep old values such as status
    } else {
      state.list.push(value) // see fetchModel.js
    }
  },
  POINT_LIST_REMOVE (state, value) {
    const index = state.list.findIndex((i) => i.id === value)
    if (index !== -1) {
      state.list.splice(index, 1) // keep old values such as status
    }
  },
  POINT_CURRENT_HISTORY (state, value) {
    if (value && Object.keys(value).length > 0) {
      state.current.history = value
    } else {
      state.current.history = {}
    }
  },
  POINT_CURRENT (state, value) {
    if (value) {
      for (const prop of ['id', 'building', 'dataType', 'decimalPlaces', 'room', 'name', 'path', 'kind', 'active', 'scale', 'units', 'reenableTime']) {
        if (value[prop] !== state.current[prop]) {
          state.current[prop] = value[prop]
          state.update[prop] = value[prop]
        }
      }
      for (const prop of ['value', 'status', 'hvacController', 'backupDiscovered', 'notes']) {
        if (value[prop] !== state.current[prop]) {
          state.current[prop] = value[prop]
        }
      }
      if (!value.socketUpdate) {
        state.current.history = value.history
      }
    } else {
      state.current = {
        id: '',
        building: null,
        dataType: '',
        decimalPlaces: 2,
        room: null,
        name: '',
        path: '',
        kind: '',
        active: false,
        value: 0,
        status: '',
        scale: 1,
        units: '',
        reenableTime: null,
        hvacController: null,
        history: {},
        healthChecks: [],
        notes: false
      }
      state.update = {
        id: null,
        building: null,
        dataType: '',
        decimalPlaces: 2,
        room: null,
        name: null,
        path: '',
        kind: null,
        active: false,
        scale: 1,
        units: null,
        reenableTime: null
      }
    }
  },
  POINT_CURRENT_NOTES (state, value) {
    state.current.notes = value
  },
  POINT_CURRENT_VALUE (state, value) {
    state.current.value = value
  },
  POINT_UPDATE_BUILDING (state, value) {
    state.update.building = value
  },
  POINT_UPDATE_DATA_TYPE (state, value) {
    state.update.dataType = value
  },
  POINT_UPDATE_DECIMAL_PLACES (state, value) {
    state.update.decimalPlaces = value
  },
  POINT_UPDATE_ROOM (state, value) {
    state.update.room = value
  },
  POINT_UPDATE_NAME (state, value) {
    state.update.name = value
  },
  POINT_UPDATE_PATH (state, value) {
    state.update.path = value
  },
  POINT_UPDATE_KIND (state, value) {
    state.update.kind = value
  },
  POINT_UPDATE_HEALTH_CHECK_HISTORY (state, value) {
    if (state.current?.history && state.current.history[value.name]) {
      state.current.history[value.name] = value.history
    }
  },
  POINT_CURRENT_VALUE_HISTORY (state, value) {
    if (state.current?.history?.Value) {
      state.current.history.Value = value
    }
  },
  POINT_UPDATE_REENABLE_TIME (state, value) {
    state.update.reenableTime = value
  },
  POINT_UPDATE_ACTIVE (state, value) {
    state.update.active = value
  },
  POINT_UPDATE_SCALE (state, value) {
    state.update.scale = value
  },
  POINT_UPDATE_UNITS (state, value) {
    state.update.units = value
  }
}
