import { register } from 'register-service-worker'
import store from '../src/store/index'

// The ready(), registered(), cached(), updatefound() and updated()
// events passes a ServiceWorkerRegistration instance in their arguments.
// ServiceWorkerRegistration: https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerRegistration

let refreshing = false
if (navigator && navigator.serviceWorker) {
  navigator.serviceWorker.addEventListener('controllerchange',
    function () {
      if (!refreshing) {
        refreshing = true
        window.location.reload()
      }
    }
  )
}

register(process.env.SERVICE_WORKER_FILE, {
  // The registrationOptions object will be passed as the second argument
  // to ServiceWorkerContainer.register()
  // https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerContainer/register#Parameter

  // registrationOptions: { scope: './' },

  ready () {
    console.log('App is being served from cache by a service worker.')
  },

  registered (registration) {
    console.log('Service worker has been registered.')
    const check = () => {
      if (registration) {
        console.log('checking...')
        registration.update()
        setTimeout(check, 1000 * 60 * 60)
      }
    }
    if (registration) {
      check()
    }
  },

  cached (registration) {
    console.log('Content has been cached for offline use.')
  },

  updatefound (registration) {
    console.log('New content is downloading.')
  },

  updated (registration) {
    store().commit('REGISTRATION', registration)
    store().commit('UPDATE_AVAILABLE', true)
    console.log('New content is available; please refresh.')
  },

  offline () {
    console.log('No internet connection found. App is running in offline mode.')
  },

  error (err) {
    console.error('Error during service worker registration:', err)
  }
})
