const addBlanks = (missing) => {
  const updates = []
  const container = { name: 'x1x1', occupied: false, rowspan: 1, colspan: 1, widgets: [] }
  const widget = { colspan: 1, meta: {}, name: 'blank', rowspan: 1 }
  container.widgets = [...Array(parseInt(3))].map((i) => Object.assign({}, widget))
  for (let int = 0; int < missing; int++) {
    updates.push(Object.assign({}, container))
  }
  return updates
}

export default {
  DASHBOARD_LIST (state, value) {
    state.list = (value && Array.isArray(value)) ? [...(value.sort((a, b) => a.name.localeCompare(b.name)))] : []
  },
  DASHBOARD_LIST_ADD (state, value) {
    if (value && value.id) {
      state.list.push(value)
      state.list.sort((a, b) => {
        if (a.name > b.name) {
          return 1
        } else if (b.name > a.name) {
          return -1
        } else {
          return 0
        }
      })
    }
  },
  DASHBOARD_LIST_UPDATE (state, value) {
    if (value && value.id) {
      const index = state.list.findIndex(d => d.id === value.id)
      if (index > -1) {
        state.list.splice(index, 1, Object.assign(state.list[index], value))
        state.list.sort((a, b) => {
          if (a.name > b.name) {
            return 1
          } else if (b.name > a.name) {
            return -1
          } else {
            return 0
          }
        })
      }
    }
  },
  DASHBOARD_LIST_REMOVE (state, value) {
    const index = state.list.findIndex(d => d.id === value)
    if (index > -1) {
      state.list.splice(index, 1)
    }
  },
  DASHBOARD_RESET_TO_BLANK_CONTAINER (state, value) {
    if (!isNaN(value.index) && value.index >= 0 && value.reset) {
      const currentColspan = state.update.containers[value.index].colspan
      const container = { name: 'x1x1', occupied: false, rowspan: 1, colspan: 1, widgets: [] }
      const widget = { colspan: 1, meta: {}, name: 'blank', rowspan: 1 }
      container.widgets = [...Array(parseInt(3))].map((i) => Object.assign({}, widget))
      state.update.containers.splice(value.index, 1, container)
      for (let int = 1; int < currentColspan; int++) {
        state.update.containers.splice((value.index + int), 0, Object.assign({}, container))
      }
    }
  },
  DASHBOARD_UPDATE_CONTAINER_BORDER (state, value) {
    if (state.update.containers[value.index] && value.hideBorder !== null && typeof value.hideBorder !== 'undefined') {
      state.update.containers[value.index].hideBorder = value.hideBorder
    }
  },
  DASHBOARD_UPDATE_CONTAINER (state, value) {
    if (state.update.containers[value.index]) { // vue is only watching containers
      const index = typeof value.from !== 'undefined' ? value.from : value.index
      const deepCopy = JSON.parse(JSON.stringify(state.update.containers[index]))
      if (value.name) {
        deepCopy.name = value.name
      }
      if (value.occupied !== null && typeof value.occupied !== 'undefined') {
        deepCopy.occupied = value.occupied
      }
      deepCopy.colspan = value.colspan !== null && typeof value.colspan !== 'undefined' ? value.colspan : 1
      const hasTitle = (value.title && value.title !== '' && value.title !== 'Title Bar')
      if (hasTitle) {
        deepCopy.title = value.title
      } else { delete deepCopy.title }
      if (hasTitle && value.titleBgColor && value.titleBgColor !== '') {
        deepCopy.titleBgColor = value.titleBgColor
      } else { delete deepCopy.titleBgColor }
      if (hasTitle && value.titleFontColor && value.titleFontColor !== '') {
        deepCopy.titleFontColor = value.titleFontColor
      } else { delete deepCopy.titleFontColor }
      if (hasTitle && value.titleFontSize && value.titleFontSize !== '') {
        deepCopy.titleFontSize = value.titleFontSize
      } else { delete deepCopy.titleFontSize }
      if (value.containerBgColor && value.containerBgColor !== '') {
        deepCopy.containerBgColor = value.containerBgColor
      } else { delete deepCopy.containerBgColor }

      const columnNewDiff = state.update.containers[value.index].colspan - deepCopy.colspan
      let mutableFrom = value.from
      state.update.containers.splice(value.index, 1, deepCopy)

      // Update where it's going
      if (columnNewDiff > 0) {
        for (let int = 1; int <= columnNewDiff; int++) {
          const single = addBlanks(1)
          if (!isNaN(value.from)) {
            mutableFrom++
          }
          state.update.containers.splice((value.index + int), 0, single[0])
        }
      } else if (columnNewDiff < 0) { // include templates which have no value.from
        const remove = value.index + Math.abs(columnNewDiff)
        for (let int = remove; int >= (value.index + 1); int--) {
          if (state.update.containers[int]?.occupied === false || int === value.from) { // issue is here
            if (!isNaN(value.from) && value.index < value.from && int !== value.from) {
              mutableFrom--
            }
            if (int === value.from) {
              const single = addBlanks(1)
              state.update.containers.splice(int, 1, single[0])
            } else {
              state.update.containers.splice(int, 1)
            }
          }
        }
      }
      // update where it's been
      if (!isNaN(value.from)) {
        const currentColspan = state.update.containers[mutableFrom].colspan
        const container = { name: 'x1x1', occupied: false, rowspan: 1, colspan: 1, widgets: [] }
        const widget = { colspan: 1, meta: {}, name: 'blank', rowspan: 1 }
        container.widgets = [...Array(parseInt(3))].map((i) => Object.assign({}, widget))
        state.update.containers.splice(mutableFrom, 1, container)
        for (let int = 1; int < currentColspan; int++) {
          state.update.containers.splice((mutableFrom + int), 0, Object.assign({}, container))
        }
      }
    }
  },
  DASHBOARD_UPDATE_CONTAINER_WIDGET (state, value) {
    if (state.update.containers[value.containerIndex]) { // vue is only watching containers
      const deepCopy = JSON.parse(JSON.stringify(state.update.containers[value.containerIndex]))
      if (deepCopy.widgets[value.widgetIndex]) {
        deepCopy.widgets[value.widgetIndex].name = value.name
        deepCopy.widgets[value.widgetIndex].rowspan = value.rowspan || 1
        deepCopy.widgets[value.widgetIndex].colspan = value.colspan || 1
        deepCopy.widgets[value.widgetIndex].meta = value.meta ? value.meta : {}
      }
      let count = 0
      for (const w of deepCopy.widgets) {
        if (w.name !== 'blank' && w.rowspan && w.colspan) {
          count += (w.rowspan * w.colspan)
        } else { ++count }
      }
      if (count >= deepCopy.maxWidgets) {
        const counter = (count - deepCopy.maxWidgets)
        for (let int = 0; int < counter; int++) {
          const lstIndex = deepCopy.widgets.map(w => w.name).lastIndexOf('blank')
          if (lstIndex !== -1) {
            deepCopy.widgets.splice(lstIndex, 1)
          }
        }
      } else if (count < deepCopy.maxWidgets) {
        const blank = { name: 'blank', rowspan: 1, colspan: 1, meta: {} }
        const counter = (deepCopy.maxWidgets - count)
        for (let int = 1; int <= counter; int++) {
          deepCopy.widgets.splice((value.widgetIndex + int), 0, blank)
        }
      }
      state.update.containers.splice(value.containerIndex, 1, deepCopy)
    }
  },
  DASHBOARD_ADD_CONTAINER_WIDGETS (state, value) {
    if (state.update.containers[value.containerIndex]) { // vue is only watching containers
      const deepCopy = JSON.parse(JSON.stringify(state.update.containers[value.containerIndex]))
      if (deepCopy) {
        deepCopy.maxWidgets = parseInt(value.widgetCount)
        deepCopy.widgets = [...Array(parseInt(value.widgetCount))].map((i) => Object.assign({}, { name: 'blank', meta: {} }))
        state.update.containers.splice(value.containerIndex, 1, deepCopy)
      }
    }
  },
  DASHBOARD_UPDATE_CONTAINER_WIDGET_META (state, value) {
    if (state.update.containers[value.containerIndex]) { // vue is only watching containers
      const deepCopy = JSON.parse(JSON.stringify(state.update.containers[value.containerIndex]))
      if (deepCopy.widgets[value.widgetIndex]) {
        deepCopy.widgets[value.widgetIndex].meta = value.meta
      }
      state.update.containers.splice(value.containerIndex, 1, deepCopy)
    }
  },
  DASHBOARD_CONTAINER_RESET (state, value) {
    const missing = state.defaultContainerCount - state.current.containers.length
    if (missing > 0) { // skip on socketupdate
      state.update.containers = [...state.current.containers, ...addBlanks(missing)]
    } else {
      state.update.containers = [...state.current.containers]
    }
    for (const key of Object.keys(state.current)) {
      if (key !== 'containers') {
        state.update[key] = state.current[key]
      }
    }
  },
  DASHBOARD_CURRENT (state, value) {
    if (value) {
      state.dirty = false
      for (const prop of ['id', 'name', 'share', 'organization', 'viewStyle']) {
        if (typeof value[prop] !== 'undefined' && value[prop] !== state.current[prop]) {
          state.current[prop] = value[prop]
          state.update[prop] = value[prop]
        }
      }
      state.current.containers = value?.containers ? value.containers : []
      if (!value.socketUpdate) {
        if (state.current.containers.length < state.defaultContainerCount) {
          const missing = state.defaultContainerCount - state.current.containers.length
          state.update.containers = [...state.current.containers, ...addBlanks(missing)]
        } else {
          state.update.containers = [...state.current.containers]
        }
      }
    } else {
      state.update.id = null
      state.update.name = ''
      state.update.organization = ''
      state.update.viewStyle = 'mobile'
      state.update.share = false
      state.update.containers = []
      state.current.id = null
      state.current.name = ''
      state.current.organization = ''
      state.current.viewStyle = 'mobile'
      state.current.share = false
      state.current.containers = []
    }
  },
  DASHBOARD_UPDATE_NAME (state, value) {
    state.update.name = value
  },
  DASHBOARD_UPDATE_VIEW_STYLE (state, value) {
    state.update.viewStyle = value
  },
  DASHBOARD_UPDATE_CONTAINERS (state, value) {
    state.update.containers = value
  },
  DASHBOARD_UPDATE_VALUES (state, value) { // { pointId: value, ... }
    const data = JSON.parse(state.update.data)
    const points = Object.keys(value)
    const descend = (parent) => {
      if (parent.children) {
        for (const child of parent.children) {
          if (child.meta && child.meta.point && points.includes(child.meta.point)) {
            child.meta.value = value[child.meta.point].value
            child.meta.status = value[child.meta.point].status
            child.meta.time = value[child.meta.point].time
          }
          if (child.meta && child.meta.points) {
            try {
              const newPoints = JSON.parse(child.meta.points)
              for (const point of newPoints) {
                if (points.includes(point.id)) {
                  point.value = value[point.id].value
                  point.status = value[point.id].status
                  point.time = value[point.id].time
                }
              }
              child.meta.points = JSON.stringify(newPoints)
            } catch (e) {
            }
          }
          if (child.children) {
            descend(child)
          }
        }
      }
    }
    descend(data)
    state.update.data = JSON.stringify(data)
  },
  DASHBOARD_UPDATE_ADD_BLANK_CONTAINER (state, value) {
    const single = addBlanks(1)
    state.update.containers.push(single[0])
  }
}
