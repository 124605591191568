import axios from 'axios'

export default {
  // ======================================================================
  // ==========================List Management=============================
  // ======================================================================
  doorList (context, opts) {
    if (!context.state.fetchingList) { // the same widget multiple times could call this. see open_door_count.vue
      context.commit('DOOR_FETCHING_LIST', true)
      return new Promise((resolve, reject) => {
        axios
          .get('/api/door', { params: opts })
          .then(({ data }) => {
            context.commit('DOOR_LIST', data)
            resolve(data)
          })
          .catch((error) => reject(error))
          .finally(() => context.commit('DOOR_FETCHING_LIST', false))
      })
    }
  },
  // ==========================Socket Updates==============================
  doorCreated (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    const inputs = { }
    if (opts && Array.isArray(opts.populate) && opts.populate.length > 0) {
      inputs.params = { }
      inputs.params.populate = opts.populate
    }
    axios
      .get(`/api/door/${id}`, inputs)
      .then(({ data }) => {
        data.socketUpdate = true
        context.commit('DOOR_LIST_ADD', data)
      }).catch(() => {
      })
  },
  doorUpdated (context, opts) {
    const current = (context.rootState.Organization.current.id === opts.organization)
    if (opts.socket && current) {
      context.commit('DOOR_SOCKET_UPDATE', new Date())
    } else {
      const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
      const inputs = { }
      if (opts && Array.isArray(opts.populate) && opts.populate.length > 0) {
        inputs.params = { }
        inputs.params.populate = opts.populate
      }
      const inList = context.state.list.find((i) => i.id === id)
      if (context.state.current.id === id || inList) {
        axios
          .get(`/api/door/${id}`, inputs)
          .then(({ data }) => {
            if (data.id === context.state.current.id) {
              context.commit('DOOR_CURRENT', data)
            }
            if (inList) {
              context.commit('DOOR_LIST_UPDATE', data)
            }
          }).catch(() => {
          })
      }
    }
  },
  doorDestroyed (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    if (id === context.state.current.id) {
      context.commit('DOOR_CURRENT', null)
    }
    context.commit('DOOR_LIST_ITEM_REMOVE', opts)
  },
  // ======================================================================
  // ==========================Item Management=============================
  // ======================================================================
  doorDetail (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    const inputs = { }
    if (opts && Array.isArray(opts.populate) && opts.populate.length > 0) {
      inputs.params = { }
      inputs.params.populate = opts.populate
    }
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/door/${id}`, inputs)
        .then(({ data }) => {
          context.commit('DOOR_CURRENT', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  doorUpdate (context, opts) {
    const updates = Object.assign({}, context.state.update)
    for (const key of Object.keys(updates)) {
      if ((updates[key] === null || updates[key] === context.state.current[key]) && key !== 'id') {
        delete updates[key]
      }
    }
    return new Promise((resolve, reject) => {
      axios
        .patch(`/api/door/${context.state.current.id}`, updates)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  doorHistory (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/door/history', { params: { id: opts } })
        .then(({ data }) => {
          context.commit('DOOR_CURRENT_HISTORY', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  bulkUpdateDoors (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .patch('/api/door/bulkupdate', opts)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  doorPositionSubscribe (context, opts) {
    return new Promise((resolve, reject) => {
      const currentOrg = context.rootState.Organization.current.id
      const opts = currentOrg && currentOrg !== '' ? { organization: currentOrg } : {}
      axios
        .post('/api/door/position', opts)
        .then(async ({ data }) => {
          resolve(data)
        }).catch((error) => {
          reject(error)
        })
    })
  }
}
