import axios from 'axios'

export default {
  fileDelete (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/api/fileupload/${opts}`)
        .then(({ data }) => {
          context.commit('FILE_LIST_REMOVE', opts)
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  fileList (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/fileupload', { params: { category: opts } })
        .then(({ data }) => {
          context.commit('FILE_LIST', data)
          resolve(data)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  fileUpload (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/fileupload', { category: opts.category, name: opts.name })
        .then(({ data }) => {
          const result = data.downloadUrl
          axios.put(data.uploadUrl, opts.file, { contentType: data.contentType }).then(({ data }) => {
            resolve(result)
          })
        }).catch((error) => reject(error))
    })
  },
  fileUpdate (context, opts) {
    if (opts.id && Object.keys(opts).length > 1) {
      const id = opts.id
      delete opts.id
      return new Promise((resolve, reject) => {
        axios
          .patch(`/api/fileupload/${id}`, opts)
          .then(({ data }) => {
            resolve(data)
          }).catch((error) => reject(error))
      })
    }
  },
  fileUploadUrl (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/fileupload', { category: opts.category, name: opts.name })
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  }
}
