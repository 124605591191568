export default {
  SOCKET_IO (state, value) {
    if (!state.io) {
      state.io = value
    }
  },
  SOCKET_ENROLLED (state, value) {
    state.enrolled = value
  },
  SOCKET_ENROLLING (state, value) {
    state.enrolling = value
  },
  SOCKET_OFFLINE (state, value) {
    state.offline = value
  },
  SOCKET_INHIBIT_UPDATE (state, value) {
    state.inhibitUpdate = value
  },
  SOCKET_INITIAL_CONNECTION (state, value) {
    state.initialConnection = value
  }
}
