export default function () {
  return {
    current: {
      id: '',
      boschPanel: '',
      active: false,
      configured: false,
      position: null,
      history: {},
      name: '',
      reenableTime: '',
      status: '',
      statusNumber: null,
      notes: false
    },
    update: {
      id: '',
      active: false,
      reenableTime: ''
    },
    list: [],
    canInactivateForever: true
  }
}
