export default function () {
  return {
    current: {
      dayDefault: false,
      description: '',
      id: '',
      name: '',
      permissions: [],
      rank: 10,
      userDefault: false
    },
    update: {
      dayDefault: false,
      description: '',
      id: '',
      name: '',
      rank: 10,
      userDefault: false
    },
    list: []
  }
}
