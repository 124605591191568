import axios from 'axios'
export default {
  alarmList (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/alarm', { params: opts })
        .then(({ data }) => {
          context.commit('ALARMS', data) // this will update the left side drawer
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  alarmCounts (context, opts) {
    if (opts.id) {
      delete opts.id
    }
    if (opts.from) {
      delete opts.from
    }
    return new Promise((resolve, reject) => {
      axios
        .get('/api/alarm/counts', { params: opts })
        .then(({ data }) => {
          context.commit('COUNTS', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  alarmCountsBySystem (context, opts) {
    if (opts.kind) {
      return new Promise((resolve, reject) => {
        const kind = opts.kind
        axios
          .get('/api/alarm/counts', { params: opts })
          .then(({ data }) => {
            context.commit('UPDATE_SYSTEM_COUNTS', { kind, data })
            resolve(true)
          }).catch(() => {
            reject(true)
          })
      })
    }
  }
}
