export default function () {
  return {
    current: {
      description: '',
      deviceIdentifier: '',
      endpointId: '',
      icopId: '',
      id: '',
      ipAddress: '',
      isLicensed: '',
      name: '',
      reenableTime: '',
      system: '',
      type: '',
      vendorId: ''
    },
    list: []
  }
}
