import axios from 'axios'

export default {
  permissionList (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/permission')
        .then(({ data }) => {
          const permissions = data.reduce((res, perm) => {
            if (!res.models[perm.model]) {
              res.models[perm.model] = {}
            }
            if (!res.actions[perm.action]) {
              res.actions[perm.action] = {}
            }
            res.models[perm.model][perm.action] = perm.id
            return res
          }, { models: {}, actions: {} })
          context.commit('PERMISSION_ACTIONS', Object.keys(permissions.actions))
          context.commit('PERMISSION_MODELS', permissions.models)
          resolve(data)
        }).catch((error) => {
          context.commit('PERMISSION_ACTIONS', [])
          context.commit('PERMISSION_MODELS', [])
          reject(error)
        })
    })
  }
}
