export default function () {
  return {
    current: {
      id: '',
      active: false,
      alarms: [],
      budgets: [],
      cameras: [],
      childHealthChecks: [],
      childPoints: [],
      encoders: [],
      energyUsage: {},
      hvacControllers: [],
      hvacSubControllers: [],
      healthChecks: [],
      healthCheckKinds: [],
      name: '',
      organization: null,
      points: [],
      reenableTime: null,
      rooms: [],
      servers: [],
      tags: []
    },
    update: {
      alias: '',
      acronym: '',
      active: false,
      name: '',
      reenableTime: null
    },
    create: {
      name: null
    },
    list: [],
    canInactivateForever: false
  }
}
