import axios from 'axios'

export default {
  accessgroupList (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/accessgroup', { params: opts })
        .then(({ data }) => {
          context.commit('ACCESS_GROUP_LIST', data)
          resolve(data)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  // ===========================Socket Updates=====================
  accessgroupUpdated (context, opts) {
    const id = opts && opts.id ? opts.id : opts
    const populated = opts && opts.populate ? opts.populate : []
    const inList = context.state.list.find((i) => i.id === id)
    if (inList) {
      axios
        .get(`/api/accessgroup/${id}`, { params: populated })
        .then(({ data }) => {
          if (data.id) {
            data.socketUpdate = true
            context.commit('ACCESS_GROUP_LIST_UPDATE', data)
          }
        }).catch(() => {})
    }
  },
  accessgroupDestroyed (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    const inList = context.state.list.find((i) => i.id === id)
    if (id === context.state.current.id) {
      context.commit('ACCESS_GROUP_CURRENT', null)
    }
    if (inList) {
      context.commit('ACCESS_GROUP_LIST_REMOVE', id)
    }
  }
}
