import axios from 'axios'
export default {
  // ======================================================================
  // ==========================List Management=============================
  // ======================================================================
  // TODO: keep org list current all the time
  organizationList (context, opts) {
    let noVuex = false
    if (opts && opts.noVuex) {
      noVuex = true
      delete opts.noVuex
    }
    const params = opts && Object.keys(opts).length > 0 ? opts : {}
    return new Promise((resolve, reject) => {
      axios
        .get('/api/organization', { params })
        .then(({ data }) => {
          if (!noVuex) {
            context.commit('ORGANIZATION_LIST', data)
          }
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  /** Possible not used */
  // organizationHealth (context, opts) {
  //   return new Promise((resolve, reject) => {
  //     axios
  //       .get('/api/organization/health', { params: opts })
  //       .then(({ data }) => {
  //         context.commit('ORGANIZATION_HEALTH', data)
  //         context.commit('SYSTEM_RESOURCE_LOGS', data.resourceLogs) // this will update the left side drawer
  //         resolve(data)
  //       }).catch((error) => reject(error))
  //   })
  // },
  // organizationHealthUpdate (context, opts) {
  //   return new Promise((resolve, reject) => {
  //     axios
  //       .get('/api/organization/health', { params: { noHistory: true } })
  //       .then(({ data }) => {
  //         context.commit('ORGANIZATION_HEALTH', data)
  //         context.commit('SYSTEM_RESOURCE_LOGS_UPDATE', data.resourceLogs) // this will update the left side drawer
  //         resolve(data)
  //       }).catch((error) => reject(error))
  //   })
  // },
  organizationVersionHistory (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/organization/versionhistory', { params: opts })
        .then(({ data }) => {
          context.commit('ORGANIZATION_CURRENT_VERSION_HISTORY', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  organizationLastUpdatedBy (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/organization/lastupdatedby', {})
        .then(({ data }) => {
          context.commit('ORGANIZATION_LAST_UPDATED_BY', data)
          resolve()
        }).catch((error) => reject(error))
    })
  },
  // ==========================Socket Updates==============================
  organizationCreated (context, opts) {
    // do nothing. see userorg.actions
  },
  organizationlogUpdated (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    if (context.state.current.id === id) {
      context.commit('ORGANIZATION_CURRENT_LAST_LOG', opts.log)
    }
  },
  organizationUpdated (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    // const backgroundUpdate = opts && opts.background ? opts.background : false
    const inputs = { }
    if (opts && Array.isArray(opts.populate) && opts.populate.length > 0) {
      inputs.params = { }
      inputs.params.populate = opts.populate
    }
    const inList = context.state.list.find((i) => i.id === id)
    if (context.state.current.id === id || inList) {
      axios
        .get(`/api/organization/${id}`, inputs)
        .then(({ data }) => {
          if (data.id) {
            if (data.id === context.state.current.id) {
              data.socketUpdate = true
              context.commit('ORGANIZATION_CURRENT', data)
              // if (!backgroundUpdate) {
              //   setChildLists(context, data, false)
              // }
            }
            if (inList) {
              context.commit('ORGANIZATION_LIST_UPDATE', data)
            }
          }
        }).catch(() => {
        })
    }
    // context.dispatch('organizationHealth')
  },
  organizationChildUpdate (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/api/${opts.kind}/${opts.id}`, opts.data)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  organizationDestroyed (context, opts) {
    // always try to remove from list
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    if (id === context.state.current.id) {
      context.commit('ORGANIZATION_CURRENT', null)
    }
    context.commit('ORGANIZATION_LIST_ITEM_REMOVE', opts)
  },
  // ===========================List Edits===============================
  organizationAdd (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/organization', opts)
        .then(({ data }) => {
          context.commit('ORGANIZATION_LIST_ADD', data)
          resolve(data)
        }).catch((error) => {
          if (error.response && error.response.data && error.response.data.message) {
            reject(error.response.data)
          } else {
            reject(error)
          }
        })
    })
  },
  organizationDelete (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/api/organization/${opts}`)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => {
          if (error.response && error.response.data && error.response.data.message) {
            reject(error.response.data)
          } else {
            reject(error)
          }
        })
    })
  },
  // ========================== Child Lists ===============================
  // organizationServers (context, opts) {
  //   return new Promise((resolve, reject) => {
  //     axios
  //       .get(`/api/server`, { params: opts })
  //       .then(({ data }) => {
  //         context.commit('SERVER_LIST', data)
  //         resolve(data)
  //       }).catch((error) => reject(error))
  //   })
  // },
  organizationUsers (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/user', { params: opts })
        .then(({ data }) => {
          context.commit('ORGANIZATION_CURRENT_USERS', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  organizationTags (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/tag', { params: opts })
        .then(({ data }) => {
          context.commit('ORGANIZATION_CURRENT_TAGS', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  organizationSystemEvents (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/organization/system-events', { params: opts }) // {organization: <id>, system: <kind> }
        .then(({ data }) => {
          context.commit('ORGANIZATION_CURRENT_SYSTEM_EVENTS', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  // ==========================Item Management=============================
  // ======================================================================
  organizationDetail (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    // const changed = (context.state.current.id !== id)
    const inputs = { }
    if (opts && Array.isArray(opts.populate) && opts.populate.length > 0) {
      inputs.params = { }
      inputs.params.populate = opts.populate
    }
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/organization/${id}`, inputs)
        .then(({ data }) => {
          context.commit('ORGANIZATION_LIST_UPDATE', data)
          context.commit('ORGANIZATION_CURRENT', data)
          // if (!opts.overrideFetch) {
          //   setChildLists(context, data, changed)
          // }
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  organizationEnergyDetail (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/organization/energy')
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  organizationUpdate (context, opts) {
    return new Promise((resolve, reject) => {
      const updates = Object.assign({}, context.state.update)
      for (const key of Object.keys(updates)) {
        if ((updates[key] === null || updates[key] === context.state.current[key]) && key !== 'id') {
          delete updates[key]
        }
      }
      axios
        .patch(`/api/organization/${context.state.current.id}`, updates)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  organizationRpiClientUpdate (context, opts) {
    return new Promise((resolve, reject) => {
      if (opts.organization && opts.macAddress) {
        axios
          .patch(`/api/organization/${opts.organization}`, { rpiMacAddress: opts.macAddress })
          .then(({ data }) => {
            resolve(data)
          }).catch((error) => reject(error))
      }
    })
  },
  // ======================================================================
  // ==============================Client==================================
  // ======================================================================
  organizationClientKey (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/organization/${opts.id}/get-client-key`)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  organizationClientExe (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/client/make-exe?id=${opts.id}&key=${opts.key}&model=organization`)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  organizationClientDestroy (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .delete('/api/client', { data: opts })
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  organizationClientRestart (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/client/restart', opts)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  organizationClientExeUrl (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/client/get-exe?id=${opts.id}&model=organization`)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  organizationClientResetConnected (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/api/organization/${context.state.current.id}`, { keyConnected: false })
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  organizationClientLogNames (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/organization/get-client-log-names', opts)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  clientlogfilenamesValue (context, opts) {
    if (opts.user === context.rootState.User.loggedIn.id) {
      context.commit('ORGANIZATION_CLIENT_LOG_FILENAMES', opts.list)
      context.commit('ORGANIZATION_FETCHING_CLIENT_LOG_FILENAMES', false)
    } else {
      context.commit('ORGANIZATION_CLIENT_LOG_FILENAMES', null)
      context.commit('ORGANIZATION_FETCHING_CLIENT_LOG_FILENAMES', true)
    }
  },
  clientDownloadSingleLog (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/organization/download-single-client-log', opts)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  clientlogdownloadValue (context, opts) {
    if (opts.user === context.rootState.User.loggedIn.id) {
      context.commit('ORGANIZATION_UPDATE_DOWNLOADABLE_CLIENT_LOG_URL', opts.logUrl)
    } else {
      context.commit('ORGANIZATION_UPDATE_DOWNLOADABLE_CLIENT_LOG_URL', null)
    }
  },
  organizationHealthchecks (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/organization/healthchecks?id=${context.state.current.id}`)
        .then(({ data }) => {
          context.commit('ORGANIZATION_CURRENT_HEALTH_CHECKS', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  organizationForecast (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/organization/forecast?id=${opts}`)
        .then(({ data }) => {
          context.commit('ORGANIZATION_CURRENT_FORECAST', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  organizationCreate (context, opts) {
    return new Promise((resolve, reject) => {
      const create = Object.assign({}, context.state.create)
      for (const key of Object.keys(create)) {
        if (create[key] == null) {
          delete create[key]
        }
      }
      axios
        .post('/api/organization', create)
        .then(({ data }) => {
          context.commit('ORGANIZATION_CREATE_ID', data)
          resolve(data)
        }).catch((error) => {
          if (error.response && error.response.data && error.response.data.message) {
            reject(error.response.data)
          } else {
            reject(error)
          }
        })
    })
  },
  organizationCreateReset (context, opts) {
    context.commit('ORGANIZATION_CREATE_RESET', null)
  }
}
