export default {
  CAMERA_CURRENT (state, value) {
    // need to make a more graceful update to minimize screen disruptions
    if (value) {
      const props = [
        'vendorId',
        'encoder',
        'deletedAt',
        'firmwareVersion',
        'ip',
        'logicalId',
        'location',
        'mac',
        'manufacturer',
        'model',
        'name',
        'notes',
        'operatingPriority',
        'serialNumber',
        'server',
        'siteServer',
        'status',
        'system'
      ]
      for (const prop of props) {
        if (value[prop] !== state.current[prop]) {
          state.current[prop] = value[prop]
        }
      }
      for (const prop of ['id', 'active', 'reenableTime', 'building']) {
        if (value[prop] !== state.current[prop]) {
          state.current[prop] = value[prop]
          state.update[prop] = value[prop]
        }
      }
    } else {
      state.current = {
        active: false,
        vendorId: '',
        building: null,
        deletedAt: null,
        encoder: null,
        firmwareVersion: '',
        healthChecks: [],
        id: '',
        ip: '',
        location: '',
        logicalId: '',
        mac: '',
        manufacturer: '',
        model: '',
        name: '',
        notes: false,
        operatingPriority: 0,
        reenableTime: null,
        serialNumber: '',
        server: '',
        siteServer: '',
        status: '',
        system: null
      }
      state.update = {
        active: false,
        building: null,
        id: '',
        reenableTime: null
      }
    }
  },
  CAMERA_CURRENT_HISTORY (state, value) {
    if (value && Object.keys(value).length > 0) {
      state.current.history = value
    } else {
      state.current.history = {}
    }
  },
  CAMERA_CURRENT_NOTES (state, value) {
    state.current.notes = value
  },
  CAMERA_LIST (state, value) {
    state.list = (value && Array.isArray(value)) ? [...(value.sort((a, b) => a.name.localeCompare(b.name)))] : []
  },
  CAMERA_LIST_UPDATE (state, value) {
    const index = state.list.findIndex((i) => i.id === value.id)
    if (index !== -1) {
      state.list.splice(index, 1, Object.assign(state.list[index], value)) // keep old values such as status
    } else {
      state.list.push(value) // see fetchModel.js
    }
  },
  CAMERA_BULK_EDIT (state, value) {
    state.update.bulkEdit = value
  },
  CAMERA_LIST_ITEM_REMOVE (state, value) {
    const id = typeof value === 'string' ? value : typeof value === 'object' && value.id ? value.id : ''
    const index = state.list.findIndex(c => c.id === id)
    if (index > -1) {
      state.list.splice(index, 1)
    }
  },
  CAMERA_UPDATE_ACTIVE (state, value) {
    state.update.active = value
  },
  CAMERA_UPDATE_HEALTH_CHECK_HISTORY (state, value) {
    if (state.current?.history && state.current.history[value.name]) {
      state.current.history[value.name] = value.history
    }
  },
  CAMERA_UPDATE_BUILDING (state, value) {
    state.update.building = value
  },
  CAMERA_UPDATE_REENABLE_TIME (state, value) {
    state.update.reenableTime = value
  }
}
