import Accessgroup from './accessgroup/accessgroup.module'
import Accessgroupmember from './accessgroupmember/accessgroupmember.module'
import Acmappliance from './acmappliance/acmappliance.module'
import Acmpolicy from './acmpolicy/acmpolicy.module'
import AdHoc from './adhoc/adhoc.module'
import Alarm from './alarm/alarm.module'
import App from './app/app.module'
import Area from './area/area.module'
import Boschpanel from './boschpanel/boschpanel.module'
import Boschpanelpoint from './boschpanelpoint/boschpanelpoint.module'
import Bookmark from './bookmark/bookmark.module'
import Branch from './branch/branch.module'
import Budget from './budget/budget.module'
import Building from './building/building.module'
import Camera from './camera/camera.module'
import Comfort from './comfort/comfort.module'
import Dashboard from './dashboard/dashboard.module'
import Diagnostic from './diagnostic/diagnostic.module'
import Door from './door/door.module'
import Encoder from './encoder/encoder.module'
import Energy from './energy/energy.module'
import Flash from './flash/flash.module'
import Fileupload from './fileupload/fileupload.module'
import Globalaction from './globalaction/globalaction.module'
import Globalactionmember from './globalactionmember/globalactionmember.module'
import Group from './group/group.module'
import Grouppermission from './grouppermission/grouppermission.module'
import Oncallschedule from './oncallschedule/oncallschedule.module'
import Healthcheck from './healthcheck/healthcheck.module'
import Healthcheckeventlog from './healthcheckeventlog/healthcheckeventlog.module'
import Healthcheckdef from './healthcheckdef/healthcheckdef.module'
import Helppage from './helppage/helppage.module'
import Hvaccontroller from './hvaccontroller/hvaccontroller.module'
import Icfusionendpoint from './icfusionendpoint/icfusionendpoint.module'
import Informacastdialcast from './informacastdialcast/informacastdialcast.module'
import Informacastdialcastrecipientgroup from './informacastdialcastrecipientgroup/dialcastrecipientgroup.module'
import Informacastm2m from './informacastm2m/informacastm2m.module'
import Informacastlog from './informacastlog/informacastlog.module'
import Informacastmessage from './informacastmessage/informacastmessage.module'
import Informacastoutboundemail from './informacastoutboundemail/informacastoutboundemail.module'
import Informacastrecipientgroup from './informacastrecipientgroup/informacastrecipientgroup.module'
import Lockdownconfig from './lockdownconfig/lockdownconfig.module'
import Note from './note/note.module'
import Organization from './organization/organization.module'
import Permission from './permission/permission.module'
import Panel from './panel/panel.module'
import Point from './point/point.module'
import Resourcelog from './resourcelog/resourcelog.module'
import Room from './room/room.module'
import Rpiclient from './rpiclient/rpiclient.module'
import Schedule from './schedule/schedule.module'
import Server from './server/server.module'
import Servicedivision from './servicedivision/servicedivision.module'
import Socket from './socket/socket.module'
import System from './system/system.module'
import Tag from './tag/tag.module'
import Token from './token/token.module'
import User from './user/user.module'
import Userdashboard from './userdashboard/userdashboard.module'
import Userfeedback from './userfeedback/userfeedback.module'
import Usergroup from './usergroup/usergroup.module'
import Userorg from './userorg/userorg.module'
import Usersystemkind from './usersystemkind/usersystemkind.module'
import Userservicedivision from './userservicedivision/userservicedivision.module'
import Versionhistory from './versionhistory/versionhistory.module'

import { store } from 'quasar/wrappers'
import { createStore } from 'vuex'

export default store(function (/* { ssrContext } */) {
  const Store = createStore({
    modules: {
      Accessgroup,
      Accessgroupmember,
      Acmappliance,
      Acmpolicy,
      AdHoc,
      Alarm,
      App,
      Area,
      Bookmark,
      Boschpanel,
      Boschpanelpoint,
      Branch,
      Budget,
      Building,
      Camera,
      Comfort,
      Dashboard,
      Diagnostic,
      Door,
      Encoder,
      Energy,
      Fileupload,
      Flash,
      Globalaction,
      Globalactionmember,
      Group,
      Grouppermission,
      Oncallschedule,
      Healthcheck,
      Healthcheckeventlog,
      Healthcheckdef,
      Helppage,
      Hvaccontroller,
      Icfusionendpoint,
      Informacastdialcast,
      Informacastdialcastrecipientgroup,
      Informacastlog,
      Informacastm2m,
      Informacastmessage,
      Informacastoutboundemail,
      Informacastrecipientgroup,
      Lockdownconfig,
      Note,
      Organization,
      Permission,
      Panel,
      Point,
      Resourcelog,
      Room,
      Rpiclient,
      Schedule,
      Server,
      Servicedivision,
      Socket,
      System,
      Tag,
      Token,
      User,
      Userdashboard,
      Usergroup,
      Userfeedback,
      Userorg,
      Usersystemkind,
      Userservicedivision,
      Versionhistory
    },
    state: {
    },
    mutations: {
    },
    actions: {
    },
    getters: {
    },

    // enable strict mode (adds overhead!)
    // for dev mode and --debug builds only
    strict: process.env.DEBUGGING
  })
  return Store
})
