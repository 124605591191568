import axios from 'axios'
export default {
  childResourceLogs (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/resourcelog', { params: opts })
        .then(({ data }) => {
          context.commit('CHILD_RESOURCE_LOGS', data) // this will update the left side drawer
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  resourcelogList (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/resourcelog', { params: opts })
        .then(({ data }) => {
          context.commit('RESOURCELOG_LIST', data) // this will update the left side drawer
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  resourcelogHistoryList (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/resourcelog/history', opts)
        .then(({ data }) => {
          context.commit('RESOURCELOG_HISTORY_LIST', data) // this will update the left side drawer
          resolve(data)
        }).catch((error) => {
          reject(error)
        })
    })
  }
}
