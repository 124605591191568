export default function () {
  return {
    current: {
      id: '',
      allDay: false,
      area: null,
      kind: '',
      endTime: null,
      startTime: null,
      title: '',
      weekly: false
    },
    update: {
      id: '',
      allDay: false,
      kind: '',
      endTime: null,
      startTime: null,
      title: ''
    },
    list: []
  }
}
