import axios from 'axios'
export default {
  // ===========================Socket Updates=====================
  buildingCreated (context, opts) {
    const currentOrgId = context.rootState.Organization.current.id
    const createdOrgId = context.rootState.Organization.create.id
    const permission = (context.rootState.User.permissions.building && context.rootState.User.permissions.building.read)
    if (permission && opts?.organization && (opts.organization === currentOrgId || opts.organization === createdOrgId)) {
      const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
      const inputs = { }
      if (opts && Array.isArray(opts.populate) && opts.populate.length > 0) {
        inputs.params = { }
        inputs.params.populate = opts.populate
      }
      axios
        .get(`/api/building/${id}`, inputs)
        .then(({ data }) => {
          if (data.id) {
            context.commit('BUILDING_LIST_UPDATE', data)
          }
        }).catch(() => {
        })
    }
  },
  buildingUpdated (context, opts) {
    const id = opts && opts.id ? opts.id : opts
    const populated = opts && opts.populate ? opts.populate : []
    if (id === context.state.current.id) {
      axios
        .get(`/api/building/${id}`, { params: populated })
        .then(({ data }) => {
          if (data.id) {
            data.socketUpdate = true
            context.commit('BUILDING_CURRENT', data)
            // if (!opts.background) { // check2.js calls socket updates without a populate so disregard
            //   setChildLists(context, data, false)
            // }
          }
        }).catch(() => {})
    }
  },
  buildingDestroyed (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    if (id === context.state.current.id) {
      context.commit('BUILDING_CURRENT', null)
    }
    context.commit('BUILDING_LIST_ITEM_REMOVE', opts)
  },
  // ===========================List Edits=====================
  buildingList (context, opts) {
    let noVuex = false
    if (opts && opts.noVuex) {
      noVuex = true
      delete opts.noVuex
    }
    return new Promise((resolve, reject) => {
      axios
        .get('/api/building', { params: opts })
        .then(({ data }) => {
          if (!noVuex) {
            context.commit('BUILDING_LIST', data)
          }
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  buildingAdd (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/building', opts)
        .then(async ({ data }) => {
          resolve(data)
        }).catch((error) => {
          if (error.response && error.response.data && error.response.data.message) {
            reject(error.response.data)
          } else {
            reject(error)
          }
        })
    })
  },
  buildingDelete (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/api/building/${opts}`)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  // ===========================Item Management=====================
  buildingDetail (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    // const changed = (context.state.current.id !== id)
    const inputs = { populate: null, kind: null }
    if (opts && Array.isArray(opts.populate) && opts.populate.length > 0) {
      inputs.params = { }
      inputs.params.populate = opts.populate
    }
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/building/${id}`, inputs)
        .then(({ data }) => {
          context.commit('BUILDING_CURRENT', data)
          context.commit('BUILDING_LIST_UPDATE', data)
          // if (opts && !opts.overrideFetch) {
          //   setChildLists(context, data, changed)
          // }
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  buildingEnergyList (context, opts) {
    const dateRange = `startDate=${opts.startDate}&endDate=${opts.endDate}` // required
    const params = opts.id ? `${dateRange}&id=${opts.id}` : dateRange
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/building/energy?${params}`)
        .then(({ data }) => {
          context.commit('BUILDING_CURRENT', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  buildingUpdate (context, opts) {
    return new Promise((resolve, reject) => {
      const updates = Object.assign({}, context.state.update)
      for (const key of Object.keys(updates)) {
        if ((updates[key] === null || updates[key] === context.state.current[key]) && key !== 'id') {
          delete updates[key]
        }
      }
      axios
        .patch(`/api/building/${context.state.current.id}`, updates)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  buildingChildUpdate (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/api/${opts.kind}/${opts.id}`, opts.data)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  buildingAddTags (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/buildingtag', opts)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  buildingRemoveTags (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .delete('/api/buildingtag', { data: opts })
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  buildingCreate (context, opts) {
    return new Promise((resolve, reject) => {
      if (opts.organization && context.state.create.name && context.state.create.name !== '') {
        axios
          .post('/api/building', { name: context.state.create.name, organization: opts.organization })
          .then(({ data }) => {
            resolve(data)
          }).catch((error) => reject(error))
      } else {
        reject(new Error('No Organization'))
      }
    })
  },
  buildingCreateReset (context, opts) {
    context.commit('BUILDING_CREATE_RESET', null)
  }
}
