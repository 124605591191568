export default {
  SERVER_CURRENT (state, value) {
    // need to make a more graceful update to minimize screen disruptions
    if (value) {
      const currentValues = [
        'active',
        'building',
        'databaseName',
        'icToken',
        'id',
        'instanceName',
        'ip',
        'kind',
        'name',
        'managementCardIp',
        'managementCardKind',
        'managementCardPassword',
        'managementCardPort',
        'managementCardUsername',
        'osCheck',
        'password',
        'port',
        'reenableTime',
        'serverLogDirectory',
        'serverLogPassword',
        'serverLogUsername',
        'siteServerId',
        'url',
        'username'
      ]
      for (const prop of currentValues) {
        if (value[prop] !== state.current[prop]) {
          state.current[prop] = value[prop]
          state.update[prop] = value[prop]
        }
      }
      for (const prop of ['info', 'status', 'system', 'notes', 'icTokenExpiresOn']) {
        if (value[prop] !== state.current[prop]) {
          state.current[prop] = value[prop]
        }
      }
      if (!value.socketUpdate) {
        state.current.points = value.points
      }
    } else {
      state.current = {
        active: false,
        building: null,
        databaseName: '',
        hasManagementCard: false,
        healthChecks: [],
        icToken: '',
        icTokenExpiresOn: null,
        id: '',
        info: {},
        instanceName: '',
        ip: '',
        kind: '',
        managementCardIp: '',
        managementCardKind: '',
        managementCardPassword: '',
        managementCardPort: 0,
        managementCardUsername: '',
        name: '',
        notes: false,
        osCheck: false,
        password: '',
        points: [],
        port: 0,
        reenableTime: null,
        serverLogDirectory: '',
        serverLogPassword: '',
        serverLogUsername: '',
        siteServerId: '',
        status: '',
        system: null,
        url: '',
        username: ''
      }
      state.update = {
        active: false,
        building: null,
        databaseName: '',
        hasManagementCard: false,
        icToken: '',
        id: '',
        instanceName: '',
        ip: '',
        kind: '',
        managementCardIp: '',
        managementCardKind: '',
        managementCardPassword: '',
        managementCardPort: 0,
        managementCardUsername: '',
        name: '',
        osCheck: false,
        port: 0,
        password: '',
        reenableTime: null,
        serverLogDirectory: '',
        serverLogPassword: '',
        serverLogUsername: '',
        siteServerId: '',
        url: '',
        username: ''
      }
    }
  },
  SERVER_CURRENT_HISTORY (state, value) {
    if (value && Object.keys(value).length > 0) {
      state.current.history = value
    } else {
      state.current.history = {}
    }
  },
  SERVER_CURRENT_NOTES (state, value) {
    state.current.notes = value
  },
  SERVER_CREATE_BUILDING (state, value) {
    state.create.building = value
  },
  SERVER_CREATE_DATABASE_NAME (state, value) {
    state.create.databaseName = value
  },
  SERVER_CREATE_INSTANCE_NAME (state, value) {
    state.create.instanceName = value
  },
  SERVER_CREATE_IP (state, value) {
    state.create.ip = value
  },
  SERVER_CREATE_KIND (state, value) {
    state.create.kind = value
  },
  SERVER_CREATE_MANAGEMENT_CARD_IP (state, value) {
    state.create.managementCardIp = value
  },
  SERVER_CREATE_MANAGEMENT_CARD_KIND (state, value) {
    state.create.managementCardKind = value
  },
  SERVER_CREATE_MANAGEMENT_CARD_PASSWORD (state, value) {
    state.create.managementCardPassword = value
  },
  SERVER_CREATE_MANAGEMENT_CARD_PORT (state, value) {
    state.create.managementCardPort = value
  },
  SERVER_CREATE_MANAGEMENT_CARD_USERNAME (state, value) {
    state.create.managementCardUsername = value
  },
  SERVER_CREATE_NAME (state, value) {
    state.create.name = value
  },
  SERVER_CREATE_OS_CHECK (state, value) {
    state.create.osCheck = value
  },
  SERVER_CREATE_PASSWORD (state, value) {
    state.create.password = value
  },
  SERVER_CREATE_PORT (state, value) {
    state.create.port = value
  },
  SERVER_CREATE_RESET (state, value) {
    state.create.id = value
    state.create.building = value
    state.create.databaseName = value
    state.create.instanceName = value
    state.create.ip = value
    state.create.kind = value
    state.create.managementCardIp = value
    state.create.managementCardKind = value
    state.create.managementCardPassword = value
    state.create.managementCardPort = value
    state.create.managementCardUsername = value
    state.create.name = value
    state.create.osCheck = false
    state.create.password = value
    state.create.port = value
    state.create.username = value
  },
  SERVER_CREATE_USERNAME (state, value) {
    state.create.username = value
  },
  SERVER_CREATE_SYSTEM (state, value) {
    state.create.system = value
  },
  SERVER_LOG_NAMES (state, value) {
    state.serverLogNames = value || []
  },
  SERVER_FETCHING_LOG_NAMES (state, value) {
    if (typeof value !== 'undefined' && value !== null) {
      state.fetchingServerLogNames = value
    } else {
      state.fetchingServerLogNames = true
    }
  },
  SERVER_LIST (state, value) {
    state.list = (value && Array.isArray(value)) ? [...(value.sort((a, b) => a.name.localeCompare(b.name)))] : []
  },
  SERVER_LIST_UPDATE (state, value) {
    const index = state.list.findIndex((i) => i.id === value.id)
    if (index !== -1) {
      state.list.splice(index, 1, Object.assign(state.list[index], value))
    } else {
      state.list.push(Object.assign({}, value)) // see fetchModel.js
    }
  },
  SERVER_UPDATE_HEALTH_CHECK_HISTORY (state, value) {
    if (state.current?.history && state.current.history[value.name]) {
      state.current.history[value.name] = value.history
    }
  },
  SERVER_LIST_ITEM_REMOVE (state, value) {
    const id = typeof value === 'string' ? value : typeof value === 'object' && value.id ? value.id : ''
    const index = state.list.findIndex(c => c.id === id)
    if (index > -1) {
      state.list.splice(index, 1)
    }
  },
  SERVER_SITE_SERVER_IDS (state, value) {
    state.siteServerIds = value
  },
  SERVER_UPDATE_ACTIVE (state, value) {
    state.update.active = value
  },
  SERVER_UPDATE_BUILDING (state, value) {
    state.update.building = value
  },
  SERVER_UPDATE_DATABASE_NAME (state, value) {
    state.update.databaseName = value
  },
  SERVER_UPDATE_DOWNLOADABLE_SERVER_LOG_URL (state, value) {
    state.downloadableServerLogUrl = value
  },
  SERVER_UPDATE_HAS_MANAGEMENT_CARD (state, value) {
    state.update.hasManagementCard = value
  },
  SERVER_UPDATE_IC_TOKEN (state, value) {
    state.update.icToken = value
  },
  SERVER_UPDATE_INSTANCE_NAME (state, value) {
    state.update.instanceName = value
  },
  SERVER_UPDATE_IP (state, value) {
    state.update.ip = value
  },
  SERVER_UPDATE_KIND (state, value) {
    state.update.kind = value
  },
  SERVER_UPDATE_LOG_DIRECTORY (state, value) {
    state.update.serverLogDirectory = value
  },
  SERVER_UPDATE_LOG_PASSWORD (state, value) {
    state.update.serverLogPassword = value
  },
  SERVER_UPDATE_SERVER_LOG_USERNAME (state, value) {
    state.update.serverLogUsername = value
  },
  SERVER_UPDATE_MANAGEMENT_CARD_IP (state, value) {
    state.update.managementCardIp = value
  },
  SERVER_UPDATE_MANAGEMENT_CARD_KIND (state, value) {
    state.update.managementCardKind = value
  },
  SERVER_UPDATE_MANAGEMENT_CARD_PASSWORD (state, value) {
    state.update.managementCardPassword = value
  },
  SERVER_UPDATE_MANAGEMENT_CARD_PORT (state, value) {
    state.update.managementCardPort = value
  },
  SERVER_UPDATE_MANAGEMENT_CARD_USERNAME (state, value) {
    state.update.managementCardUsername = value
  },
  SERVER_UPDATE_NAME (state, value) {
    state.update.name = value
  },
  SERVER_UPDATE_OS_CHECK (state, value) {
    state.update.osCheck = value
  },
  SERVER_UPDATE_PASSWORD (state, value) {
    state.update.password = value
  },
  SERVER_UPDATE_PORT (state, value) {
    state.update.port = value
  },
  SERVER_UPDATE_REENABLE_TIME (state, value) {
    state.update.reenableTime = value
  },
  SERVER_UPDATE_RESET_MANAGEMENT_CARD (state, value) {
    state.update.managementCardIp = value ? state.current.managementCardIp : ''
    state.update.managementCardPassword = value ? state.current.managementCardPassword : ''
    state.update.managementCardPort = value ? state.current.managementCardPort : 0
    state.update.managementCardUsername = value ? state.current.managementCardUsername : ''
  },
  SERVER_UPDATE_SITE_SERVER_ID (state, value) {
    state.update.siteServerId = value
  },
  SERVER_UPDATE_URL (state, value) {
    state.update.url = value
  },
  SERVER_UPDATE_USERNAME (state, value) {
    state.update.username = value
  }
}
