export default {
  USERDASHBOARD_USERS (state, value) {
    if (value && value.users && Array.isArray(value.users)) {
      state.dashboard.users = value.users
    }
  },
  USERDASHBOARD_DASHBOARDS (state, value) {
    if (value && value.dashboards && Array.isArray(value.dashboards)) {
      state.user.dashboards = value.dashboards
    }
  },
  USERDASHBOARD_UPDATE_LISTS (state, value) {
    const userIndex = state.user.dashboards.findIndex((i) => i.id === value.id)
    const dashIndex = state.dashboard.users.findIndex((i) => i.id === value.id)
    const newVal = { defaultDashboard: value.defaultDashboard, allowEditing: value.allowEditing }
    if (userIndex !== -1) {
      state.user.dashboards.splice(userIndex, 1, Object.assign(state.user.dashboards[userIndex], newVal))
    }
    if (dashIndex !== -1) {
      state.dashboard.users.splice(dashIndex, 1, Object.assign(state.dashboard.users[dashIndex], newVal))
    }
  },
  USERDASHBOARD_USER_LIST_ADD (state, value) {
    const index = state.dashboard.users.findIndex((i) => i.id === value.id)
    if (index === -1) {
      state.dashboard.users.push(value)
    }
  },
  USERDASHBOARD_DASHBOARD_LIST_ADD (state, value) {
    const index = state.user.dashboards.findIndex((i) => i.id === value.id)
    if (index === -1) {
      state.user.dashboards.push(value)
    }
  },
  USERDASHBOARD_UPDATE_DEFAULT (state, value) {
    const index = state.user.dashboards.findIndex((ud) => ud.id === value.dashboard)
    if (index !== -1) {
      state.user.dashboards.splice(index, 1, Object.assign(state.user.dashboards[index], { defaultDashboard: value.defaultDashboard }))
    }
  },
  USERDASHBOARD_UPDATE_HOMEPAGE (state, value) {
    const index = state.user.dashboards.findIndex((ud) => ud.id === value.dashboard)
    if (index !== -1) {
      state.user.dashboards.splice(index, 1, Object.assign(state.user.dashboards[index], { homepage: value.homepage }))
    }
  },
  USERDASHBOARD_DESTROYED (state, value) {
    const dashIndex = state.user.dashboards.findIndex((ud) => ud.id === value)
    const userIndex = state.dashboard.users.findIndex((ud) => ud.id === value)
    if (dashIndex !== -1) {
      state.user.dashboards.splice(dashIndex, 1)
    }
    if (userIndex !== -1) {
      state.dashboard.users.splice(userIndex, 1)
    }
  }
}
