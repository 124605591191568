import axios from 'axios'

export default {
  // ==========================Socket Updates==============================
  cameraCreated (context, opts) {
    // do nothing
  },
  cameraUpdated (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    const inputs = { }
    if (opts && Array.isArray(opts.populate) && opts.populate.length > 0) {
      inputs.params = { }
      inputs.params.populate = opts.populate
    }
    const inList = context.state.list.find((i) => i.id === id)
    if (context.state.current.id === id || inList) {
      axios
        .get(`/api/camera/${id}`, inputs)
        .then(({ data }) => {
          if (context.state.current.id === data.id) {
            data.socketUpdate = true
            context.commit('CAMERA_CURRENT', data)
          }
          if (inList) {
            context.commit('CAMERA_LIST_UPDATE', data)
          }
        }).catch(() => {
        })
    }
  },
  cameraActiveUpdate (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/api/camera/${opts.id}`, opts.data)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  cameraDestroyed (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    if (id === context.state.current.id) {
      context.commit('CAMERA_CURRENT', null)
    }
    context.commit('CAMERA_LIST_ITEM_REMOVE', opts)
  },
  // ======================================================================
  // ==========================Item Management=============================
  // ======================================================================
  cameraList (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/camera', { params: opts })
        .then(({ data }) => {
          context.commit('CAMERA_LIST', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  cameraDetail (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    const inputs = { }
    if (opts && Array.isArray(opts.populate) && opts.populate.length > 0) {
      inputs.params = { }
      inputs.params.populate = opts.populate
    }
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/camera/${id}`, inputs)
        .then(({ data }) => {
          context.commit('CAMERA_CURRENT', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  cameraHistory (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/camera/history', { params: { id: opts } })
        .then(({ data }) => {
          context.commit('CAMERA_CURRENT_HISTORY', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  cameraUpdate (context, opts) {
    const updates = Object.assign({}, context.state.update)
    for (const key of Object.keys(updates)) {
      if (updates[key] == null && key !== 'building') {
        delete updates[key]
      }
    }
    return new Promise((resolve, reject) => {
      axios
        .patch(`/api/camera/${context.state.current.id}`, updates)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  cameraUpdateBuilding (context, opts) {
    const id = opts.id
    delete opts.id
    return new Promise((resolve, reject) => {
      axios
        .patch(`/api/camera/${id}`, opts)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  bulkUpdateCameras (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .patch('/api/camera/bulkupdate', opts)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  }
}
