export default function () {
  return {
    current: {
      path: '',
      content: '',
      videoSrc: '',
      modelPermission: '',
      actionPermission: ''
    },
    update: {
      content: '',
      videoSrc: '',
      modelPermission: '',
      actionPermission: ''
    }
  }
}
