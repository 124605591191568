export default function () {
  return {
    user: {
      dashboards: []
    },
    dashboard: {
      users: []
    }
  }
}
