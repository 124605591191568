export default {
  INFORMACASTLOG_LIST (state, value) {
    state.list = (value && Array.isArray(value)) ? [...(value.sort((a, b) => new Date(b.dateOfLog) - new Date(a.dateOfLog)))] : []
  },
  INFORMACASTLOG_LIST_UPDATE (state, value) {
    const index = state.list.findIndex((i) => i.id === value.id)
    if (index !== -1) {
      state.list.splice(index, 1, Object.assign(state.list[index], value))
    } else {
      state.list.push(value)
    }
  },
  INFORMACASTLOG_LIST_RESET_MASTER_TEMPLATE (state, value) {
    for (const log of state.list) {
      if (typeof log.masterTemplate !== 'undefined') {
        log.masterTemplate = false
      }
    }
  },
  INFORMACASTLOG_CURRENT (state, value) {
    if (value) {
      const props = [
        'id',
        'active',
        'activationDuration',
        'authenticationErrors',
        'broadcastDuration',
        'deactivationDuration',
        'gatheringDuration',
        'networkErrors',
        'activateFailureCount',
        'unknownErrors',
        'idnsBroadcasted',
        'phonesBroadcasted',
        'recipientsBroadcasted',
        'speakersBroadcasted',
        'reenableTime',
        'system'
      ]
      for (const prop of props) {
        if (value[prop] !== state.current[prop]) {
          state.current[prop] = value[prop]
          state.update[prop] = value[prop]
        }
      }
    } else {
      state.current = {
        id: '',
        active: false,
        activationDuration: null,
        authenticationErrors: null,
        broadcastDuration: null,
        deactivationDuration: null,
        gatheringDuration: null,
        networkErrors: null,
        activateFailureCount: null,
        unknownErrors: null,
        idnsBroadcasted: null,
        phonesBroadcasted: null,
        recipientsBroadcasted: null,
        speakersBroadcasted: null,
        reenableTime: null,
        system: null
      }
      state.update = {
        id: '',
        active: false,
        activationDuration: null,
        authenticationErrors: null,
        broadcastDuration: null,
        deactivationDuration: null,
        gatheringDuration: null,
        networkErrors: null,
        activateFailureCount: null,
        unknownErrors: null,
        idnsBroadcasted: null,
        phonesBroadcasted: null,
        recipientsBroadcasted: null,
        speakersBroadcasted: null,
        reenableTime: null
      }
    }
  },
  INFORMACASTLOG_UPDATE_ACTIVE (state, value) {
    state.update.active = value
  },
  INFORMACASTLOG_UPDATE_REENABLE_TIME (state, value) {
    state.update.reenableTime = value
  },
  INFORMACASTLOG_UPDATE_BUILDING (state, value) {
    state.update.building = value
  },
  INFORMACASTLOG_FETCHING_LIST (state, value) {
    state.fetchingList = value
  }
}
