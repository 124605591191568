export default {
  TAG_LIST (state, value) {
    state.list = (value && Array.isArray(value)) ? [...(value.sort((a, b) => a.name.localeCompare(b.name)))] : []
  },
  TAG_LIST_ADD (state, value) {
    if (state.list.findIndex((i) => i.id === value.id) === -1) {
      state.list.push({
        id: value.id,
        name: value.name,
        buildings: value.buildings.length,
        rooms: value.rooms.length
      })
    }
  },
  TAG_LIST_ITEM_REMOVE (state, value) {
    const id = typeof value === 'string' ? value : typeof value === 'object' && value.id ? value.id : ''
    const index = state.list.findIndex((i) => i.id === id)
    if (index !== -1) {
      state.list.splice(index, 1)
    }
  },
  TAG_LIST_UPDATE (state, value) {
    const index = state.list.findIndex((i) => i.id === value.id)
    value.buildings = value.buildings ? value.buildings.length : 0
    value.rooms = value.rooms ? value.rooms.length : 0
    if (index !== -1) {
      state.list.splice(index, 1, Object.assign(state.list[index], value))
    } else {
      state.list.push(value)
    }
  },
  TAG_CURRENT (state, value) {
    // need to make a more graceful update to minimize screen disruptions
    if (value) {
      for (const prop of ['id', 'name']) {
        if (value[prop] !== state.current[prop]) {
          state.current[prop] = value[prop]
          state.update[prop] = value[prop]
        }
      }
      for (const prop of ['organization']) {
        state.current[prop] = value[prop]
      }
      if (!value.socketUpdate) {
        state.current.buildings = value.buildings
        state.current.rooms = value.rooms
      }
    } else {
      state.current = {
        id: '',
        name: '',
        organization: null,
        buildings: [],
        rooms: []
      }
      state.update = {
        id: '',
        name: ''
      }
    }
  },
  TAG_UPDATE_NAME (state, value) {
    state.update.name = value
  }
}
