import axios from 'axios'
export default {
  // ==========================Socket Updates==============================
  hvaccontrollerCreated (context, opts) {
    const buildingId = context.rootState.Building.current.id
    const systemId = context.rootState.System.current.id
    const parentId = context.rootState.Hvaccontroller.current.id
    const permission = (context.rootState.User.permissions.hvaccontroller && context.rootState.User.permissions.hvaccontroller.read)
    const buildingResult = (opts?.building && opts.building === buildingId)
    const systemResult = (opts?.system && opts.system === systemId)
    const parentResult = (opts?.parentController && opts.parentController === parentId)

    if (permission && (buildingResult || systemResult || parentResult)) {
      const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
      const inputs = { }
      if (opts && Array.isArray(opts.populate) && opts.populate.length > 0) {
        inputs.params = { }
        inputs.params.populate = opts.populate
      }
      axios
        .get(`/api/hvaccontroller/${id}`, inputs)
        .then(({ data }) => {
          if (data.id) {
            context.commit('HVACCONTROLLER_LIST_UPDATE', data)
          }
        }).catch(() => {
        })
    }
  },
  hvaccontrollerUpdated (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    const inputs = { }
    if (opts && Array.isArray(opts.populate) && opts.populate.length > 0) {
      inputs.params = { }
      inputs.params.populate = opts.populate
    }
    const inList = context.state.list.find((i) => i.id === id)
    if (context.state.current.id === id || inList) {
      axios
        .get(`/api/hvaccontroller/${id}`, inputs)
        .then(({ data }) => {
          if (context.state.current.id === data.id) {
            data.socketUpdate = true
            context.commit('HVACCONTROLLER_CURRENT', data)
          }
          if (inList) {
            context.commit('HVACCONTROLLER_LIST_UPDATE', data)
          }
        }).catch(() => {
        })
    } else if (context.state.current.controllers.find(c => c.id === id) || context.state.list.find((i) => i.id === id)) {
      axios
        .get(`/api/hvaccontroller/${id}`, inputs)
        .then(({ data }) => {
          if (data.id) {
            context.commit('HVACCONTROLLER_LIST_PATCH', data)
          }
        }).catch(() => {
        })
    } else if (context.rootState.System.current.hvacControllers.find(c => c.id === opts)) {
      axios
        .get(`/api/hvaccontroller/${id}`, inputs)
        .then(({ data }) => {
          if (data.id) {
            context.commit('SYSTEM_HVACCONTROLLER_LIST_PATCH', data)
          }
        }).catch(() => {
        })
    }
  },
  hvaccontrollerDestroyed (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    context.commit('HVACCONTROLLER_LIST_ITEM_REMOVE', id)
    if (id === context.state.current.id) {
      context.commit('HVACCONTROLLER_CURRENT', null)
    }
  },
  // ===========================List Edits===============================
  hvaccontrollerAdd (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/hvaccontroller', opts)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  hvaccontrollerDelete (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/api/hvaccontroller/${opts}`)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  // ======================================================================
  // ==========================Item Management=============================
  // ======================================================================
  hvaccontrollerList (context, opts) {
    let noVuex = false
    if (opts && opts.noVuex) {
      noVuex = true
      delete opts.noVuex
    }
    return new Promise((resolve, reject) => {
      axios
        .get('/api/hvaccontroller', { params: opts })
        .then(({ data }) => {
          if (!noVuex) {
            context.commit('HVACCONTROLLER_LIST', data)
          }
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  hvaccontrollerDetail (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    const inputs = { }
    if (opts && Array.isArray(opts.populate) && opts.populate.length > 0) {
      inputs.params = { }
      inputs.params.populate = opts.populate
    }
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/hvaccontroller/${id}`, inputs)
        .then(({ data }) => {
          context.commit('HVACCONTROLLER_CURRENT', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  hvaccontrollerPoints (context, opts) {
    if (opts && opts.hvacController && opts.hvacController !== '') {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/point', { params: opts })
          .then(({ data }) => {
            resolve(data)
          }).catch((error) => reject(error))
      })
    }
  },
  hvaccontrollerHistory (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/hvaccontroller/history', { id: opts })
        .then(({ data }) => {
          context.commit('HVACCONTROLLER_CURRENT_HISTORY', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  hvaccontrollerParentDetail (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/hvaccontroller/${opts}`)
        .then(({ data }) => {
          context.commit('HVACCONTROLLER_PARENT', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  hvaccontrollerUpdate (context, opts) {
    let id
    if (opts && opts.id) {
      id = opts.id
      delete opts.id
    } else {
      id = context.state.current.id
      opts = Object.assign({}, context.state.update)
      const current = Object.assign({}, context.state.current)
      for (const key of Object.keys(opts)) {
        if ((opts[key] == null && key !== 'building') || opts[key] === current[key]) {
          delete opts[key]
        }
      }
    }
    if (opts && Object.keys(opts).length > 0) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/api/hvaccontroller/${id}`, opts)
          .then(({ data }) => {
            resolve(data)
          }).catch((error) => reject(error))
      })
    } else {
      return false
    }
  },
  bulkUpdateHvacControllers (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .patch('/api/hvaccontroller/bulkupdate', opts)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  }
}
