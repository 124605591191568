export default {
  HEALTHCHECK_CURRENT (state, value) {
    if (value) {
      for (const prop of ['active', 'delay', 'reenableTime', 'threshold', 'aggregationThreshold']) {
        if (value[prop] !== state.current[prop]) {
          state.current[prop] = value[prop]
          state.update[prop] = value[prop]
        }
      }
      for (const prop of [
        'aggregation', 'aggregationLowerIsWorse', 'alarm', 'alarm', 'conditions',
        'defaultDelay', 'defaultThreshold', 'description', 'id', 'kind', 'lowerIsWorse',
        'maxValue', 'name', 'notes', 'resource', 'resourceType', 'status', 'systemKind', 'triggered',
        'unitOfMeasure', 'value'
      ]) {
        if (value[prop] !== state.current[prop]) {
          state.current[prop] = value[prop]
        }
      }
      if (!value.socketUpdate) {
        state.current.logs = value.logs
        state.current.points = value.points
      }
    } else {
      state.current = {
        active: false,
        aggregation: '',
        aggregationLowerIsWorse: false,
        aggregationThreshold: 0,
        alarm: false,
        conditions: '',
        defaultDelay: 0,
        defaultThreshold: 0,
        delay: null,
        description: '',
        id: '',
        kind: '',
        logs: [],
        lowerIsWorse: false,
        maxValue: 0,
        notes: '',
        points: [],
        reenableTime: null,
        resource: null,
        resourceType: '',
        status: '',
        systemKind: '',
        threshold: 0,
        triggered: false,
        unitOfMeasure: '',
        value: 0
      }
      state.update = {
        active: null,
        delay: null,
        aggregationThreshold: null,
        reenableTime: null,
        threshold: null
      }
    }
  },
  HEALTHCHECK_LIST (state, value) {
    state.list = (value && Array.isArray(value)) ? value : []
  },
  HEALTHCHECK_CURRENT_HISTORY (state, value) {
    if (Object.keys(value).length > 0) {
      state.current.history = value
    } else {
      state.current.history = {}
    }
  },
  HEALTHCHECK_LIST_UPDATE (state, value) {
    const index = state.list.findIndex((i) => i.id === value.id)
    if (index !== -1) {
      state.list.splice(index, 1, Object.assign(state.list[index], value))
    } else {
      state.list.push(value) // see fetchModel.js
    }
  },
  HEALTHCHECKKIND_LIST (state, value) {
    state.kinds = value
  },
  HEALTHCHECKKIND_LIST_UPDATE (state, value) {
    const index = state.kinds.findIndex((i) => i.id === value.id)
    if (index !== -1) {
      state.kinds.splice(index, 1, value)
    } else {
      state.kinds.push(value) // see fetchModel.js
    }
  },
  HEALTHCHECKLOG_LIST (state, value) {
    state.logs = value
  },
  HEALTHCHECK_LIST_ITEM_REMOVE (state, value) {
    const id = typeof value === 'string' ? value : typeof value === 'object' && value.id ? value.id : ''
    const index = state.list.findIndex(c => c.id === id)
    if (index > -1) {
      state.list.splice(index, 1)
    }
  },
  CHILDHEALTHCHECK_LIST (state, value) {
    state.childHealthCheckList = value
  },
  CHILDHEALTHCHECK_LIST_UPDATE (state, value) {
    const index = state.childHealthCheckList.findIndex((i) => i.id === value.id)
    if (index !== -1) {
      state.childHealthCheckList.splice(index, 1, value)
    } else {
      state.childHealthCheckList.push(value) // see fetchModel.js
    }
  },
  HEALTHCHECK_UPDATE_ACTIVE (state, value) {
    state.update.active = value
  },
  HEALTHCHECK_UPDATE_DELAY (state, value) {
    state.update.delay = value
  },
  HEALTHCHECK_UPDATE_REENABLE_TIME (state, value) {
    state.update.reenableTime = value
  },
  HEALTHCHECK_UPDATE_NOTES (state, value) {
    state.update.notes = value
  },
  HEALTHCHECK_CURRENT_NOTES (state, value) {
    state.current.notes = value
  },
  HEALTHCHECK_UPDATE_THRESHOLD (state, value) {
    state.update.threshold = value
  },
  HEALTHCHECK_UPDATE_AGGREGATION_THRESHOLD (state, value) {
    state.update.aggregationThreshold = value === '' ? null : Number(value)
  }
}
