export default {
  INFORMACASTMESSAGE_LIST (state, value) {
    state.list = (value && Array.isArray(value)) ? [...(value.sort((a, b) => a.description.localeCompare(b.description)))] : []
  },
  INFORMACASTMESSAGE_LIST_UPDATE (state, value) {
    const index = state.list.findIndex((i) => i.id === value.id)
    if (index !== -1) {
      state.list.splice(index, 1, Object.assign(state.list[index], value))
    } else {
      state.list.push(value)
    }
  },
  INFORMACASTMESSAGE_CURRENT (state, value) {
    const props = [
      'customizable',
      'description',
      'messageType',
      'ringtone',
      'shortText',
      'system',
      'syncId',
      'vendorId'
    ]
    if (value) {
      for (const prop of props) {
        if (value[prop] !== state.current[prop]) {
          state.current[prop] = value[prop]
        }
      }
      for (const prop of ['id', 'active', 'reenableTime', 'building']) {
        if (value[prop] !== state.current[prop]) {
          state.current[prop] = value[prop]
          state.update[prop] = value[prop]
        }
      }
    } else {
      state.current = {
        id: '',
        active: false,
        customizable: false,
        building: '',
        description: '',
        messageType: '',
        ringtone: false,
        reenableTime: null,
        shortText: '',
        system: '',
        syncId: '',
        vendorId: ''
      }
      state.update = {
        id: '',
        active: false,
        building: '',
        reenableTime: null
      }
    }
  },
  INFORMACASTMESSAGE_UPDATE_ACTIVE (state, value) {
    state.update.active = value
  },
  INFORMACASTMESSAGE_UPDATE_REENABLE_TIME (state, value) {
    state.update.reenableTime = value
  },
  INFORMACASTMESSAGE_UPDATE_BUILDING (state, value) {
    state.update.building = value
  },
  INFORMACASTMESSAGE_FETCHING_LIST (state, value) {
    state.fetchingList = value
  }
}
