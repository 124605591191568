import axios from 'axios'

export default {
  // ===========================List Management=====================
  oncallscheduleList (context, opts) {
    const selectedYear = opts ? { year: opts } : {}
    return new Promise((resolve, reject) => {
      axios
        .get('/api/oncallschedule', { params: selectedYear })
        .then(({ data }) => {
          context.commit('ONCALLSCHEDULE_LIST', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  oncallscheduleFutureList (context, opts) {
    const selectedYear = opts ? { year: opts } : {}
    return new Promise((resolve, reject) => {
      axios
        .get('/api/oncallschedule', { params: selectedYear })
        .then(({ data }) => {
          context.commit('ONCALLSCHEDULE_FUTURE_LIST', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  // ===========================Socket Updates=====================
  oncallscheduleCreated (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    const inputs = { }
    if (opts && Array.isArray(opts.populate) && opts.populate.length > 0) {
      inputs.params = { }
      inputs.params.populate = opts.populate
    }
    axios
      .get(`/api/oncallschedule/${id}`, inputs)
      .then(({ data }) => {
        if (data.id) {
          context.commit('ONCALLSCHEDULE_LIST', data)
        }
      }).catch(() => {})
  },
  oncallscheduleUpdated (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    const inputs = { }
    if (opts && Array.isArray(opts.populate) && opts.populate.length > 0) {
      inputs.params = { }
      inputs.params.populate = opts.populate
    }
    if (context.state.current.id === id) {
      axios
        .get(`/api/oncallschedule/${id}`, inputs)
        .then(({ data }) => {
          if (context.state.current.id === data.id) {
            data.socketUpdate = true
            context.commit('ONCALLSCHEDULE_CURRENT', data)
          }
        }).catch(() => {})
    }
  },
  // ===========================List Edits=====================
  oncallscheduleUpload (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/oncallschedule/upload', opts)
        .then(({ data }) => {
          context.commit('ONCALLSCHEDULE_CURRENT', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  oncallscheduleDelete (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/api/oncallschedule/${opts}`)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  // ===========================Item Management=====================
  oncallscheduleDetail (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/oncallschedule/${opts}`)
        .then(({ data }) => {
          context.commit('ONCALLSCHEDULE_CURRENT', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  oncallscheduleUpdate (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/api/oncallschedule/${context.state.current.id}`, { user: context.state.update.user })
        .then(({ data }) => {
          context.commit('ONCALLSCHEDULE_CURRENT_USER', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  }
}
