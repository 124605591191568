export default function () {
  return {
    current: {
      active: false,
      address: null,
      alarm: false,
      building: null,
      changeDetected: false,
      childHealthChecks: [],
      childPoints: [],
      controllers: [],
      deviceId: 0,
      discover: false,
      firmware: '',
      healthChecks: [],
      history: {},
      id: '',
      ip: '',
      model: '',
      name: '',
      network: null,
      notes: false,
      parentController: null,
      path: '',
      pointCount: 0,
      points: [],
      protocol: '',
      reenableTime: null,
      serial: '',
      status: '',
      system: null,
      triggered: false
    },
    list: [],
    childControllerList: [],
    parent: {
      active: false,
      address: null,
      building: null,
      changeDetected: false,
      childHealthChecks: [],
      childPoints: [],
      controllers: [],
      deviceId: 0,
      discover: false,
      firmware: '',
      healthChecks: [],
      id: '',
      ip: '',
      model: '',
      name: '',
      network: null,
      parentController: null,
      path: '',
      pointCount: 0,
      points: [],
      protocol: '',
      reenableTime: null,
      serial: '',
      status: '',
      system: null
    },
    update: {
      active: false,
      building: null,
      changeDetected: false,
      discover: false,
      id: '',
      ip: '',
      network: null,
      path: '',
      protocol: '',
      reenableTime: null
    },
    canInactivateForever: true
  }
}
