import axios from 'axios'

export default {
  userorgList (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/userorg', { params: opts })
        .then(({ data }) => {
          context.commit('USERORG_LIST', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  userorgFindByUserAndOrg (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/userorg', { params: opts })
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  userorgAdd (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/userorg', opts)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  userorgUnsubscribe (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/userorg/unsubscribe?token=${opts.token}&option=${opts.option}`)
        .then(() => resolve())
        .catch((error) => reject(error))
    })
  },
  userorgDelete (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/api/userorg/${opts}`)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  userorgDestroyed (context, opts) {
    if (opts.id && context.state.list.find(i => i.id === opts.id)) {
      context.commit('USERORG_LIST_REMOVE', opts)
    }
  },
  userorgUpdate (context, opts) {
    const id = opts.id
    delete opts.id
    return new Promise((resolve, reject) => {
      axios
        .patch(`/api/userorg/${id}`, Object.assign({}, opts))
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  userorgCreated (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    const permissions = context.rootState.User.permissions
    const readOrg = permissions.organization?.read
    const readUser = ((permissions.user?.read && !opts.isDay) || permissions.day?.read)
    const currentOrg = context.rootState.Organization.current.id
    const createdUser = context.rootState.User.create.id
    if (readUser && readOrg && (currentOrg === opts.organization || createdUser === opts.user)) {
      axios
        .get(`/api/userorg/${id}`)
        .then(({ data }) => {
          if (data.id) {
            context.commit('USERORG_LIST_UPDATE', data)
          }
        }).catch(() => {})
    }
  },
  userorgUpdated (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    const listItem = context.state.list.find((i) => i.id === id)
    if (listItem) {
      axios
        .get(`/api/userorg/${id}`)
        .then(({ data }) => {
          context.commit('USERORG_LIST_UPDATE', data)
        }).catch(() => {
        })
    }
  }
}
