import axios from 'axios'

export default {
  bookmarkAdd (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/bookmark', opts)
        .then(({ data }) => {
          context.commit('USER_UPDATE_LOGGEDIN_BOOKMARKS', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  bookmarkDelete (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    return new Promise((resolve, reject) => {
      axios
        .delete(`/api/bookmark/${id}`)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  }
}
