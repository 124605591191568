import axios from 'axios'
export default {
  // ==========================List Management=============================
  rpiclientList (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/rpiclient', { params: opts })
        .then(({ data }) => {
          context.commit('RPI_CLIENT_LIST', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  rpiclientUpdate (context, opts) {
    if (opts.id && opts.organization) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/api/rpiclient/${opts.id}`, { organization: opts.organization })
          .then(({ data }) => {
            resolve(data)
          }).catch((error) => reject(error))
      })
    }
  },
  rpiClientRestart (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/rpiclient/restart', opts)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  }
}
