export default {
  UPDATE_LOCKDOWN_CONFIG (state, value) {
    state.current = state.default
    if (value) {
      for (const prop of Object.keys(state.default)) {
        if (prop === 'servers') {
          state.current.servers = value.servers
        } else {
          if (value[prop] && state.current[prop] && value[prop].count !== state.current[prop].count) {
            state.current[prop].count = value[prop].count
          }
          if (value[prop] && state.current[prop] && value[prop].alarms !== state.current[prop].alarms) {
            state.current[prop].alarms = value[prop].alarms
          }
        }
      }
    } else {
      state.current = structuredClone(state.default)
    }
  }
}
