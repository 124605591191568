export default {
  BACK_TO (state, value) {
    state.backTo = value
  },
  INSTALL_PROMPT (state, value) {
    state.installPrompt = value
  },
  INSTALLED (state, value) {
    state.installed = value
  },
  UPDATE_AVAILABLE (state, value) {
    state.updateAvailable = value
  },
  REGISTRATION (state, value) {
    state.registration = value
  }
}
