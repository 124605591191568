export default function () {
  return {
    current: {
      id: '',
      acmAppliance: '',
      active: false,
      alias: '',
      building: '',
      dn: '',
      ip: '',
      history: {},
      installed: false,
      name: '',
      reenableTime: '',
      status: '',
      system: '',
      notes: false
    },
    update: {
      alias: null,
      active: false,
      reenableTime: null
    },
    list: [],
    canInactivateForever: true
  }
}
