export default {
  GLOBAL_ACTION_LIST (state, value) {
    state.list = (value && Array.isArray(value)) ? [...(value.sort((a, b) => a.name.localeCompare(b.name)))] : []
  },
  GLOBAL_ACTION_LIST_UPDATE (state, value) {
    const index = state.list.findIndex((i) => i.id === value.id)
    if (index !== -1) {
      state.list.splice(index, 1, Object.assign(state.list[index], value))
    } else {
      state.list.push(value)
    }
  },
  GLOBAL_ACTION_CURRENT (state, value) {
    const props = [
      'building',
      'dn',
      'libraryType',
      'name',
      'priority',
      'qualifier',
      'system',
      'toggle',
      'type',
      'typeName',
      'vendorId'
    ]
    if (value) {
      for (const prop of props) {
        if (value[prop] !== state.current[prop]) {
          state.current[prop] = value[prop]
        }
      }
      for (const prop of ['id', 'active', 'reenableTime']) {
        if (value[prop] !== state.current[prop]) {
          state.current[prop] = value[prop]
          state.update[prop] = value[prop]
        }
      }
    } else {
      state.current = {
        id: '',
        active: false,
        building: '',
        dn: '',
        libraryType: 0,
        name: '',
        priority: 0,
        qualifier: 0,
        reenableTime: '',
        system: '',
        toggle: false,
        type: 0,
        typeName: '',
        vendorId: ''
      }
      state.update = {
        id: '',
        active: false,
        building: '',
        reenableTime: null
      }
    }
  },
  GLOBAL_ACTION_UPDATE_ACTIVE (state, value) {
    state.update.active = value
  },
  GLOBAL_ACTION_UPDATE_REENABLE_TIME (state, value) {
    state.update.reenableTime = value
  },
  GLOBAL_ACTION_UPDATE_BUILDING (state, value) {
    state.update.building = value
  },
  GLOBAL_ACTION_FETCHING_LIST (state, value) {
    state.fetchingList = value
  }
}
