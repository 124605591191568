import axios from 'axios'

export default {
  versionhistoryList (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/versionhistory', { params: opts })
        .then(({ data }) => {
          context.commit('VERSIONHISTORY_LIST', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  }
}
