export default {
  ALARMS (state, value) {
    state.current = state.default
    if (value) {
      state.loaded = true
      for (const prop of Object.keys(state.default)) {
        if (value[prop] !== state.current[prop]) {
          state.current[prop] = value[prop]
        }
      }
    }
  },
  COUNTS (state, value) {
    if (value) {
      state.counts = value
    }
  },
  UPDATE_SYSTEM_COUNTS (state, value) {
    if (value?.kind && value?.data) {
      state.systems[value.kind] = value.data
    } else if (!value) {
      for (const key of Object.keys(state.systems)) {
        state.systems[key] = {}
      }
    }
  }
}
