export default {
  SET_SYSTEM_COUNT (state, value) {
    state.count = value
  },
  SYSTEM_CREATE_ID (state, value) {
    state.create.id = value
  },
  SYSTEM_CREATE_ALARM_2_DELAY (state, value) {
    state.create.alarm2Delay = value
  },
  SYSTEM_CREATE_ALARM_3_DELAY (state, value) {
    state.create.alarm3Delay = value
  },
  SYSTEM_CREATE_ALLOW_IMAGES (state, value) {
    state.create.allowImages = value
  },
  SYSTEM_CREATE_BACKUP_ID (state, value) {
    state.create.backupId = value
  },
  SYSTEM_CREATE_BBMD (state, value) {
    state.create.bbmd = value
  },
  SYSTEM_CREATE_DAY_CONTACT (state, value) {
    state.create.dayContact = value
  },
  SYSTEM_CREATE_DEFAULT_RECORDING (state, value) {
    state.create.defaultRecording = value
  },
  SYSTEM_CREATE_DEFAULT_RETENTION (state, value) {
    state.create.defaultRetention = value
  },
  SYSTEM_CREATE_KIND (state, value) {
    state.create.kind = value
  },
  SYSTEM_CREATE_REPEAT_ALARM_3 (state, value) {
    state.create.repeatAlarm3 = value
  },
  SYSTEM_CREATE_RESET (state, value) {
    state.create.id = value
    state.create.allowImages = value
    state.create.backupId = value
    state.create.bbmd = value
    state.create.kind = value
  },
  SYSTEM_CREATE_TIMEOUT (state, value) {
    state.create.timeout = value
  },
  SYSTEM_CURRENT (state, value) {
    if (value) {
      const props = [
        'active',
        'alarm2Delay',
        'alarm3Delay',
        'allowImages',
        'allowUpgrade',
        'backupId',
        'bbmd',
        'checkInterval',
        'dayContact',
        'defaultRecording',
        'defaultRetention',
        'hasClient',
        'id',
        'isMonitored',
        'kind',
        'recordInformacastLogs',
        'repeatAlarm3',
        'reenableTime',
        'resourceCheckInterval',
        'serviceDivision',
        'timeout'
      ]
      for (const prop of props) {
        if (value[prop] !== state.current[prop]) {
          state.current[prop] = value[prop]
        }
        if (value[prop] !== state.update[prop]) {
          state.update[prop] = value[prop]
        }
      }
      const attributes = [
        'checkFinished',
        'checkStarted',
        'clientVersion',
        'externalIp',
        'internalIp',
        'keyConnected',
        'lastLog',
        'organization',
        'status'
      ]
      for (const prop of attributes) {
        if (value[prop] !== state.current[prop]) {
          state.current[prop] = value[prop]
        }
      }
      if (!value.socketUpdate) {
        state.current.serviceDivision = value.serviceDivision || null
        state.update.serviceDivision = state.current.serviceDivision || null
      }
    } else {
      state.current = {
        id: '',
        active: false,
        alarm2Delay: 0,
        alarm3Delay: 0,
        allowImages: false,
        allowUpgrade: false,
        backupId: '',
        bbmd: '',
        checkFinished: null,
        checkInterval: 600,
        checkStarted: null,
        clientVersion: '',
        dayContact: '',
        defaultRecording: 0,
        defaultRetention: 0,
        externalIp: '',
        hasClient: false,
        healthChecks: [],
        internalIp: '',
        isMonitored: false,
        keyConnected: false,
        kind: '',
        lastLog: '',
        organization: null,
        recordInformacastLogs: false,
        reenableTime: null,
        repeatAlarm3: false,
        serviceDivision: {},
        status: '',
        timeout: 15,
        cameras: [],
        encoders: [],
        hvacControllers: [],
        points: [],
        servers: []
      }
      state.update = {
        active: false,
        alarm2Delay: 0,
        alarm3Delay: 0,
        allowImages: false,
        allowUpgrade: false,
        backupId: null,
        bbmd: '',
        checkInterval: 600,
        dayContact: '',
        defaultRecording: 0,
        defaultRetention: 0,
        hasClient: false,
        id: '',
        isMonitored: false,
        kind: '',
        recordInformacastLogs: false,
        reenableTime: null,
        repeatAlarm3: false,
        serviceDivision: null,
        timeout: 15
      }
    }
  },
  SYSTEM_HVACCONTROLLER_LIST_PATCH (state, value) {
    const index = state.current.hvacControllers.findIndex(c => c.id === value.id)
    if (index > -1) {
      state.current.hvacControllers.splice(index, 1, value)
    }
  },
  SYSTEM_INACTIVE (state, value) {
    if (value) {
      state.inactive = value
    } else {
      state.inactive = {}
    }
  },
  SYSTEM_LIST (state, value) {
    state.list = (value && Array.isArray(value)) ? [...(value.sort((a, b) => a.kind.localeCompare(b.kind)))] : []
  },
  SYSTEM_LIST_ITEM_REMOVE (state, value) {
    const id = typeof value === 'string' ? value : typeof value === 'object' && value.id ? value.id : ''
    const index = state.list.findIndex(c => c.id === id)
    if (index > -1) {
      state.list.splice(index, 1)
    }
  },
  SYSTEM_LIST_UPDATE (state, value) {
    const index = state.list.findIndex((i) => i.id === value.id)
    if (index !== -1) {
      state.list.splice(index, 1, Object.assign(state.list[index], value))
    } else {
      state.list.push(Object.assign({}, value)) // reason: see fetchModel.js
    }
  },
  SYSTEM_ADD_INACTIVE (state, value) {
    if (value && value.id && value.kind) {
      if (!state.inactive[value.kind]) {
        state.inactive[value.kind] = []
      }
    }
  },
  SYSTEM_REMOVE_INACTIVE (state, value) {
    if (state.inactive[value.kind]) {
      const clone = [...state.inactive[value.kind]]
      const index = clone.findIndex((i) => i.id === value.id)
      if (index !== -1) {
        clone.splice(index, 1)
        state.inactive[value.kind] = clone
        state.inactive = { ...state.inactive }
      }
    }
  },
  SET_SYSTEM_OK (state, value) {
    state.ok = value
  },
  SYSTEM_CURRENT_HEALTH_CHECKS (state, value) {
    state.current.healthChecks = value
  },
  SYSTEM_UPDATE_ACTIVE (state, value) {
    state.update.active = value
  },
  SYSTEM_UPDATE_ALARM_2_DELAY (state, value) {
    state.update.alarm2Delay = value
  },
  SYSTEM_UPDATE_ALARM_3_DELAY (state, value) {
    state.update.alarm3Delay = value
  },
  SYSTEM_UPDATE_ALLOW_IMAGES (state, value) {
    state.update.allowImages = value
  },
  SYSTEM_UPDATE_ALLOW_UPGRADE (state, value) {
    state.update.allowUpgrade = value
  },
  SYSTEM_UPDATE_BBMD (state, value) {
    state.update.bbmd = value
  },
  SYSTEM_UPDATE_CHECK_INTERVAL (state, value) {
    state.update.checkInterval = value
  },
  SYSTEM_UPDATE_CHILD (state, opts) { // { id: '', key: '', value: '', childKind: ''}
    for (const child of state.current[opts.childKind]) {
      if (child.id === opts.id) {
        child[opts.key] = opts.value
      }
    }
  },
  SYSTEM_UPDATE_DAY_CONTACT (state, value) {
    state.update.dayContact = value
  },
  SYSTEM_UPDATE_DEFAULT_RETENTION (state, value) {
    state.update.defaultRetention = value
  },
  SYSTEM_UPDATE_DEFAULT_RECORDING (state, value) {
    state.update.defaultRecording = value
  },
  SYSTEM_UPDATE_KIND (state, value) {
    state.update.kind = value
  },
  SYSTEM_UPDATE_HAS_CLIENT (state, value) {
    state.update.hasClient = value
  },
  SYSTEM_UPDATE_IS_MONITORED (state, value) {
    state.update.isMonitored = value
  },
  SYSTEM_UPDATE_REENABLE_TIME (state, value) {
    state.update.reenableTime = value
  },
  SYSTEM_UPDATE_RECORD_INFORMACAST_LOGS (state, value) {
    state.update.recordInformacastLogs = value
  },
  SYSTEM_UPDATE_REPEAT_ALARM_3 (state, value) {
    state.update.repeatAlarm3 = value
  },
  SYSTEM_UPDATE_RESOURCE_CHECK_INTERVAL (state, value) {
    state.update.resourceCheckInterval = value
  },
  SYSTEM_UPDATE_SERVICE_DIVISION (state, value) {
    state.update.serviceDivision = value
  },
  SYSTEM_UPDATE_TIMEOUT (state, value) {
    state.update.timeout = value
  },
  SYSTEM_UPDATE_BACKUP_ID (state, value) {
    state.update.backupId = value
  }
}
