export default function () {
  return {
    current: {
      id: '',
      name: '',
      manager: {},
      managers: [],
      systems: [],
      users: []
    },
    update: {
      id: null,
      name: null,
      manager: {},
      managers: []
    },
    list: []
  }
}
