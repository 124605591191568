import version from '../version'

export default function () {
  return {
    backTo: null,
    version,
    installPrompt: null,
    installed: false,
    updateAvailable: false,
    registration: null,
    enableMobileAnimations: false
  }
}
