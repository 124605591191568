export default function () {
  return {
    current: {
      id: '',
      name: '',
      organization: '',
      viewStyle: 'mobile',
      share: false,
      containers: []
    },
    list: [],
    update: {
      id: null,
      name: '',
      organization: '',
      viewStyle: 'mobile',
      share: false,
      containers: []
    },
    dirty: false,
    defaultContainerCount: 18
  }
}
