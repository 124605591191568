export default {
  ENCODER_CURRENT (state, value) {
    if (value) {
      for (const prop of ['id', 'active', 'reenableTime']) {
        if (value[prop] !== state.current[prop]) {
          state.current[prop] = value[prop]
          state.update[prop] = value[prop]
        }
      }
      for (const prop of ['system', 'vendorId', 'name', 'building', 'location', 'model', 'serialNumber', 'manufacturer', 'ip', 'mac', 'logicalId', 'firmwareVersion', 'operatingPriority', 'status', 'deletedAt', 'notes']) {
        if (value[prop] !== state.current[prop]) {
          state.current[prop] = value[prop]
        }
      }
      if (state.current.building && state.current.building.id) {
        if (state.update.building !== state.current.building.id) {
          state.update.building = state.current.building.id
        }
      } else {
        if (state.update.building !== null) {
          state.update.building = null
        }
      }
      if (!value.socketUpdate) {
        state.current.cameras = value.cameras
      }
    } else {
      state.current = {
        id: '',
        vendorId: '',
        name: '',
        location: '',
        model: '',
        serialNumber: '',
        manufacturer: '',
        ip: '',
        mac: '',
        logicalId: '',
        firmwareVersion: '',
        operatingPriority: 0,
        active: false,
        status: '',
        deletedAt: null,
        reenableTime: null,
        building: null,
        system: null,
        encoder: null,
        healthChecks: [],
        cameras: [],
        notes: false
      }
      state.update = {
        id: '',
        building: null,
        active: false,
        reenableTime: null
      }
    }
  },
  ENCODER_CURRENT_NOTES (state, value) {
    state.current.notes = value
  },
  ENCODER_LIST (state, value) {
    state.list = (value && Array.isArray(value)) ? [...(value.sort((a, b) => a.name.localeCompare(b.name)))] : []
  },
  ENCODER_LIST_ITEM_REMOVE (state, value) {
    const id = typeof value === 'string' ? value : typeof value === 'object' && value.id ? value.id : ''
    const index = state.list.findIndex(c => c.id === id)
    if (index > -1) {
      state.list.splice(index, 1)
    }
  },
  ENCODER_CURRENT_HISTORY (state, value) {
    if (value && Object.keys(value).length > 0) {
      state.current.history = value
    } else {
      state.current.history = {}
    }
  },
  ENCODER_LIST_UPDATE (state, value) {
    const index = state.list.findIndex((i) => i.id === value.id)
    if (index !== -1) {
      state.list.splice(index, 1, Object.assign(state.list[index], value))
    } else {
      state.list.push(value) // see fetchModel.js
    }
  },
  ENCODER_UPDATE_NAME (state, value) {
    state.update.name = value
  },
  ENCODER_UPDATE_ACTIVE (state, value) {
    state.update.active = value
  },
  ENCODER_UPDATE_REENABLE_TIME (state, value) {
    state.update.reenableTime = value
  },
  ENCODER_UPDATE_BUILDING (state, value) {
    state.update.building = value
  }
}
