import axios from 'axios'

export default {
  getToken (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/token/${opts}`)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  }
}
