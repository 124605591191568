export default {
  GROUP_LIST (state, value) {
    state.list = (value && Array.isArray(value)) ? [...(value.sort((a, b) => a.name.localeCompare(b.name)))] : []
  },
  GROUP_LIST_ADD (state, value) {
    if (state.list.findIndex((i) => i.id === value.id) === -1) {
      state.list.push({
        id: value.id,
        name: value.name,
        users: value.users.length,
        permissions: value.permissions.length
      })
    }
  },
  GROUP_LIST_ITEM_REMOVE (state, value) {
    const id = typeof value === 'string' ? value : typeof value === 'object' && value.id ? value.id : ''
    const index = state.list.findIndex((i) => i.id === id)
    if (index !== -1) {
      state.list.splice(index, 1)
    }
  },
  GROUP_LIST_UPDATE (state, value) {
    const index = state.list.findIndex((i) => i.id === value.id)
    if (index !== -1) {
      state.list.splice(index, 1, Object.assign(state.list[index], value))
    } else {
      state.list.push(value)
    }
  },
  GROUP_CURRENT (state, value) {
    // need to make a more graceful update to minimize screen disruptions
    if (value) {
      for (const prop of ['id', 'name', 'description', 'dayDefault', 'rank', 'userDefault']) {
        if (value[prop] !== state.current[prop]) {
          state.current[prop] = value[prop]
          state.update[prop] = value[prop]
        }
      }
    } else {
      state.current = {
        dayDefault: false,
        description: '',
        id: '',
        name: '',
        rank: 10,
        userDefault: false
      }
      state.update = {
        dayDefault: false,
        description: '',
        id: '',
        name: '',
        rank: 10,
        userDefault: false
      }
    }
  },
  GROUP_UPDATE_DAY_DEFAULT (state, value) {
    state.update.dayDefault = value
  },
  GROUP_UPDATE_DESCRIPTION (state, value) {
    state.update.description = value
  },
  GROUP_UPDATE_NAME (state, value) {
    state.update.name = value
  },
  GROUP_UPDATE_RANK (state, value) {
    state.update.rank = value
  },
  GROUP_UPDATE_USER_DEFAULT (state, value) {
    state.update.userDefault = value
  }
}
