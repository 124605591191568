export default function () {
  return {
    current: {
      active: null,
      id: '',
      name: '',
      dn: '',
      port: 0,
      reenableTime: '',
      system: '',
      versionNumber: ''
    },
    list: []
  }
}
