import axios from 'axios'
export default {
  // ===========================List Edits=====================
  userservicedivisionList (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/userservicedivision', { params: opts })
        .then(({ data }) => {
          context.commit('USERSERVICEDIVISION_LIST', data)
          resolve(data)
        }).catch((error) => {
          context.commit('USERSERVICEDIVISION_LIST', null)
          reject(error)
        })
    })
  },
  userservicedivisionDestroyed (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    const inList = context.state.current.list.find((i) => i.id === id)
    if (inList) {
      context.commit('USERSERVICEDIVISION_LIST_ITEM_REMOVE', opts)
    }
  },
  userservicedivisionUpdate (context, opts) {
    // opts is the user.id. .patch requires an Id so why not put the user id in there since it is needed on the backend
    return new Promise((resolve, reject) => {
      axios
        .patch(`/api/userservicedivision/${opts}`, { userservicedivisions: context.state.update.list })
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  userservicedivisionDelete (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .delete('/api/userservicedivision', { data: opts })
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  }
}
