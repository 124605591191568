import axios from 'axios'

export default {
  usergroupList (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/usergroup', { params: opts })
        .then(({ data }) => {
          context.commit('USERGROUP_LIST', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  usergroupAdd (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/usergroup', opts)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  usergroupDelete (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .delete('/api/usergroup', { params: { user: opts.user, group: opts.group } })
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  usergroupDestroyed (context, opts) {
    const id = typeof opts === 'string' ? opts : opts && opts.id ? opts.id : '#'
    if (context.state.list.find((i) => i.id === id)) {
      context.commit('USERGROUP_LIST_REMOVE', id)
    }
  }
}
