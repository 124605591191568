export default {
  HEALTHCHECKDEF_LIST (state, value) {
    state.list = (value && Array.isArray(value)) ? [...(value.sort((a, b) => a.name.localeCompare(b.name)))] : []
  },
  HEALTHCHECKDEF_LIST_ADD (state, value) {
    if (state.list.findIndex((i) => i.id === value.id) === -1) {
      state.list.push(value)
    }
  },
  HEALTHCHECKDEF_LIST_REMOVE (state, value) {
    const index = state.list.findIndex((i) => i.id === value)
    if (index !== -1) {
      state.list.splice(index, 1)
    }
  },
  HEALTHCHECKDEF_LIST_UPDATE (state, value) {
    const index = state.list.findIndex((i) => i.id === value.id)
    if (index !== -1) {
      state.list.splice(index, 1, Object.assign(state.list[index], value))
    } else {
      state.list.push(index, 1, value)
    }
  },
  HEALTHCHECKDEF_CURRENT (state, value) {
    if (value) {
      const props = [
        'aggregation',
        'aggregationLowerIsWorse',
        'aggregationThreshold',
        'alarmEmailSubject',
        'conditions',
        'defaultThreshold',
        'delay',
        'description',
        'id',
        'kind',
        'lowerIsWorse',
        'name',
        'normalEmailSubject',
        'showTriggeredValue',
        'unitOfMeasure'
      ]
      for (const prop of props) {
        if (value[prop] !== state.current[prop]) {
          state.current[prop] = value[prop]
          state.update[prop] = value[prop]
        }
      }
      for (const prop of ['resourceType', 'organization', 'systemKind', 'protected']) {
        if (value[prop] !== state.current[prop]) {
          state.current[prop] = value[prop]
        }
      }
    } else {
      state.current = {
        aggregation: '',
        aggregationLowerIsWorse: false,
        aggregationThreshold: 0,
        alarmEmailSubject: null,
        conditions: '',
        defaultThreshold: 0,
        delay: 0,
        description: '',
        id: '',
        kind: '',
        lowerIsWorse: false,
        name: '',
        normalEmailSubject: null,
        organization: '',
        protected: false,
        resourceType: '',
        systemKind: '',
        unitOfMeasure: ''
      }
      state.update = {
        aggregation: '',
        aggregationLowerIsWorse: false,
        aggregationThreshold: 0,
        alarmEmailSubject: null,
        conditions: '',
        defaultThreshold: 0,
        delay: 0,
        description: '',
        id: '',
        kind: '',
        lowerIsWorse: false,
        name: '',
        normalEmailSubject: null,
        unitOfMeasure: ''
      }
    }
  },
  HEALTHCHECKDEF_UPDATE_AGGREGATION (state, value) {
    state.update.aggregation = value
  },
  HEALTHCHECKDEF_UPDATE_AGGREGATION_LOWER_IS_WORSE (state, value) {
    state.update.aggregationLowerIsWorse = value
  },
  HEALTHCHECKDEF_UPDATE_AGGREGATION_THRESHOLD (state, value) {
    state.update.aggregationThreshold = value
  },
  HEALTHCHECKDEF_UPDATE_ALARM_EMAIL_SUBJECT (state, value) {
    state.update.alarmEmailSubject = value
  },
  HEALTHCHECKDEF_UPDATE_CONDITIONS (state, value) {
    state.update.conditions = value
  },
  HEALTHCHECKDEF_UPDATE_DELAY (state, value) {
    state.update.delay = value > 0 ? value : null
  },
  HEALTHCHECKDEF_UPDATE_DEFAULT_THRESHOLD (state, value) {
    state.update.defaultThreshold = value
  },
  HEALTHCHECKDEF_UPDATE_DESCRIPTION (state, value) {
    state.update.description = value
  },
  HEALTHCHECKDEF_UPDATE_KIND (state, value) {
    state.update.kind = value
  },
  HEALTHCHECKDEF_UPDATE_LOWER_IS_WORSE (state, value) {
    state.update.lowerIsWorse = value
  },
  HEALTHCHECKDEF_UPDATE_NAME (state, value) {
    state.update.name = value
  },
  HEALTHCHECKDEF_UPDATE_NORMAL_EMAIL_SUBJECT (state, value) {
    state.update.normalEmailSubject = value
  },
  HEALTHCHECKDEF_UPDATE_SHOW_TRIGGERED_VALUE (state, value) {
    state.update.showTriggeredValue = value
  },
  HEALTHCHECKDEF_UPDATE_UNIT_OF_MEASURE (state, value) {
    state.update.unitOfMeasure = value
  }
}
