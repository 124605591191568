export default {
  INFORMACASTOUTBOUNDEMAIL_LIST (state, value) {
    state.list = (value && Array.isArray(value)) ? [...(value.sort((a, b) => a.smtpServer.localeCompare(b.smtpServer)))] : []
  },
  INFORMACASTOUTBOUNDEMAIL_LIST_UPDATE (state, value) {
    const index = state.list.findIndex((i) => i.id === value.id)
    if (index !== -1) {
      state.list.splice(index, 1, Object.assign(state.list[index], value))
    } else {
      state.list.push(value)
    }
  },
  INFORMACASTOUTBOUNDEMAIL_CURRENT (state, value) {
    const props = [
      'applicationVersion',
      'application',
      'description',
      'expiration',
      'fromAddress',
      'fromName',
      'ipAddressRestriction',
      'ip',
      'issuer',
      'licensee',
      'name',
      'replyToAddress',
      'smtpPort',
      'smtpServer',
      'smtpUser',
      'system',
      'vendorId'
    ]
    if (value) {
      for (const prop of props) {
        if (value[prop] !== state.current[prop]) {
          state.current[prop] = value[prop]
        }
      }
      for (const prop of ['id', 'active', 'reenableTime', 'building']) {
        if (value[prop] !== state.current[prop]) {
          state.current[prop] = value[prop]
          state.update[prop] = value[prop]
        }
      }
    } else {
      state.current = {
        id: '',
        active: false,
        building: '',
        applicationVersion: '',
        application: '',
        description: '',
        expiration: '',
        fromAddress: '',
        fromName: '',
        ipAddressRestriction: '',
        ip: '',
        issuer: '',
        licensee: '',
        name: '',
        replyToAddress: '',
        smtpPort: 0,
        smtpServer: '',
        smtpUser: '',
        reenableTime: null,
        system: '',
        vendorId: ''
      }
      state.update = {
        id: '',
        active: false,
        building: '',
        reenableTime: null
      }
    }
  },
  INFORMACASTOUTBOUNDEMAIL_UPDATE_ACTIVE (state, value) {
    state.update.active = value
  },
  INFORMACASTOUTBOUNDEMAIL_UPDATE_REENABLE_TIME (state, value) {
    state.update.reenableTime = value
  },
  INFORMACASTOUTBOUNDEMAIL_UPDATE_BUILDING (state, value) {
    state.update.building = value
  },
  INFORMACASTOUTBOUNDEMAIL_FETCHING_LIST (state, value) {
    state.fetchingList = value
  }
}
