export default {
  HELPPAGE_CURRENT (state, value) {
    if (value) {
      for (const prop of ['path', 'content', 'videoSrc', 'modelPermission', 'actionPermission']) {
        if (value[prop] !== state.current[prop]) {
          state.current[prop] = value[prop]
          state.update[prop] = value[prop]
        }
      }
    } else {
      state.current = {
        path: '',
        content: '',
        videoSrc: '',
        modelPermission: '',
        actionPermission: ''
      }
      state.update = {
        content: '',
        videoSrc: '',
        modelPermission: '',
        actionPermission: ''
      }
    }
  },
  HELPPAGE_UPDATE_CONTENT (state, value) {
    state.update.content = value
  },
  HELPPAGE_UPDATE_MODEL_PERMISSION (state, value) {
    state.update.modelPermission = value
  },
  HELPPAGE_UPDATE_ACTION_PERMISSION (state, value) {
    state.update.actionPermission = value
  },
  HELPPAGE_UPDATE_VIDEO_SRC (state, value) {
    state.update.videoSrc = value
  }
}
