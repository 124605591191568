import axios from 'axios'

export default {
  userfeedbackAdd (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/userfeedback', opts)
        .then(({ data }) => {
          context.commit('USER_UPDATE_LOGGEDIN_FEEDBACKS', data)
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  userfeedbackUpdate (context, opts) {
    if (opts.id && (opts.rating || opts.feedback)) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/api/userfeedback/${opts.id}`, opts)
          .then(({ data }) => {
            context.commit('USER_UPDATE_LOGGEDIN_FEEDBACKS', data)
            resolve(data)
          }).catch((error) => reject(error))
      })
    }
  }
}
