export default function () {
  return {
    current: {
      id: '',
      kind: '',
      year: 2000,
      resource: null,
      resourceType: '',
      janAmount: 0,
      febAmount: 0,
      marAmount: 0,
      aprAmount: 0,
      mayAmount: 0,
      junAmount: 0,
      julAmount: 0,
      augAmount: 0,
      sepAmount: 0,
      octAmount: 0,
      novAmount: 0,
      decAmount: 0,
      rate: 0,
      units: ''
    },
    list: [],
    update: {
      id: '',
      kind: '',
      year: 2000,
      janAmount: 0,
      febAmount: 0,
      marAmount: 0,
      aprAmount: 0,
      mayAmount: 0,
      junAmount: 0,
      julAmount: 0,
      augAmount: 0,
      sepAmount: 0,
      octAmount: 0,
      novAmount: 0,
      decAmount: 0,
      rate: 0,
      units: ''
    },
    kinds: [
      'Electric',
      'Gas',
      'Steam',
      'Water'
    ]
  }
}
