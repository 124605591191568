import axios from 'axios'

export default {
  energyBudgetAll (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/energy/budget/all?startDate=${opts.startDate}&endDate=${opts.endDate}`)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  energyBudgetOrganization (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/energy/budget/organization?startDate=${opts.startDate}&endDate=${opts.endDate}&id=${opts.id}`)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  },
  energyBudgetBuilding (context, opts) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/energy/budget/building?startDate=${opts.startDate}&endDate=${opts.endDate}&id=${opts.id}`)
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => reject(error))
    })
  }
}
