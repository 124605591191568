export default function () {
  return {
    current: {
      boschPanelPoints: { count: 0, alarms: [] },
      boschPanels: { count: 0, alarms: [] },
      dialcasts: { count: 0, alarms: [] },
      doors: { count: 0, alarms: [] },
      globalActions: { count: 0, alarms: [] },
      m2ms: { count: 0, alarms: [] },
      messages: { count: 0, alarms: [] },
      panels: { count: 0, alarms: [] },
      policies: { count: 0, alarms: [] },
      recipientGroups: { count: 0, alarms: [] },
      servers: {}
    },
    default: {
      boschPanelPoints: { count: 0, alarms: [] },
      boschPanels: { count: 0, alarms: [] },
      dialcasts: { count: 0, alarms: [] },
      doors: { count: 0, alarms: [] },
      globalActions: { count: 0, alarms: [] },
      m2ms: { count: 0, alarms: [] },
      messages: { count: 0, alarms: [] },
      panels: { count: 0, alarms: [] },
      policies: { count: 0, alarms: [] },
      recipientGroups: { count: 0, alarms: [] },
      servers: { }
    }
  }
}
