export default function () {
  return {
    loaded: false,
    HVAC: {
      current: {
        id: '',
        healthCheckTotal: 0,
        healthCheckAlarms: 0,
        healthChecks: null,
        history: []
      },
      healthCheckTotal: 0,
      healthCheckAlarms: 0,
      resources: {},
      healthChecks: null,
      history: []
    },
    Energy: {
      current: {
        id: '',
        healthCheckTotal: 0,
        healthCheckAlarms: 0,
        healthChecks: null,
        history: []
      },
      healthCheckTotal: 0,
      healthCheckAlarms: 0,
      resources: {},
      healthChecks: {},
      history: []
    },
    Lighting: {
      current: {
        id: '',
        healthCheckTotal: 0,
        healthCheckAlarms: 0,
        healthChecks: null,
        history: []
      },
      healthCheckTotal: 0,
      healthCheckAlarms: 0,
      resources: {},
      healthChecks: {},
      history: []
    },
    Access: {
      current: {
        id: '',
        healthCheckTotal: 0,
        healthCheckAlarms: 0,
        healthChecks: null,
        history: []
      },
      healthCheckTotal: 0,
      healthCheckAlarms: 0,
      resources: {},
      healthChecks: {},
      history: []
    },
    Massnotification: {
      current: {
        id: '',
        healthCheckTotal: 0,
        healthCheckAlarms: 0,
        healthChecks: null,
        history: []
      },
      healthCheckTotal: 0,
      healthCheckAlarms: 0,
      resources: {},
      healthChecks: {},
      history: []
    },
    Surveillance: {
      current: {
        id: '',
        healthCheckTotal: 0,
        healthCheckAlarms: 0,
        healthChecks: null,
        history: []
      },
      healthCheckTotal: 0,
      healthCheckAlarms: 0,
      resources: {},
      healthChecks: {},
      history: []
    },
    default: {
      loaded: false,
      HVAC: {
        current: {
          id: '',
          healthCheckAlarms: 0,
          healthCheckTotal: 0,
          healthChecks: {},
          history: []
        },
        resources: {},
        healthChecks: {},
        history: []
      },
      Energy: {
        current: {
          id: '',
          healthCheckTotal: 0,
          healthCheckAlarms: 0,
          healthChecks: {},
          history: []
        },
        resources: {},
        healthChecks: {},
        history: []
      },
      Lighting: {
        current: {
          id: '',
          healthCheckTotal: 0,
          healthCheckAlarms: 0,
          healthChecks: {},
          history: []
        },
        resources: {},
        healthChecks: {},
        history: []
      },
      Access: {
        current: {
          id: '',
          healthCheckTotal: 0,
          healthCheckAlarms: 0,
          healthChecks: {},
          history: []
        },
        resources: {},
        healthChecks: {},
        history: []
      },
      Surveillance: {
        current: {
          id: '',
          healthCheckTotal: 0,
          healthCheckAlarms: 0,
          healthChecks: {},
          history: []
        },
        resources: {},
        healthChecks: {},
        history: []
      }
    }
  }
}
