export default {
  INFORMACASTM2M_LIST (state, value) {
    state.list = (value && Array.isArray(value)) ? [...(value.sort((a, b) => a.name.localeCompare(b.name)))] : []
  },
  INFORMACASTM2M_LIST_UPDATE (state, value) {
    const index = state.list.findIndex((i) => i.id === value.id)
    if (index !== -1) {
      state.list.splice(index, 1, Object.assign(state.list[index], value))
    } else {
      state.list.push(value)
    }
  },
  INFORMACASTM2M_CURRENT (state, value) {
    const props = [
      'building',
      'description',
      'inputPortCount',
      'outputPortCount',
      'name',
      'ip',
      'system',
      'vendorId'
    ]
    if (value) {
      for (const prop of props) {
        if (value[prop] !== state.current[prop]) {
          state.current[prop] = value[prop]
        }
      }
      for (const prop of ['id', 'active', 'reenableTime', 'building']) {
        if (value[prop] !== state.current[prop]) {
          state.current[prop] = value[prop]
          state.update[prop] = value[prop]
        }
      }
    } else {
      state.current = {
        id: '',
        active: false,
        building: '',
        description: '',
        inputPortCount: 0,
        outputPortCount: 0,
        name: '',
        ip: '',
        reenableTime: null,
        system: '',
        vendorId: ''
      }
      state.update = {
        id: '',
        active: false,
        building: '',
        reenableTime: null
      }
    }
  },
  INFORMACASTM2M_UPDATE_ACTIVE (state, value) {
    state.update.active = value
  },
  INFORMACASTM2M_UPDATE_REENABLE_TIME (state, value) {
    state.update.reenableTime = value
  },
  INFORMACASTM2M_UPDATE_BUILDING (state, value) {
    state.update.building = value
  },
  INFORMACASTM2M_FETCHING_LIST (state, value) {
    state.fetchingList = value
  }
}
